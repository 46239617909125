import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changePassword } from "../actions/account";
import { Col, Row, Container, Button, Form, Card } from "react-bootstrap";

class ChangePasswordLoggedPage extends Component {
  static propTypes = {
    changePassword: PropTypes.func.isRequired
  };
  state = {
    oldPassword: "",
    newPassword: "",
    newPasswordRepeat: "",
    newPasswordIsNotSame: false
  };
  onSubmit = async e => {
    e.preventDefault();
    const { newPasswordRepeat, newPassword, oldPassword } = this.state;
    try {
      await this.props.changePassword({
        newPasswordRepeat,
        newPassword,
        oldPassword
      });
      this.setState({
        oldPassword: "",
        newPassword: "",
        newPasswordRepeat: "",
        newPasswordIsNotSame: false
      });
    } catch (e) {
      console.log(e);
    }
  };
  handleInputChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
      {
        [name]: value
      },
      this.validIsNewPasswordSameAsRepeat
    );
  };
  validIsNewPasswordSameAsRepeat = () => {
    const { newPasswordRepeat, newPassword } = this.state;
    if (newPassword && newPasswordRepeat && newPassword !== newPasswordRepeat) {
      this.setState({
        newPasswordIsNotSame: true
      });
    } else {
      this.setState({
        newPasswordIsNotSame: false
      });
    }
  };
  render() {
    return (
      <>
        <Container fluid className="view-name-header">
          <Row>
            <Col sm="9" className="padding-none">
              <h4>Change password</h4>
            </Col>
          </Row>
        </Container>
        <div className="view-wrapper">
          <Row className="justify-content-md-center loggedChangePassPage">
            <Col sm={12} md={12} lg={12} xl={12}>
              <Form onSubmit={this.onSubmit} name="change-password-form">
                <div>
                  <Card.Body>
                    <Form.Row className="mb-2">
                      <Col sm={4}>
                        <Form.Label>Old password</Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="password"
                          value={this.state.oldPassword}
                          onChange={this.handleInputChange}
                          name="oldPassword"
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="mb-2">
                      <Col sm={4}>
                        <Form.Label>New password</Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="password"
                          value={this.state.newPassword}
                          isInvalid={
                            this.state.newPassword &&
                            this.state.newPassword.length < 8
                          }
                          onChange={this.handleInputChange}
                          name="newPassword"
                        />
                        <Form.Control.Feedback type="invalid">
                          {this.state.newPassword &&
                          this.state.newPassword.length < 8
                            ? "must be at least 8 characters"
                            : ""}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mb-2">
                      <Col sm={4}>
                        <Form.Label>Repeat new password</Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          isInvalid={this.state.newPasswordIsNotSame}
                          type="password"
                          value={this.state.newPasswordRepeat}
                          onChange={this.handleInputChange}
                          name="newPasswordRepeat"
                        />
                        <Form.Control.Feedback type="invalid">
                          {this.state.newPasswordIsNotSame
                            ? "new password is not same as repeat"
                            : ""}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>
                  </Card.Body>
                  <Card.Footer className="text-right bg-white">
                    <Button
                      className="ml-2"
                      type="submit"
                      disabled={
                        this.state.newPasswordIsNotSame ||
                        (!this.state.newPassword &&
                          !this.state.newPasswordRepeat) ||
                        this.state.newPassword.length < 8
                      }
                    >
                      Change
                    </Button>
                  </Card.Footer>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default connect(
  state => ({}),
  dispatch =>
    bindActionCreators(
      {
        changePassword: changePassword
      },
      dispatch
    )
)(ChangePasswordLoggedPage);
