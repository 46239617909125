import {command, find} from "../utils/api";

const CANDIDATES_BASE_URL = `${process.env.PUBLIC_URL}/api/v1/candidates`;

export const FIND_CANDIDATES_TO_ACCEPT = "FIND_CANDIDATES_TO_ACCEPT";
export const FIND_CANDIDATES_TO_ACCEPT_COMPLETED = "FIND_CANDIDATES_TO_ACCEPT_COMPLETED";
export const FIND_CANDIDATES_TO_ACCEPT_FAILED = "FIND_CANDIDATES_TO_ACCEPT_FAILED";

export const findCandidatesToAccept = find(
  CANDIDATES_BASE_URL + "/candidatesToAcceptByAdmin",
  FIND_CANDIDATES_TO_ACCEPT,
  FIND_CANDIDATES_TO_ACCEPT_COMPLETED,
  FIND_CANDIDATES_TO_ACCEPT_FAILED
);

export const ACCEPT_CANDIDATE_BY_ADMIN = "ACCEPT_CANDIDATE_BY_ADMIN_VERIFY";
export const ACCEPT_CANDIDATE_BY_ADMIN_COMPLETED = "ACCEPT_CANDIDATE_BY_ADMIN_COMPLETED";
export const ACCEPT_CANDIDATE_BY_ADMIN_FAILED = "ACCEPT_CANDIDATE_BY_ADMIN_FAILED";

export const acceptCandidateByAdmin = command(
  CANDIDATES_BASE_URL + "/acceptCandidateByAdmin",
  ACCEPT_CANDIDATE_BY_ADMIN,
  ACCEPT_CANDIDATE_BY_ADMIN_COMPLETED,
  ACCEPT_CANDIDATE_BY_ADMIN_FAILED
);

export const REJECT_CANDIDATE_BY_ADMIN = "REJECT_CANDIDATE_BY_ADMIN_VERIFY";
export const REJECT_CANDIDATE_BY_ADMIN_COMPLETED = "REJECT_CANDIDATE_BY_ADMIN_COMPLETED";
export const REJECT_CANDIDATE_BY_ADMIN_FAILED = "REJECT_CANDIDATE_BY_ADMIN_FAILED";

export const rejectCandidateByAdmin = command(
  CANDIDATES_BASE_URL + "/rejectCandidateByAdmin",
  REJECT_CANDIDATE_BY_ADMIN,
  REJECT_CANDIDATE_BY_ADMIN_COMPLETED,
  REJECT_CANDIDATE_BY_ADMIN_FAILED
);

export const CHANGE_CANDIDATE_JOB_POSITION_BY_ADMIN = "CHANGE_CANDIDATE_JOB_POSITION_BY_ADMIN";
export const CHANGE_CANDIDATE_JOB_POSITION_BY_ADMIN_COMPLETED = "CHANGE_CANDIDATE_JOB_POSITION_BY_ADMIN_COMPLETED";
export const CHANGE_CANDIDATE_JOB_POSITION_BY_ADMIN_FAILED = "CHANGE_CANDIDATE_JOB_POSITION_BY_ADMIN_FAILED";

export const changeCandidateJobPositionByAdmin = command(
    CANDIDATES_BASE_URL + "/changeCandidateJobPositionByAdmin",
    CHANGE_CANDIDATE_JOB_POSITION_BY_ADMIN,
    CHANGE_CANDIDATE_JOB_POSITION_BY_ADMIN_COMPLETED,
    CHANGE_CANDIDATE_JOB_POSITION_BY_ADMIN_FAILED
);

