import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {findUsers, unblockUser, blockUser, deleteUser} from "../actions/users";
import {
  Table,
  FormControl,
  Col,
  Row,
  Container,
  Button, Tooltip, OverlayTrigger, ButtonGroup, Modal, Alert,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import auth from "../utils/auth"
import moment from "moment";

class ListOfUsers extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,

    users: PropTypes.array.isRequired,

    findUser: PropTypes.func.isRequired,
    unblockUser: PropTypes.func.isRequired,
    blockUser: PropTypes.func.isRequired,
    totalCount: PropTypes.number,
    deleteUser: PropTypes.func.isRequired,
    deleteUserError: PropTypes.string.isRequired,
  };
  timeout = null;
  state = {
    pageNr: 0,
    perPage: 25,
    search: "",
    deleteModalVisible: false,
    deleteUserId: "",
    deleteTooltipVisible: "",
  };
  componentDidMount() {
    this.findUsers();
  }
  findUsers = async () => {
    const search = this.state.search;
    if (!search) {
      await this.props.findUser({
        offset: this.state.pageNr,
        limit: this.state.perPage,
      });
    } else {
      await this.props.findUser({
        offset: this.state.pageNr,
        limit: this.state.perPage,
        search: this.state.search,
      });
    }
  };
  pageChange = (pageNumber) => {
    const pageNr = pageNumber - 1;
    this.setState({ pageNr: pageNr }, () => {
      this.findUsers();
    });
  };
  search = (e) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.setState(
      {
        search: e.target.value,
        pageNr: 0,
      },
      () =>
        (this.timeout = setTimeout(() => {
          this.findUsers();
        }, 900))
    );
  };
  render() {
    return (
      <>
        <Container fluid className="view-name-header">
          <Row>
            <Col sm="9" className="padding-none">
              <h4>Users</h4>
            </Col>
            <Col sm="3" className="padding-none">
              <FormControl
                type="text"
                placeholder="Search"
                value={this.state.search}
                onChange={this.search}
              />
            </Col>
          </Row>
        </Container>
        <div className="table-wrapper">
          <Table>
            <thead>
              <tr>
                <th>Username</th>
                <th>Name</th>
                <th>Surname</th>
                <th>Scope</th>
                <th>Sign Up</th>
                <th>Last Login</th>
                <th>Is blocked</th>
                <th className="actions-transparent">Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.props.users.map((user) => (
                <tr className="tr-main" key={user.userId}>
                  <td>{user.username}</td>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.scope}</td>
                  <td>{moment(user.createdAt).format("YYYY-MM-DD hh:mm A")}</td>
                  <td>{user.lastLoginAt ? moment(user.lastLoginAt).format("YYYY-MM-DD hh:mm A") : ''}</td>
                  <td>{user.isBlocked ? "Yes" : "No"}</td>
                  <td>
                    <ButtonGroup className="btn-group-actions">
                      {!user.isBlocked && user.scope !== "admin" ? (
                        <Button
                          variant="danger"
                          onClick={() =>
                            this.props.blockUser({ userId: user.userId })
                          }
                        >
                          Block
                        </Button>
                      ) : user.scope !== "admin" ? (
                        <Button
                          variant="primary"
                          onClick={() =>
                            this.props.unblockUser({ userId: user.userId })
                          }
                        >
                          Unblock
                        </Button>
                      ) : null}
                      {user.scope !== "admin" ? (
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 250 }}
                          overlay={<Tooltip id="delete-user-tooltip">Delete</Tooltip>}
                          show={this.state.deleteTooltipVisible === user.userId}
                          onToggle={(show) => this._onToggleDeleteTooltip(show, user.userId)}
                        >
                          <Button
                            size="sm"
                            variant="danger"
                            onClick={() => this._showDeleteModal(user.userId)}
                            className="material-icons"
                          >
                            delete
                          </Button>
                        </OverlayTrigger>
                      ) : null}
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Button size="sm" variant="primary" style={{float: 'right'}} onClick={this._exportAsCsv}>Export CSV</Button>
        <Pagination
          activePage={this.state.pageNr + 1}
          itemsCountPerPage={this.state.perPage}
          totalItemsCount={this.props.totalCount}
          pageRangeDisplayed={5}
          onChange={this.pageChange}
        />
        <Modal
          centered
          show={this.state.deleteModalVisible}
          onHide={this._hideDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="danger" hidden={!this.props.deleteUserError}>
              {this.props.deleteUserError}
            </Alert>
            <p>
              Deleting an user will only remove the user from this list,
              preventing further login and allowing new sign-up with same email.
            </p>
            <p>Are you sure you want to delete this user?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={this._hideDeleteModal}>
              Close
            </Button>
            <Button variant="primary" onClick={this._deleteUser}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  _exportAsCsv = () => window.open(
    `${process.env.PUBLIC_URL}/api/v1/export/csv/users?access_token=${auth.getInstance().getAccessToken()}`,
    '_blank'
  );

  _onToggleDeleteTooltip = (show, userId) => {
    // JD: hack to prevent tooltip from showing up again after modal close
    if (this.state.deleteTooltipVisible === false) {
      this.setState({ deleteTooltipVisible: '' });
    } else {
      this.setState({ deleteTooltipVisible: (show && userId) || "" });
    }
  };

  _showDeleteModal = (userId) => this.setState({ deleteModalVisible: true, deleteUserId: userId });

  _hideDeleteModal = () => this.setState({ deleteTooltipVisible: false, deleteModalVisible: false, deleteUserId: "" });

  _deleteUser = async() => {
    try {
      const { deleteUserId: userId } = this.state;
      await this.props.deleteUser({ userId });
      this._hideDeleteModal();
    } catch (e) {}
  };
}

export default connect(
  (state) => ({
    users: state.users.users,
    totalCount: state.users.totalCount,
    deleteUserError: state.users.deleteUserError,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        findUser: findUsers,
        unblockUser: unblockUser,
        blockUser: blockUser,
        deleteUser,
      },
      dispatch
    )
)(ListOfUsers);
