import { Component } from "react";
import Auth from "../utils/auth";

export default class HomePage extends Component {
  componentDidMount() {
    const auth = Auth.getInstance();
    const scope = auth.getScope();
    if (scope === "admin") {
      this.props.history.push(`recruiter/home`);
    } else if (scope) {
      this.props.history.push(`${scope}/home`);
    }
  }

  render() {
    return null;
  }
}
