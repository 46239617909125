import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { uploadFile } from "../../actions/files";
import { Form, ProgressBar } from "react-bootstrap";
import parsePdfData from '../../utils/pdfParser';

class FileUpload extends Component {

  state = {
    invalid: false,
    error: '',
    percentage: 0
  };

  static propTypes = {
    // props
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    // state
    progress: PropTypes.object.isRequired,
    // actions
    uploadFile: PropTypes.func.isRequired,
    // external handlers
    onParsedPdfFile: PropTypes.func,
  };

  _raiseError = (errMsg) => {
    this.setState({
      invalid: true,
      error: errMsg,
    });
  }

  _clearError = () => {
    this.setState({
      invalid: false,
      error: '',
    });
  }

  _readFile = (file) => {
    const readFile = async (file) => {
      const reader = new FileReader();
      // console.log('reading file', file);

      const sanitizePdfString = (s) => {
        const pattern = '\u0000';
        return s.replaceAll(pattern, '');
      }

      reader.onabort = () => console.error(`'${file.name}': file reading was aborted`);
      reader.onerror = () => console.error(`'${file.name}': file reading has failed`);
      reader.onload = () => {
        // console.log(`'${file.name}': read OK`);

        const binaryStr = reader.result;
        // console.log(binaryStr);

        file.data = binaryStr;
        parsePdfData(file.data).then((parsedText) => {
          this.props.onParsedPdfFile(sanitizePdfString(parsedText));
        });
      }
      reader.readAsArrayBuffer(file);
    }
    readFile(file);
  }

  _onFileChange = (e) => {
    try {
      console.log('files: ', e.target.files);
      if (0 === e.target.files.length) return;
    
      const file = e.target.files[0];
      if (file.type !== "application/pdf") {
        this._raiseError('File type must be pdf');
      }
      else {
        this._clearError();
      }

      this._readFile(file);
      this.props.uploadFile({ file });
    } catch (err) {
      //
    }
  };

  componentDidUpdate(prevProps) {
    const {
      progress: { filename },
      onChange,
      name,
    } = this.props;

    if (filename && filename !== prevProps.progress.filename)
      onChange({ target: { name, value: filename } });
  }

  render() {
    const {
      progress: { state, percentage, error },
      isInvalid,
      name,
    } = this.props;
    return (
      <div>
        <Form.Control
          name={name}
          type="file"
          onChange={this._onFileChange}
          isInvalid={isInvalid || this.state.invalid}
        />
        <Form.Control.Feedback type="invalid">{error || this.state.error}</Form.Control.Feedback>
        {state && !error && (
          <ProgressBar now={percentage} label={percentage === 100 ? "Completed" : `${percentage}%`}
            variant={percentage === 100 ? 'success' : ''}/>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    progress: state.files.progress,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        uploadFile,
      },
      dispatch
    )
)(FileUpload);
