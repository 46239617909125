import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Container,
  Row,
  Col,
  FormControl,
  ListGroup,
  Badge,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { checkStatusByEmail } from "../../actions/candidates";

function CandidateCheck(props) {

  const [email, setEmail] = useState('');
  const [searchedEmail, setSearchedEmail] = useState('');
  const [checkedCandidates, setCheckedCandidates] = useState([]);
  const emailInputRef = useRef(null);

  const onSearch = () => {
    setSearchedEmail(email.trim());
  }

  const onClear = () => {
    setEmail('');
    emailInputRef.current.focus();
  }

  const onClearResults = () => {
    setCheckedCandidates([]);
    onClear();
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 13) onSearch();
  }

  useEffect(() => {
    async function fetchStatus() {
      let res;
      try {
        res = await checkStatusByEmail(searchedEmail);
        if (!ignore) {
          setCheckedCandidates( candidates => candidates = [...candidates, res]);
        }
      }
      catch (err) {
        toast.error(`Failed checking candidate ${searchedEmail}: ${err.message}'`);
      }
    }

    if (!searchedEmail) return;

    let ignore = false;
    fetchStatus();
    return () => { ignore = true; };

  }, [searchedEmail]);

  const badgeForCandidate = (c) => {
    return c.allowSubmission ? (
          <Badge pill variant="success">
            YES
          </Badge>
        ) : (
          <Badge pill variant="danger">
            NO
          </Badge>
        );
  }

  return (
    <>
      <Container fluid className="view-name-header">
        <Row>
          <Col xs="8">
            {/* <p>Here you can check if you can submit your candidate to RNR or if this candidate has already been submitted by another recruiter.</p> */}
            <p>{props.title}</p>
          </Col> 
        </Row>
        <Row>
          <Col xs="6">
            <FormControl
              autoFocus={true}
              type="text"
              placeholder="Candidate Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={onKeyDown}
              ref={emailInputRef}
            />
          </Col>
          <Col xs="2">
            <Button 
              variant="primary"
              className='margin-right-10'
              onClick={onSearch}
            >
              Search
            </Button>
            {0 < email.length ? (
              <Button
                variant="light"
                className='margin-right-10'
                onClick={onClear}
              >
                Clear
              </Button>
            ): null}
          </Col>
        </Row>
        <Row className='list-header'>
          <Col xs="8">
            {0 < checkedCandidates.length ? (
              <h4>Candidate Check Results:</h4>
            ) : null }
          </Col>
        </Row>
        <Row>
          <Col xs="8">
            <ListGroup as="ul" variant="flush">
              {checkedCandidates.map((candidate, idx) => (
                  <ListGroup.Item as="li" key={idx}>
                    {badgeForCandidate(candidate)}
                    &nbsp;
                    {candidate.email}
                    &nbsp;-&nbsp;
                    {candidate.message}
                  </ListGroup.Item>))
              }
            </ListGroup>
          </Col>
        </Row>
        <Row className='list-header'>
          <Col xs="8">
            {0 < checkedCandidates.length ? (
              <Button 
              variant="danger"
              className="float-end"
              onClick={onClearResults}
              >
                Clear Results
              </Button>
            ) : null }
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CandidateCheck;
