import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { verifyRecruiter } from "../actions/recruiters";
import { Alert, Spinner, Jumbotron, Button } from "react-bootstrap";

class VerifyRecruiterPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    verifyingRecruiter: PropTypes.bool.isRequired,
    recruiterVerified: PropTypes.bool.isRequired,
    verifyRecruiterError: PropTypes.string.isRequired,
    verifyRecruiter: PropTypes.func.isRequired,
  };

  _renderError() {
    const error = this.props.verifyRecruiterError
    if (!error) return null;
    const isAlreadyVerified = error === 'Recruiter already verified';
    return (
      <Alert variant="danger">
        <p>{error}</p>
        {isAlreadyVerified ? (
          <p><strong>
            Reminder: you need to be approved by an administrator before you can
            login. We'll notify you by email when this happen.
          </strong></p>
        ) : null}
      </Alert>
    );
  }

  async componentDidMount() {
    const { recruiterId } = this.props.match.params;
    try {
      await this.props.verifyRecruiter({ recruiterId });
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    const { verifyingRecruiter, recruiterVerified } = this.props;
    return (
      <Jumbotron className="text-center">
        <h1>Account verification</h1>
        <div className="col-6 my-5 mx-auto">
          {this._renderError()}
          {recruiterVerified ? (
            <Alert variant="success">
              <p>Your account has been verified.</p>
              <p><strong>
                One last step: you need to be approved by an administrator
                before you can login. We'll notify you by email when this
                happen.
              </strong></p>
            </Alert>
          ) : null}
          {verifyingRecruiter ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : null}
        </div>
        <div className="my-5">
          <Button
            onClick={() => this.props.history.push(`/recruiter`)}
            variant="primary"
          >
            Home page
          </Button>
        </div>
      </Jumbotron>
    );
  }
}

export default connect(
  (state) => ({
    verifyingRecruiter: state.recruiters.verifyingRecruiter,
    recruiterVerified: state.recruiters.recruiterVerified,
    verifyRecruiterError: state.recruiters.verifyRecruiterError,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        verifyRecruiter,
      },
      dispatch
    )
)(VerifyRecruiterPage);
