import React from "react";
import * as Icon from "react-bootstrap-icons";

/**
 * Column sorter.
 * @param {string} field
 * @param {string[]} orders
 * @param {function(string[])} onChange
 * @param {mixed} props
 * @returns {JSX.Element}
 * @constructor
 */
const ColumnSorter = ({ field, orders = [], onChange, ...props }) => {
  const orderIndex = orders.findIndex(x=> x.startsWith(field + ''));
  const order = orderIndex >= 0 ? orders[orderIndex].split(' ')[1] : '';
  const getClickHandler = (order) => (e) => {
    e.preventDefault();
    const newOrders = [...orders];
    if (order) {
      newOrders[orderIndex >= 0 ? orderIndex : orders.length] = field + ' ' + order;
    } else {
      newOrders.splice(orderIndex, 1);
    }
    onChange(newOrders);
  };
  switch (order) {
    case 'ASC':
      return (<Icon.ArrowUp {...props} onClick={getClickHandler('DESC')}/>);
    case 'DESC':
      return (<Icon.ArrowDown {...props} onClick={getClickHandler('')}/>);
    default:
      return (<Icon.ArrowDownUp {...props} onClick={getClickHandler('ASC')}/>);
  }
};

export default ColumnSorter;
