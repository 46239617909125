import {
  EMPLOYER_SIGN_UP,
  EMPLOYER_SIGN_UP_COMPLETED,
  EMPLOYER_SIGN_UP_FAILED,
  EMPLOYER_VERIFY_COMPLETED,
  EMPLOYER_VERIFY_FAILED,
  EMPLOYER_VERIFY,
  FIND_EMPLOYERS,
  FIND_EMPLOYERS_COMPLETED,
  FIND_EMPLOYERS_FAILED,
  FIND_EMPLOYER,
  FIND_EMPLOYER_COMPLETED,
  FIND_EMPLOYER_FAILED,
  FIND_LOGGED_EMPLOYER,
  FIND_LOGGED_EMPLOYER_COMPLETED,
  FIND_LOGGED_EMPLOYER_FAILED,
  PURCHASE_A_JOB,
  PURCHASE_A_JOB_COMPLETED,
  PURCHASE_A_JOB_FAILED,
  REMOVE_EMPLOYER,
  REMOVE_EMPLOYER_COMPLETED,
  REMOVE_EMPLOYER_FAILED
} from "../actions/employers";
import { PAGE_INIT } from "../actions/pages";
import { toast } from "react-toastify";

export const defaultState = Object.freeze({
  signingUp: false,
  signUpErrors: {},
  signedUp: true,
  verifyingEmployer: false,
  employerVerified: false,
  verifyEmployerError: "",
  findingEmployers: false,
  employers: [],
  findEmployersError: "",
  findingEmployer: false,
  findEmployerError: "",
  employer: {},
  customCodeErrors: [],
  totalCount: 0,
  loggedEmployer: {},
  removingEmployer: false,
  employerRemoved: false,
  removeEmployerError: ""
});

const employersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case PAGE_INIT: {
      return defaultState;
    }
    case FIND_EMPLOYERS: {
      return {
        ...state,
        findingEmployers: true,
        findEmployersError: "",
      };
    }
    case FIND_EMPLOYERS_COMPLETED: {
      return {
        ...state,
        findingEmployers: false,
        employers: action.payload.results,
        totalCount: action.payload.total,
      };
    }
    case FIND_EMPLOYERS_FAILED: {
      return {
        ...state,
        findingEmployers: false,
        findEmployersError: action.payload.message,
      };
    }
    case FIND_LOGGED_EMPLOYER: {
      return {
        ...state,
        findingEmployer: true,
        findEmployerError: "",
      };
    }
    case FIND_LOGGED_EMPLOYER_COMPLETED: {
      return {
        ...state,
        findingEmployer: false,
        loggedEmployer: action.payload || {},
      };
    }
    case FIND_LOGGED_EMPLOYER_FAILED: {
      return {
        ...state,
        findingEmployer: false,
        findEmployerError: action.payload.message,
      };
    }
    case FIND_EMPLOYER: {
      return {
        ...state,
        findingEmployer: true,
        findEmployerError: "",
      };
    }
    case FIND_EMPLOYER_COMPLETED: {
      return {
        ...state,
        findingEmployer: false,
        employer: action.payload || {},
        draftJobId: action.payload && action.payload.jobId,
      };
    }
    case FIND_EMPLOYER_FAILED: {
      return {
        ...state,
        findingEmployer: false,
        findEmployerError: action.payload.message,
      };
    }
    case EMPLOYER_VERIFY: {
      return {
        ...state,
        employerVerified: false,
        verifyEmployerError: "",
        verifyingEmployer: true,
      };
    }
    case EMPLOYER_VERIFY_COMPLETED: {
      return {
        ...state,
        employerVerified: true,
        verifyEmployerError: "",
        verifyingEmployer: false,
      };
    }
    case EMPLOYER_VERIFY_FAILED: {
      let verifyErrors;
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        if (Array.isArray(action.payload.response.data.message)) {
          verifyErrors = JSON.stringify(
            action.payload.response.data.message.reduce((errors, error) => {
              errors[error.field] = error.message;
              return errors;
            }, {})
          );
        } else {
          verifyErrors = action.payload.response.data.message;
        }
      } else {
        verifyErrors = action.payload.message;
      }
      return {
        ...state,
        employerVerified: false,
        verifyEmployerError: verifyErrors,
        verifyingEmployer: false,
      };
    }
    case EMPLOYER_SIGN_UP: {
      return {
        ...state,
        signingUp: true,
        signUpErrors: {},
      };
    }
    case EMPLOYER_SIGN_UP_COMPLETED: {
      return {
        ...state,
        signingUp: false,
        signedUp: true,
      };
    }
    case EMPLOYER_SIGN_UP_FAILED: {
      let signUpErrors;
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        if (Array.isArray(action.payload.response.data.message)) {
          signUpErrors = action.payload.response.data.message.reduce(
            (errors, error) => {
              errors[error.field] = error.message;
              return errors;
            },
            {}
          );
        } else {
          signUpErrors = { $form: action.payload.response.data.message };
        }
      } else {
        signUpErrors = { $form: action.payload.message };
      }
      return {
        ...state,
        signingUp: false,
        signUpErrors,
      };
    }
    case PURCHASE_A_JOB: {
      return {
        ...state,
        customCodeErrors: [],
      };
    }
    case PURCHASE_A_JOB_COMPLETED: {
      toast.success("Code used, your available posts has been incremented");
      return {
        ...state,
        customCodeErrors: [],
      };
    }
    case PURCHASE_A_JOB_FAILED: {
      let customCodeErrors = [];
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        toast.error(action.payload.response.data.message);
      } else {
        customCodeErrors = action.payload.response.data;
      }
      return {
        ...state,
        customCodeErrors,
      };
    }
    case REMOVE_EMPLOYER: {
      return {
        ...state,
        employerRemoved: false,
        removeEmployerError: "",
        removingEmployer: true,
      };
    }
    case REMOVE_EMPLOYER_COMPLETED: {
      return {
        ...state,
        employerRemoved: true,
        removeEmployerError: "",
        removingEmployer: false,
      };
    }
    case REMOVE_EMPLOYER_FAILED: {
      let removeErrors;
      if (
          action.payload.response &&
          action.payload.response.data &&
          action.payload.response.data.message
      ) {
        if (Array.isArray(action.payload.response.data.message)) {
          removeErrors = JSON.stringify(
              action.payload.response.data.message.reduce((errors, error) => {
                errors[error.field] = error.message;
                return errors;
              }, {})
          );
        } else {
          removeErrors = action.payload.response.data.message;
        }
      } else {
        removeErrors = action.payload.message;
      }
      return {
        ...state,
        employerRemoved: false,
        removeEmployerError: removeErrors,
        removingEmployer: false,
      };
    }
    default:
      return state;
  }
};

export default employersReducer;
