import axios from "axios";
import Auth from "./auth";

const auth = Auth.getInstance();
export function authorizationHeaders() {
  if (auth.isAuthenticated()) {
    const access_token = auth.getAccessToken();
    return {
      Authorization: "Bearer " + access_token,
    };
  }
  return {};
}

export function command(
  url,
  actionType,
  successType,
  failureType,
  { extraData } = {}
) {
  return function (payload) {
    return async function (dispatch) {
      dispatch({
        type: actionType,
        payload: payload,
      });
      let action;
      try {
        const res = await axios.post(
          url,
          { ...extraData, ...payload },
          { headers: authorizationHeaders() }
        );
        action = {
          type: successType,
          payload: res.data,
        };
      } catch (err) {
        action = {
          type: failureType,
          payload: err,
        };
        throw err;
      } finally {
        dispatch(action);
      }
    };
  };
}

export function find(url, actionType, successType, failureType) {
  return function (payload) {
    return async function (dispatch) {
      dispatch({
        type: actionType,
        payload: payload,
      });
      let action;
      try {
        const res = await axios.get(url, {
          params: payload,
          headers: authorizationHeaders(),
        });
        action = {
          type: successType,
          payload: res.data,
        };
      } catch (err) {
        action = {
          type: failureType,
          payload: err,
        };
        throw err;
      } finally {
        dispatch(action);
      }
    };
  };
}

export function findOne(url, actionType, successType, failureType) {
  return function (payload) {
    return async function (dispatch) {
      dispatch({
        type: actionType,
        payload: payload,
      });
      let action;
      try {
        const res = await axios.get(url, {
          params: { ...payload, offset: 0, limit: 1 },
          headers: authorizationHeaders(),
        });
        const data = Array.isArray(res.data)
          ? res.data[0]
          : res.data.results
          ? res.data.results[0]
          : undefined;
        action = {
          type: successType,
          payload: data,
        };
      } catch (err) {
        action = {
          type: failureType,
          payload: err,
        };
        throw err;
      } finally {
        dispatch(action);
      }
    };
  };
}
