import axios from "axios";
import {authorizationHeaders} from "../../../utils/api";

export async function fetchEmployer(employerId) {
  return await axios
    .get(`${process.env.PUBLIC_URL}/api/v1/employers/find?employerId=${employerId}`, {
      headers: authorizationHeaders(),
    });
}

export async function fetchCities(state)  {
  return await axios.get(`${process.env.PUBLIC_URL}/api/v1/data/cities`, {
    params: { state }
  })
}

export async function updateEmployer(employerId, data) {
  return await axios.post(`${process.env.PUBLIC_URL}/api/v1/employers/updateEmployer`, data, {
    params: { employerId },
    headers: authorizationHeaders()
  });
}
