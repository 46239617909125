import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Auth from "../utils/auth";
import { expireToken, refreshToken } from "./actions";

class SessionManager extends Component {
  constructor(props) {
    super(props);
    this._auth = Auth.getInstance();
  }

  componentDidMount() {
    this.useToken();
  }

  componentDidUpdate(prevProps) {
    const { token } = this.props;
    if (token && token !== prevProps.token) {
      console.log("use token");
      this.useToken();
    }
    if (!token && token !== prevProps.token) {
      console.log("logout");
      this._auth.logout();
    }
  }

  useToken() {
    const { expireToken, refreshToken, token } = this.props;
    this._auth.setRefreshTokenCallback(refreshToken);
    this._auth.setOnTokenExpiredCallback(expireToken);
    if (token) {
      this._auth.authenticate(token);
    }
  }

  render() {
    return null;
  }
}
SessionManager.propTypes = {
  token: PropTypes.object,
  expireToken: PropTypes.func.isRequired,
  refreshToken: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  token: state.auth.token
});
const mapDispatchToProps = dispatch => ({
  expireToken: bindActionCreators(expireToken, dispatch),
  refreshToken: bindActionCreators(refreshToken, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionManager);
