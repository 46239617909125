import React from 'react';
import CandidateCheck from "../common/CandidateCheck";

function CheckCandidatesPage(props) {
  return (
      <CandidateCheck title="Here you can check if you can submit your candidate to RNR or if this candidate has already been submitted by another recruiter." />
  );
}

export default CheckCandidatesPage;

