const states = Object.freeze([
  { country: "CA", value: "ON", text: "Ontario" },
  { country: "CA", value: "QC", text: "Quebec" },
  { country: "CA", value: "NS", text: "Nova Scotia" },
  { country: "CA", value: "NB", text: "New Brunswick" },
  { country: "CA", value: "MB", text: "Manitoba" },
  { country: "CA", value: "BC", text: "British Columbia" },
  { country: "CA", value: "PE", text: "Prince Edward Island" },
  { country: "CA", value: "SK", text: "Saskatchewan" },
  { country: "CA", value: "AB", text: "Alberta" },
  { country: "CA", value: "NL", text: "Newfoundland and Labrador" },
  { country: "CA", value: "NT", text: "Northwest Territories" },
  { country: "CA", value: "YT", text: "Yukon" },
  { country: "CA", value: "NU", text: "Nunavut" },
  { country: "US", value: "AL", text: "Alabama" },
  { country: "US", value: "AK", text: "Alaska" },
  { country: "US", value: "AZ", text: "Arizona" },
  { country: "US", value: "AR", text: "Arkansas" },
  { country: "US", value: "CA", text: "California" },
  { country: "US", value: "CO", text: "Colorado" },
  { country: "US", value: "CT", text: "Connecticut" },
  { country: "US", value: "DE", text: "Delaware" },
  { country: "US", value: "FL", text: "Florida" },
  { country: "US", value: "GA", text: "Georgia" },
  { country: "US", value: "HI", text: "Hawaii" },
  { country: "US", value: "ID", text: "Idaho" },
  { country: "US", value: "IL", text: "Illinois" },
  { country: "US", value: "IN", text: "Indiana" },
  { country: "US", value: "IA", text: "Iowa" },
  { country: "US", value: "KS", text: "Kansas" },
  { country: "US", value: "KY", text: "Kentucky" },
  { country: "US", value: "LA", text: "Louisiana" },
  { country: "US", value: "ME", text: "Maine" },
  { country: "US", value: "MD", text: "Maryland" },
  { country: "US", value: "MA", text: "Massachusetts" },
  { country: "US", value: "MI", text: "Michigan" },
  { country: "US", value: "MN", text: "Minnesota" },
  { country: "US", value: "MS", text: "Mississippi" },
  { country: "US", value: "MO", text: "Missouri" },
  { country: "US", value: "MT", text: "Montana" },
  { country: "US", value: "NE", text: "Nebraska" },
  { country: "US", value: "NV", text: "Nevada" },
  { country: "US", value: "NH", text: "New Hampshire" },
  { country: "US", value: "NJ", text: "New Jersey" },
  { country: "US", value: "NM", text: "New Mexico" },
  { country: "US", value: "NY", text: "New York" },
  { country: "US", value: "NC", text: "North Carolina" },
  { country: "US", value: "ND", text: "North Dakota" },
  { country: "US", value: "OH", text: "Ohio" },
  { country: "US", value: "OK", text: "Oklahoma" },
  { country: "US", value: "OR", text: "Oregon" },
  { country: "US", value: "PA", text: "Pennsylvania" },
  { country: "US", value: "RI", text: "Rhode Island" },
  { country: "US", value: "SC", text: "South Carolina" },
  { country: "US", value: "SD", text: "South Dakota" },
  { country: "US", value: "TN", text: "Tennessee" },
  { country: "US", value: "TX", text: "Texas" },
  { country: "US", value: "UT", text: "Utah" },
  { country: "US", value: "VT", text: "Vermont" },
  { country: "US", value: "VA", text: "Virginia" },
  { country: "US", value: "WA", text: "Washington" },
  { country: "US", value: "WV", text: "West Virginia" },
  { country: "US", value: "WI", text: "Wisconsin" },
  { country: "US", value: "WY", text: "Wyoming" },
  { country: "US", value: "DC", text: "District of Columbia" },
])

export default states;
