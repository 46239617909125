import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { findCandidates } from "../actions/candidates";
import {
  Col,
  Container,
  Row,
  Table,
  Form,
  Tooltip,
  OverlayTrigger
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import moment from "moment";
import CandidateStatus from "./CandidateStatus";

class ListOfCandidatesForAdmin extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    candidates: PropTypes.array.isRequired,
    findCandidates: PropTypes.func.isRequired,
    totalCount: PropTypes.number,
  };
  state = {
    pageNr: 0,
    perPage: Math.ceil((document.body.clientHeight / 112) - 2),
    search: '',
  };

  componentDidMount() {
    this.loadData();
  }

  pageChange = (pageNumber) => {
    const pageNr = pageNumber - 1;
    this.setState({ pageNr: pageNr }, () => {
      this.loadData();
    });
  };

  loadData() {
    this.props.findCandidates({
      offset: this.state.pageNr,
      limit: this.state.perPage,
      search: this.state.search,
    })
  }

  onSearchChange = e => {
    this.setState({search: e.target.value});
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => this.loadData(), 300);
  }

  render() {
    return (
      <>
        <Container fluid className="view-name-header">
          <Row>
            <Col sm="12" className="padding-none">
              <h4 style={{ display: "inline" }}>Candidates</h4>
              <div className={"float-right marign-right-5"}>
                <Form.Control type="text"
                              placeholder="Search"
                              value={this.state.search}
                              onChange={this.onSearchChange}
                />
              </div>
            </Col>
          </Row>
        </Container>
        <div className="table-wrapper">
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Job Title</th>
                <th>Company Name</th>
                <th>Date Submitted</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {!this.props.candidates || this.props.candidates.length === 0 ? (
                <tr key="noCandidatesKey">
                  <td colSpan={7} className="text-center">
                    <small style={{ marginLeft: "auto", marginRight: "auto" }}>
                      No candidates with specified filter
                    </small>
                  </td>
                </tr>
              ) : (
                this.props.candidates.map((candidate) => (
                  <React.Fragment key={candidate.candidateId}>
                    <tr className="tr-main">
                      <td>
                        {candidate.firstName} {candidate.lastName}
                      </td>
                      <td>
                        {candidate.jobTitle}
                      </td>
                      <td>
                        {candidate.jobCompanyName}
                      </td>
                      <td>
                        {moment(candidate.submittedAt).fromNow()}
                      </td>
                      <td>
                        <CandidateStatus candidate={candidate} />{" "}
                        <OverlayTrigger 
                          placement="bottom"
                          overlay={ 
                            <Tooltip>
                              {(() => {
                                if (candidate.candidateInvitedByEmployer) return "Employer has requested to interview your candidate";
                                if (candidate.candidateRejectedByEmployer) return "Good candidate, but did not pass employer screening";
                                if (candidate.employerAgreedOnLicenseTerms) return "Awaiting on employer decision";
                                if (candidate.acceptedByAdmin === true) return "Candidate submitted to employer";
                                // Only visible to admin/recruiter from here
                                if (candidate.acceptedByAdmin === false) return "Candidate did not meet the must have skills for this role";
                                if (!candidate.verified) return "Waiting for candidate to confirm their interest in this role";
                                return "Your candidate is under review (expect an update within 7 days)";
                              })()}
                            </Tooltip>}>
                          <span className="material-icons"> help_outline </span>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  </React.Fragment>
                ))
              )}
            </tbody>
          </Table>
        </div>
        <Pagination
          activePage={this.state.pageNr + 1}
          itemsCountPerPage={this.state.perPage}
          totalItemsCount={this.props.totalCount}
          pageRangeDisplayed={5}
          onChange={this.pageChange}
        />
      </>
    );
  }
}

export default connect(
  (state) => ({
    candidates: state.candidates.candidates,
    totalCount: state.candidates.totalCount,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        findCandidates,
      },
      dispatch
    )
)(ListOfCandidatesForAdmin);
