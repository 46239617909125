import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { verifyCandidate } from "../actions/candidates";
import { Alert, Spinner, Jumbotron } from "react-bootstrap";

class VerifyCandidatePage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    verifyingCandidate: PropTypes.bool.isRequired,
    candidateVerified: PropTypes.bool.isRequired,
    verifyCandidateError: PropTypes.string.isRequired,
    verifyCandidate: PropTypes.func.isRequired,
  };
  _renderError() {
    if (!this.props.verifyCandidateError) return null;
    return <Alert variant="danger">{this.props.verifyCandidateError}</Alert>;
  }
  async componentDidMount() {
    const { candidateId } = this.props.match.params;
    await this.props.verifyCandidate({ candidateId });
  }

  render() {
    const { verifyingCandidate, candidateVerified } = this.props;
    return (
      <Jumbotron>
        <h1>Application verification</h1>
        <div>
          {this._renderError()}
          {candidateVerified ? (
            <Alert variant="success">
              Application verification success, Your resume and application has
              been successfully submitted, your recruiter will contact you when
              an interview request is made
            </Alert>
          ) : null}
          {verifyingCandidate ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : null}
        </div>
      </Jumbotron>
    );
  }
}

export default connect(
  (state) => ({
    verifyingCandidate: state.candidates.verifyingCandidate,
    candidateVerified: state.candidates.candidateVerified,
    verifyCandidateError: state.candidates.verifyCandidateError,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        verifyCandidate,
      },
      dispatch
    )
)(VerifyCandidatePage);
