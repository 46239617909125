const industries = Object.freeze([
  { value: "0", text: "Accounting" },
  { value: "1", text: "Airlines/Aviation" },
  { value: "2", text: "Alternative Dispute Resolution" },
  { value: "3", text: "Alternative Medicine" },
  { value: "4", text: "Animation" },
  { value: "5", text: "Apparel & Fashion" },
  { value: "6", text: "Architecture & Planning" },
  { value: "7", text: "Arts & Crafts" },
  { value: "8", text: "Automotive" },
  { value: "9", text: "Aviation & Aerospace" },
  { value: "10", text: "Banking" },
  { value: "11", text: "Biotechnology" },
  { value: "12", text: "Broadcast Media" },
  { value: "13", text: "Building Materials" },
  { value: "14", text: "Business Supplies & Equipment" },
  { value: "15", text: "Capital Markets" },
  { value: "16", text: "Chemicals" },
  { value: "17", text: "Civic & Social Organization" },
  { value: "18", text: "Civil Engineering" },
  { value: "19", text: "Commercial Real Estate" },
  { value: "20", text: "Computer & Network Security" },
  { value: "21", text: "Computer Games" },
  { value: "22", text: "Computer Hardware" },
  { value: "23", text: "Computer Networking" },
  { value: "24", text: "Computer Software" },
  { value: "25", text: "Construction" },
  { value: "26", text: "Consumer Electronics" },
  { value: "27", text: "Consumer Goods" },
  { value: "28", text: "Consumer Services" },
  { value: "29", text: "Cosmetics" },
  { value: "30", text: "Dairy" },
  { value: "31", text: "Defense & Space" },
  { value: "32", text: "Design" },
  { value: "33", text: "Education Management" },
  { value: "34", text: "E-learning" },
  { value: "35", text: "Electrical & Electronic Manufacturing" },
  { value: "36", text: "Entertainment" },
  { value: "37", text: "Environmental Services" },
  { value: "38", text: "Events Services" },
  { value: "39", text: "Executive Office" },
  { value: "40", text: "Facilities Services" },
  { value: "41", text: "Farming" },
  { value: "42", text: "Financial Services" },
  { value: "43", text: "Fine Art" },
  { value: "44", text: "Fishery" },
  { value: "45", text: "Food & Beverages" },
  { value: "46", text: "Food Production" },
  { value: "47", text: "Fundraising" },
  { value: "48", text: "Furniture" },
  { value: "49", text: "Gambling & Casinos" },
  { value: "50", text: "Glass, Ceramics & Concrete" },
  { value: "51", text: "Government Administration" },
  { value: "52", text: "Government Relations" },
  { value: "53", text: "Graphic Design" },
  { value: "54", text: "Health, Wellness & Fitness" },
  { value: "55", text: "Higher Education" },
  { value: "56", text: "Hospital & Health Care" },
  { value: "57", text: "Hospitality" },
  { value: "58", text: "Human Resources" },
  { value: "59", text: "Import & Export" },
  { value: "60", text: "Individual & Family Services" },
  { value: "61", text: "Industrial Automation" },
  { value: "62", text: "Information Services" },
  { value: "63", text: "Information Technology & Services" },
  { value: "64", text: "Insurance" },
  { value: "65", text: "International Affairs" },
  { value: "66", text: "International Trade & Development" },
  { value: "67", text: "Internet" },
  { value: "68", text: "Investment Banking/Venture" },
  { value: "69", text: "Investment Management" },
  { value: "70", text: "Judiciary" },
  { value: "71", text: "Law Enforcement" },
  { value: "72", text: "Law Practice" },
  { value: "73", text: "Legal Services" },
  { value: "74", text: "Legislative Office" },
  { value: "75", text: "Leisure & Travel" },
  { value: "76", text: "Libraries" },
  { value: "77", text: "Logistics & Supply Chain" },
  { value: "78", text: "Luxury Goods & Jewelry" },
  { value: "79", text: "Machinery" },
  { value: "80", text: "Management Consulting" },
  { value: "81", text: "Maritime" },
  { value: "82", text: "Marketing & Advertising" },
  { value: "83", text: "Market Research" },
  { value: "84", text: "Mechanical or Industrial Engineering" },
  { value: "85", text: "Media Production" },
  { value: "86", text: "Medical Device" },
  { value: "87", text: "Medical Practice" },
  { value: "88", text: "Mental Health Care" },
  { value: "89", text: "Military" },
  { value: "90", text: "Mining & Metals" },
  { value: "91", text: "Motion Pictures & Film" },
  { value: "92", text: "Museums & Institutions" },
  { value: "93", text: "Music" },
  { value: "94", text: "Nanotechnology" },
  { value: "95", text: "Newspapers" },
  { value: "96", text: "Nonprofit Organization Management" },
  { value: "97", text: "Oil & Energy" },
  { value: "98", text: "Online Publishing" },
  { value: "99", text: "Outsourcing/Offshoring" },
  { value: "100", text: "Package/Freight Delivery" },
  { value: "101", text: "Packaging & Containers" },
  { value: "102", text: "Paper & Forest Products" },
  { value: "103", text: "Performing Arts" },
  { value: "104", text: "Pharmaceuticals" },
  { value: "105", text: "Philanthropy" },
  { value: "106", text: "Photography" },
  { value: "107", text: "Plastics" },
  { value: "108", text: "Political Organization" },
  { value: "109", text: "Primary/Secondary" },
  { value: "110", text: "Printing" },
  { value: "111", text: "Professional Training" },
  { value: "112", text: "Program Development" },
  { value: "113", text: "Public Policy" },
  { value: "114", text: "Public Relations" },
  { value: "115", text: "Public Safety" },
  { value: "116", text: "Publishing" },
  { value: "117", text: "Railroad Manufacture" },
  { value: "118", text: "Ranching" },
  { value: "119", text: "Real Estate" },
  { value: "120", text: "Recreational" },
  { value: "121", text: "Facilities & Services" },
  { value: "122", text: "Religious Institutions" },
  { value: "123", text: "Renewables & Environment" },
  { value: "124", text: "Research" },
  { value: "125", text: "Restaurants" },
  { value: "126", text: "Retail" },
  { value: "127", text: "Security & Investigations" },
  { value: "128", text: "Semiconductors" },
  { value: "129", text: "Shipbuilding" },
  { value: "130", text: "Sporting Goods" },
  { value: "131", text: "Sports" },
  { value: "132", text: "Staffing & Recruiting" },
  { value: "133", text: "Supermarkets" },
  { value: "134", text: "Telecommunications" },
  { value: "135", text: "Textiles" },
  { value: "136", text: "Think Tanks" },
  { value: "137", text: "Tobacco" },
  { value: "138", text: "Translation & Localization" },
  { value: "139", text: "Transportation/Trucking/Railroad" },
  { value: "140", text: "Utilities" },
  { value: "141", text: "Venture Capital" },
  { value: "142", text: "Veterinary" },
  { value: "143", text: "Warehousing" },
  { value: "144", text: "Wholesale" },
  { value: "145", text: "Wine & Spirits" },
  { value: "146", text: "Wireless" },
  { value: "147", text: "Writing & Editing" },
  { value: "148", text: "Other" },
]);

export default industries;
