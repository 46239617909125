import React, { useCallback, useMemo } from 'react';
import {useDropzone} from 'react-dropzone';
import { toast } from "react-toastify";

const MAX_FILES = 3;

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};
  
const focusedStyle = {
borderColor: '#2196f3'
};

const acceptStyle = {
borderColor: '#00e676'
};

const rejectStyle = {
borderColor: '#ff1744'
};
  

function FileDropZone(props) {

  const onDropRejected = useCallback(rejectedFiles => {
    // console.log(rejectedFiles);
    rejectedFiles.forEach(item => {
      toast.error(`File '${item.file.path}' was rejected with error: ${item.errors[0].message}`);
    });
  }, []);

  // const onDrop = useCallback(acceptedFiles => {
  const onDropAccepted = useCallback(acceptedFiles => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      // console.log('reading file', file);

      reader.onabort = () => console.error(`'${file.path}': file reading was aborted`);
      reader.onerror = () => console.error(`'${file.path}': file reading has failed`);
      reader.onload = () => {
        // console.log(`'${file.path}': read OK`);

        const binaryStr = reader.result;
        // console.log(binaryStr);

        file.data = binaryStr;        
        props.onAcceptedFile(file);
      }
      reader.readAsArrayBuffer(file);
    });

  }, [props]);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({    
    maxFiles: props.maxFiles || MAX_FILES,
    maxSize: 5 * 10 ** 6, /** 5Mb */
    noDragEventsBubbling: true,
    accept: {
      'application/pdf':  ['.pdf'],
    },
    // onDrop,
    onDropAccepted,
    onDropRejected,
  }); 

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  return (
    <section className="container">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop PDF files here, or click to select files</p>
        <em>({props.maxFiles} files are the maximum number of files you can drop here)</em>
      </div>
    </section>
  );
}

export default FileDropZone;