import {
  FIND_CODES,
  FIND_CODES_COMPLETED,
  FIND_CODES_FAILED,
  SEND_CODE,
  SEND_CODE_COMPLETED,
  SEND_CODE_FAILED,
} from "../actions/codes";
import { PAGE_INIT } from "../actions/pages";

export const defaultState = Object.freeze({
  codes: [],
  codeLoading: false,
  findingCodesErrors: "",
  sendingCodeErrors: {},
  totalCount: 0,
});

const customCodesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case PAGE_INIT: {
      return defaultState;
    }
    case FIND_CODES: {
      return {
        ...state,
        codeLoading: true,
        findingCodesErrors: "",
      };
    }
    case FIND_CODES_COMPLETED: {
      return {
        ...state,
        codeLoading: false,
        codes: action.payload.results,
        totalCount: action.payload.total,
      };
    }
    case FIND_CODES_FAILED: {
      return {
        ...state,
        codeLoading: false,
        findingCodesErrors: action.payload.message,
      };
    }
    case SEND_CODE: {
      return {
        ...state,
        codeLoading: true,
        sendingCodeErrors: {},
      };
    }
    case SEND_CODE_COMPLETED: {
      return {
        ...state,
        codeLoading: false,
        sendingCodeErrors: {},
      };
    }
    case SEND_CODE_FAILED: {
      let sendingCodeErrors;
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        if (Array.isArray(action.payload.response.data.message)) {
          sendingCodeErrors = action.payload.response.data.message.reduce(
            (errors, error) => {
              errors[error.field] = error.message;
              return errors;
            },
            {}
          );
        } else {
          sendingCodeErrors = { $form: action.payload.response.data.message };
        }
      } else {
        sendingCodeErrors = { $form: action.payload.message };
      }
      return {
        ...state,
        codeLoading: false,
        sendingCodeErrors,
      };
    }
    default:
      return state;
  }
};

export default customCodesReducer;
