import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changePasswordWithGuid } from "../actions/account";
import { Col, Button, Form, Jumbotron } from "react-bootstrap";

class ResetPasswordGuidPage extends Component {
  static propTypes = {
    changePasswordWithGuid: PropTypes.func.isRequired,
  };
  state = {
    newPassword: "",
    newPasswordRepeat: "",
    newPasswordIsNotSame: false,
  };
  onSubmit = async (e) => {
    e.preventDefault();
    const { newPasswordRepeat, newPassword } = this.state;
    try {
      await this.props.changePasswordWithGuid({
        newPasswordRepeat,
        newPassword,
        guid: this.props.match.params.guid,
      });
      this.setState({
        newPassword: "",
        newPasswordRepeat: "",
        newPasswordIsNotSame: false,
      });
    } catch (e) {
      console.log(e);
    }
  };
  handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
      {
        [name]: value,
      },
      this.validIsNewPasswordSameAsRepeat
    );
  };
  validIsNewPasswordSameAsRepeat = () => {
    const { newPasswordRepeat, newPassword } = this.state;
    if (newPassword && newPasswordRepeat && newPassword !== newPasswordRepeat) {
      this.setState({
        newPasswordIsNotSame: true,
      });
    } else {
      this.setState({
        newPasswordIsNotSame: false,
      });
    }
  };
  render() {
    return (
      <Jumbotron>
        <h4>Change password</h4>
        <div>
          <Form.Row className="mb-2">
            <Col sm={4}>
              <Form.Label>New password</Form.Label>
            </Col>
            <Col sm={8}>
              <Form.Control
                type="password"
                value={this.state.newPassword}
                isInvalid={
                  this.state.newPassword && this.state.newPassword.length < 8
                }
                onChange={this.handleInputChange}
                name="newPassword"
              />
              <Form.Control.Feedback type="invalid">
                {this.state.newPassword && this.state.newPassword.length < 8
                  ? "must be at least 8 characters"
                  : ""}
              </Form.Control.Feedback>
            </Col>
          </Form.Row>
          <Form.Row className="mb-2">
            <Col sm={4}>
              <Form.Label>Repeat new password</Form.Label>
            </Col>
            <Col sm={8}>
              <Form.Control
                isInvalid={this.state.newPasswordIsNotSame}
                type="password"
                value={this.state.newPasswordRepeat}
                onChange={this.handleInputChange}
                name="newPasswordRepeat"
              />
              <Form.Control.Feedback type="invalid">
                {this.state.newPasswordIsNotSame
                  ? "new password is not same as repeat"
                  : ""}
              </Form.Control.Feedback>
            </Col>
          </Form.Row>
          <Button
            className="pull-right"
            variant="primary"
            onClick={this.onSubmit}
            disabled={
              this.state.newPasswordIsNotSame ||
              (!this.state.newPassword && !this.state.newPasswordRepeat) ||
              this.state.newPassword.length < 8
            }
          >
            Change password
          </Button>
          <Button
            onClick={() => this.props.history.push(`/recruiter`)}
            variant="secondary"
          >
            Home page
          </Button>
        </div>
      </Jumbotron>
    );
  }
}

export default connect(
  (state) => ({}),
  (dispatch) =>
    bindActionCreators(
      {
        changePasswordWithGuid: changePasswordWithGuid,
      },
      dispatch
    )
)(ResetPasswordGuidPage);
