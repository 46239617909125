import {
  SAVE_JOB_POSTING_DRAFT,
  SAVE_JOB_POSTING_DRAFT_COMPLETED,
  SAVE_JOB_POSTING_DRAFT_FAILED,
  SAVE_JOB_POSTING,
  SAVE_JOB_POSTING_COMPLETED,
  SAVE_JOB_POSTING_FAILED,
  FIND_JOBS,
  FIND_JOBS_COMPLETED,
  FIND_JOBS_FAILED,
  FIND_ALL_JOBS,
  FIND_ALL_JOBS_COMPLETED,
  FIND_ALL_JOBS_FAILED,
  FIND_JOB,
  FIND_JOB_COMPLETED,
  FIND_JOB_FAILED,
  REMOVE_JOB_POSTING,
  REMOVE_JOB_POSTING_COMPLETED,
  REMOVE_JOB_POSTING_FAILED,
  ACTIVE_JOB_CAST,
  ACTIVE_JOB_CAST_COMPLETED,
  ACTIVE_JOB_CAST_FAILED,
  REMOVE_JOB_CAST,
  REMOVE_JOB_CAST_COMPLETED,
  REMOVE_JOB_CAST_FAILED,
  GET_ACTIVE_JOB_CAST,
  GET_ACTIVE_JOB_CAST_COMPLETED,
  GET_ACTIVE_JOB_CAST_FAILED,
  FILTER_JOB_CANDIDATES,
  FILTER_JOB_CANDIDATES_COMPLETED,
  FILTER_JOB_CANDIDATES_FAILED, CHANGE_FAVORITE_JOB_STATUS, CHANGE_FAVORITE_JOB_STATUS_COMPLETED
} from "../actions/jobs";
import { PAGE_INIT } from "../actions/pages";
import { toast } from "react-toastify";

export const defaultState = Object.freeze({
  savingJobPostingDraft: false,
  formJobError: "",
  draftJobId: null,
  findingJobs: false,
  findJobsError: "",
  jobs: [],
  findingJob: false,
  findJobError: "",
  job: {},
  findAllJobsError: false,
  findingAllJobs: false,
  allJobs: [],
  userActiveJobCastsIds: [],
  itemsCount: 0,
  changingFavorite: false,
});

const jobsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case PAGE_INIT: {
      return { ...defaultState };
    }
    case REMOVE_JOB_CAST: {
      return {
        ...state
      };
    }
    case REMOVE_JOB_CAST_COMPLETED: {
      const newUserActiveJobs = [...state.userActiveJobCastsIds].filter(
        x => x !== action.payload.jobId
      );
      return {
        ...state,
        userActiveJobCastsIds: newUserActiveJobs
      };
    }
    case REMOVE_JOB_CAST_FAILED: {
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        if (Array.isArray(action.payload.response.data.message)) {
          toast.error(
            JSON.stringify(
              action.payload.response.data.message.reduce((errors, error) => {
                errors[error.field] = error.message;
                return errors;
              }, {})
            )
          );
        } else {
          toast.error(action.payload.response.data.message);
        }
      } else {
        toast.error(action.payload.message);
      }
      return {
        ...state
      };
    }
    case ACTIVE_JOB_CAST: {
      return {
        ...state
      };
    }
    case ACTIVE_JOB_CAST_COMPLETED: {
      const newUserActiveJobs = [
        ...state.userActiveJobCastsIds,
        action.payload.jobId
      ];
      return {
        ...state,
        userActiveJobCastsIds: newUserActiveJobs
      };
    }
    case ACTIVE_JOB_CAST_FAILED: {
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        if (Array.isArray(action.payload.response.data.message)) {
          toast.error(
            JSON.stringify(
              action.payload.response.data.message.reduce((errors, error) => {
                errors[error.field] = error.message;
                return errors;
              }, {})
            )
          );
        } else {
          toast.error(action.payload.response.data.message);
        }
      } else {
        toast.error(action.payload.message);
      }
      return {
        ...state
      };
    }
    case SAVE_JOB_POSTING_DRAFT: {
      return {
        ...state,
        savingJobPostingDraft: true,
        formJobError: ""
      };
    }
    case SAVE_JOB_POSTING_DRAFT_COMPLETED: {
      return {
        ...state,
        savingJobPostingDraft: false,
        draftJobId: action.payload.jobId,
        formJobError: ""
      };
    }
    case SAVE_JOB_POSTING_DRAFT_FAILED: {
      let formJobError;
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        if (Array.isArray(action.payload.response.data.message)) {
          formJobError = JSON.stringify(
            action.payload.response.data.message.reduce((errors, error) => {
              errors[error.field] = error.message;
              return errors;
            }, {})
          );
        } else {
          formJobError = action.payload.response.data.message;
        }
      } else {
        formJobError = action.payload.message;
      }
      return {
        ...state,
        savingJobPostingDraft: false,
        formJobError
      };
    }
    case SAVE_JOB_POSTING: {
      return {
        ...state,
        savingJobPostingDraft: true,
        formJobError: ""
      };
    }
    case SAVE_JOB_POSTING_COMPLETED: {
      return {
        ...state,
        savingJobPostingDraft: false,
        draftJobId: action.payload.jobId,
        formJobError: ""
      };
    }
    case SAVE_JOB_POSTING_FAILED: {
      let formJobError;
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        if (Array.isArray(action.payload.response.data.message)) {
          formJobError = JSON.stringify(
            action.payload.response.data.message.reduce((errors, error) => {
              errors[error.field] = error.message;
              return errors;
            }, {})
          );
        } else {
          formJobError = action.payload.response.data.message;
        }
      } else {
        formJobError = action.payload.message;
      }
      return {
        ...state,
        savingJobPostingDraft: false,
        formJobError
      };
    }
    case REMOVE_JOB_POSTING: {
      return {
        ...state
      };
    }
    case REMOVE_JOB_POSTING_COMPLETED: {
      const job = state.jobs.find(x => x.jobId === action.payload.jobId);
      if (job.draft) {
        return {
          ...state,
          jobs: state.jobs.filter(x => x.jobId !== action.payload.jobId),
          itemsCount: state.itemsCount - 1
        };
      } else {
        return {
          ...state,
          jobs: state.jobs.map(x => {
            if (x.jobId === action.payload.jobId) {
              x.isDeleted = true;
            }
            return x;
          })
        };
      }
    }
    case REMOVE_JOB_POSTING_FAILED: {
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        if (Array.isArray(action.payload.response.data.message)) {
          toast.error(
            JSON.stringify(
              action.payload.response.data.message.reduce((errors, error) => {
                errors[error.field] = error.message;
                return errors;
              }, {})
            )
          );
        } else {
          toast.error(action.payload.response.data.message);
        }
      } else {
        toast.error(action.payload.message);
      }
      return {
        ...state
      };
    }
    case GET_ACTIVE_JOB_CAST: {
      return {
        ...state
      };
    }
    case GET_ACTIVE_JOB_CAST_COMPLETED: {
      return {
        ...state,
        userActiveJobCastsIds: action.payload
      };
    }
    case GET_ACTIVE_JOB_CAST_FAILED: {
      return {
        ...state
      };
    }
    case FIND_ALL_JOBS: {
      return {
        ...state,
        findingAllJobs: true,
        findAllJobsError: ""
      };
    }
    case FIND_ALL_JOBS_COMPLETED: {
      return {
        ...state,
        findingAllJobs: false,
        allJobs: action.payload.results,
        itemsCount: action.payload.total,
      };
    }
    case FIND_ALL_JOBS_FAILED: {
      toast.error(
        action.payload.response &&
          action.payload.response.data &&
          action.payload.response.data.message
      );
      return {
        ...state,
        findingAllJobs: false,
        findAllJobsError: action.payload.message
      };
    }
    case FILTER_JOB_CANDIDATES: {
      const jobId = action.payload.jobId;
      const updateCandidates = job => {
        if (job.jobId === jobId) {
          job.candidates = [];
          job.activeCandidateFilter = null;
        }
        return job;
      };
      return {
        ...state,
        findingCandidatesForJob: true,
        jobs: state.jobs.map(updateCandidates),
        allJobs: state.allJobs.map(updateCandidates),
      };
    }
    case FILTER_JOB_CANDIDATES_COMPLETED: {
      const { jobId, candidates, status } = action.payload;
      const updateCandidates = job => {
        if (job.jobId === jobId) {
          job.candidates = candidates;
          job.activeCandidateFilter = status;
          switch (status) {
            case "all":
              job.noOfCandidates = candidates.length;
              break;
            case "selectedForInterview":
              job.noOfCandidatesSelectedForInterview = candidates.length;
              break;
            case "rejected":
              job.noOfRejectedCandidates = candidates.length;
              break;
            case "offersMade":
              job.noOfOffersMadeForCandidates = candidates.length;
              break;
            case "offersAccepted":
              job.noOfOffersAccepted = candidates.length;
              break;
            default:
          }
        }
        return job;
      };
      return {
        ...state,
        findingCandidatesForJob: false,
        jobs: state.jobs.map(updateCandidates),
        allJobs: state.allJobs.map(updateCandidates),
      };
    }
    case FILTER_JOB_CANDIDATES_FAILED: {
      toast.error(
        action.payload.response &&
          action.payload.response.data &&
          action.payload.response.data.message
      );
      return {
        ...state,
        findingCandidatesForJob: false,
        findingCandidatesForJobError: action.payload.message
      };
    }
    case FIND_JOBS: {
      return {
        ...state,
        findingJobs: true,
        findJobsError: ""
      };
    }
    case FIND_JOBS_COMPLETED: {
      return {
        ...state,
        findingJobs: false,
        jobs: action.payload.results,
        itemsCount: action.payload.total,
      };
    }
    case FIND_JOBS_FAILED: {
      toast.error(
        action.payload.response &&
          action.payload.response.data &&
          action.payload.response.data.message
      );
      return {
        ...state,
        findingJobs: false,
        findJobsError: action.payload.message
      };
    }
    case FIND_JOB: {
      return {
        ...state,
        findingJob: true,
        findJobError: ""
      };
    }
    case FIND_JOB_COMPLETED: {
      return {
        ...state,
        findingJob: false,
        job: action.payload || {},
        draftJobId: action.payload && action.payload.jobId
      };
    }
    case FIND_JOB_FAILED: {
      toast.error(
        action.payload.response &&
          action.payload.response.data &&
          action.payload.response.data.message
      );
      return {
        ...state,
        findingJob: false,
        findJobError: action.payload.message
      };
    }
    case CHANGE_FAVORITE_JOB_STATUS: {
      return {
        ...state,
        changingFavorite: true,
        changeFavoriteError: '',
      };
    }
    case CHANGE_FAVORITE_JOB_STATUS_COMPLETED: {
      const allJobs = state.allJobs.map(job =>
        job.jobId === action.payload.jobId
          ? ({...job, favorite: action.payload.favorite})
          : job
      );
      return {
        ...state,
        changingFavorite: false,
        allJobs,
      };
    }
    default:
      return state;
  }
};

export default jobsReducer;
