import { command, find } from "../utils/api";

const CONVERSATIONS_ACTION_URL = `${process.env.PUBLIC_URL}/api/v1/conversations`;

export const GET_CONVERSATIONS = "GET_CONVERSATIONS";
export const GET_CONVERSATIONS_COMPLETED = "GET_CONVERSATIONS_COMPLETED";
export const GET_CONVERSATIONS_FAILED = "GET_CONVERSATIONS_FAILED";

export const getConversations = find(
  CONVERSATIONS_ACTION_URL + "/get",
  GET_CONVERSATIONS,
  GET_CONVERSATIONS_COMPLETED,
  GET_CONVERSATIONS_FAILED
);

export const GET_MESSAGES = "GET_MESSAGES";
export const GET_MESSAGES_COMPLETED = "GET_MESSAGES_COMPLETED";
export const GET_MESSAGES_FAILED = "GET_MESSAGES_FAILED";

export const getMessages = find(
  CONVERSATIONS_ACTION_URL + "/messages",
  GET_MESSAGES,
  GET_MESSAGES_COMPLETED,
  GET_MESSAGES_FAILED
);

export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_COMPLETED = "SEND_MESSAGE_COMPLETED";
export const SEND_MESSAGE_FAILED = "SEND_MESSAGE_FAILED";

export const sendMessage = command(
  CONVERSATIONS_ACTION_URL + "/sendMessage",
  SEND_MESSAGE,
  SEND_MESSAGE_COMPLETED,
  SEND_MESSAGE_FAILED
);

export const NEW_MESSAGE = "NEW_MESSAGE";

export const handleNewMessage = function(payload) {
  return async function(dispatch) {
    dispatch({
      type: NEW_MESSAGE,
      payload: payload
    });
  };
};

export const CONVERSATION_UPDATE = "CONVERSATION_UPDATE";

export const handleConversationUpdate = function(payload) {
  return async function(dispatch) {
    dispatch({
      type: CONVERSATION_UPDATE,
      payload: payload
    });
  };
};

export const READ_CONVERSATION = 'READ_CONVERSATION';
export const READ_CONVERSATION_COMPLETED = 'READ_CONVERSATION_COMPLETED';
export const READ_CONVERSATION_FAILED = 'READ_CONVERSATION_FAILED';

export const readConversation = command(
  CONVERSATIONS_ACTION_URL + '/ReadConversation',
  READ_CONVERSATION,
  READ_CONVERSATION_COMPLETED,
  READ_CONVERSATION_FAILED
);
