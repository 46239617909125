import {
  FIND_USERS,
  FIND_USERS_COMPLETED,
  FIND_USERS_FAILED,
  FIND_USER,
  FIND_USER_COMPLETED,
  FIND_USER_FAILED,
  USER_BLOCK,
  USER_BLOCK_COMPLETED,
  USER_BLOCK_FAILED,
  USER_UNBLOCK,
  USER_UNBLOCK_COMPLETED,
  USER_UNBLOCK_FAILED,
  USER_DELETE,
  USER_DELETE_COMPLETED,
  USER_DELETE_FAILED
} from "../actions/users";
import { PAGE_INIT } from "../actions/pages";
import { toast } from "react-toastify";

export const defaultState = Object.freeze({
  findingUsers: false,
  users: [],
  findUsersError: "",
  findingUser: false,
  findUserError: "",
  user: {},
  totalCount: 0,
  deletingUser: false,
  deleteUserError: ""
});

const usersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case PAGE_INIT: {
      return defaultState;
    }
    case USER_BLOCK: {
      return {
        ...state,
      };
    }
    case USER_BLOCK_COMPLETED: {
      return {
        ...state,
        users: state.users.map((x) => {
          if (x.userId === action.payload.userId) {
            x.isBlocked = true;
          }
          return x;
        }),
      };
    }
    case USER_BLOCK_FAILED: {
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        toast.error(action.payload.response.data.message);
      } else {
        toast.error(`${action.payload.response.data}`);
      }
      return {
        ...state,
      };
    }
    case USER_UNBLOCK: {
      return {
        ...state,
      };
    }
    case USER_UNBLOCK_COMPLETED: {
      return {
        ...state,
        users: state.users.map((x) => {
          if (x.userId === action.payload.userId) {
            x.isBlocked = false;
          }
          return x;
        }),
      };
    }
    case USER_UNBLOCK_FAILED: {
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        toast.error(action.payload.response.data.message);
      } else {
        toast.error(`${action.payload.response.data}`);
      }
      return {
        ...state,
      };
    }
    case FIND_USERS: {
      return {
        ...state,
        findingUsers: true,
        findUsersError: "",
      };
    }
    case FIND_USERS_COMPLETED: {
      return {
        ...state,
        findingUsers: false,
        users: action.payload.results,
        totalCount: action.payload.total,
      };
    }
    case FIND_USERS_FAILED: {
      return {
        ...state,
        findingUsers: false,
        findUsersError: action.payload.message,
      };
    }
    case FIND_USER: {
      return {
        ...state,
        findingUser: true,
        findUserError: "",
      };
    }
    case FIND_USER_COMPLETED: {
      return {
        ...state,
        findingUser: false,
        user: action.payload || {},
        draftJobId: action.payload && action.payload.jobId,
      };
    }
    case FIND_USER_FAILED: {
      return {
        ...state,
        findingUser: false,
        findUserError: action.payload.message,
      };
    }
    case USER_DELETE: {
      return {
        ...state,
        deletingUser: true,
        deleteUserError: "",
      };
    }
    case USER_DELETE_COMPLETED: {
      const { userId } = action.payload;
      return {
        ...state,
        deletingUser: false,
        users: state.users.filter(u => u.userId !== userId),
      };
    }
    case USER_DELETE_FAILED: {
      const { message } = action.payload;
      return {
        ...state,
        deletingUser: false,
        deleteUserError: message,
      };
    }
    default:
      return state;
  }
};

export default usersReducer;
