import { find, command } from "../utils/api";

const RETAIL_TERMS_ACTION_URL = `${process.env.PUBLIC_URL}/api/v1/retailTerms`;

export const FIND_RETAIL_TERMS = "FIND_RETAIL_TERMS";
export const FIND_RETAIL_TERMS_COMPLETED = "FIND_RETAIL_TERMS_COMPLETED";
export const FIND_RETAIL_TERMS_FAILED = "FIND_RETAIL_TERMS_FAILED";

export const findRetailTerms = find(
  RETAIL_TERMS_ACTION_URL + "/find",
  FIND_RETAIL_TERMS,
  FIND_RETAIL_TERMS_COMPLETED,
  FIND_RETAIL_TERMS_FAILED
);

export const CREATE_RETAIL_TERM = "CREATE_RETAIL_TERM";
export const CREATE_RETAIL_TERM_COMPLETED = "CREATE_RETAIL_TERM_COMPLETED";
export const CREATE_RETAIL_TERM_FAILED = "CREATE_RETAIL_TERM_FAILED";

export const createRetailTerm = command(
  RETAIL_TERMS_ACTION_URL + "/createRetailTerm",
  CREATE_RETAIL_TERM,
  CREATE_RETAIL_TERM_COMPLETED,
  CREATE_RETAIL_TERM_FAILED
);

export const editRetailTerm = command(
  RETAIL_TERMS_ACTION_URL + "/editRetailTerm",
  CREATE_RETAIL_TERM,
  CREATE_RETAIL_TERM_COMPLETED,
  CREATE_RETAIL_TERM_FAILED
);
export const DELETE_RETAIL_TERM = "DELETE_RETAIL_TERM";
export const DELETE_RETAIL_TERM_COMPLETED = "DELETE_RETAIL_TERM_COMPLETED";
export const DELETE_RETAIL_TERM_FAILED = "DELETE_RETAIL_TERM_FAILED";

export const deleteRetailTerm = command(
    RETAIL_TERMS_ACTION_URL + "/deleteRetailTerm",
    DELETE_RETAIL_TERM,
    DELETE_RETAIL_TERM_COMPLETED,
    DELETE_RETAIL_TERM_FAILED
);
