import {UPLOAD_FILE, UPLOAD_FILE_COMPLETED, UPLOAD_FILE_FAILED, UPLOAD_FILE_PROGRESS} from "../actions/files";
import {PAGE_INIT} from "../actions/pages";

export const defaultState = Object.freeze({
  progress: {
    state: '',
    percentage: 0,
    error: '',
    filename: '',
  },
});

const filesReducer = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    case PAGE_INIT: {
      return defaultState;
    }
    case UPLOAD_FILE: {
      return {
        ...state,
        progress: {state: 'uploading', percentage: 0},
      }
    }
    case UPLOAD_FILE_PROGRESS: {
      const event = action.payload;
      return {
        ...state,
        progress: {state: 'uploading', percentage: Math.round((event.loaded / event.total) * 100)},
      }
    }
    case UPLOAD_FILE_COMPLETED: {
      return {
        ...state,
        progress: {state: 'done', percentage: 100, filename: action.payload.filename},
      }
    }
    case UPLOAD_FILE_FAILED: {
      return {
        ...state,
        progress: {state: 'error', percentage: 0, error: action.payload.response.data.message},
      }
    }
    default:
      return state;
  }
};

export default filesReducer;