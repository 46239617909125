import {
  CANDIDATE_ACCEPT_TERMS,
  CANDIDATE_ACCEPT_TERMS_COMPLETED,
  CANDIDATE_ACCEPT_TERMS_FAILED,
  CANDIDATE_VERIFY,
  CANDIDATE_VERIFY_COMPLETED,
  CANDIDATE_VERIFY_FAILED,
  FIND_CANDIDATE,
  FIND_CANDIDATE_COMPLETED,
  FIND_CANDIDATE_FAILED,
  FIND_CANDIDATES,
  FIND_CANDIDATES_COMPLETED,
  FIND_CANDIDATES_FAILED,
  SAVE_CANDIDATE_POSTING,
  SAVE_CANDIDATE_POSTING_COMPLETED,
  SAVE_CANDIDATE_POSTING_FAILED,
  CANDIDATE_REJECT,
  CANDIDATE_REJECT_COMPLETED,
  CANDIDATE_REJECT_FAILED,
  CANDIDATE_INVITE,
  CANDIDATE_INVITE_COMPLETED,
  CANDIDATE_INVITE_FAILED,
  FIND_INTERVIEW_TIMES,
  FIND_INTERVIEW_TIMES_COMPLETED,
  FIND_INTERVIEW_TIMES_FAILED,
} from "../actions/candidates";
import { PAGE_INIT } from "../actions/pages";
import { toast } from "react-toastify";

export const defaultState = Object.freeze({
  savingCandidatePostingDraft: false,
  formCandidateError: "",
  draftCandidateId: null,
  findingCandidates: false,
  findCandidatesError: "",
  candidates: [],
  findingCandidate: false,
  findCandidateError: "",
  candidate: {},
  interviewTimes: [],
  interviewTimesRecordCounts: 0,
  totalCount: 0,
  orders: undefined
});

const candidatesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case PAGE_INIT: {
      const { candidates } = state;
      return { ...defaultState, candidates };
    }
    case FIND_INTERVIEW_TIMES: {
      return {
        ...state,
        interviewTimes: [],
        interviewTimesRecordCounts: 0,
      };
    }
    case FIND_INTERVIEW_TIMES_COMPLETED: {
      return {
        ...state,
        interviewTimes: action.payload.results,
        interviewTimesRecordCounts: action.payload.total,
      };
    }
    case FIND_INTERVIEW_TIMES_FAILED: {
      return {
        ...state,
        interviewTimes: [],
        interviewTimesRecordCounts: 0,
      };
    }
    case SAVE_CANDIDATE_POSTING: {
      return {
        ...state,
        savingCandidatePostingDraft: true,
        formCandidateError: "",
      };
    }
    case SAVE_CANDIDATE_POSTING_COMPLETED: {
      return {
        ...state,
        savingCandidatePostingDraft: false,
        draftCandidateId: action.payload.candidateId,
        formCandidateError: "",
      };
    }
    case SAVE_CANDIDATE_POSTING_FAILED: {
      let formCandidateError;
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        if (Array.isArray(action.payload.response.data.message)) {
          formCandidateError = JSON.stringify(
            action.payload.response.data.message.reduce((errors, error) => {
              errors[error.field] = error.message;
              return errors;
            }, {})
          );
        } else {
          formCandidateError = action.payload.response.data.message;
        }
      } else {
        formCandidateError = action.payload.message;
      }
      return {
        ...state,
        savingCandidatePostingDraft: false,
        formCandidateError,
      };
    }
    case FIND_CANDIDATES: {
      const { page, pageSize, orders, search } = action.payload;
      return {
        ...state,
        page,
        pageSize,
        orders,
        search,
        findingCandidates: true,
        findCandidatesError: "",
      };
    }
    case FIND_CANDIDATES_COMPLETED: {
      return {
        ...state,
        orders: action.payload.orders,
        findingCandidates: false,
        candidates: action.payload.results,
        totalCount: action.payload.total,
      };
    }
    case FIND_CANDIDATES_FAILED: {
      return {
        ...state,
        findingCandidates: false,
        findCandidatesError: action.payload.message,
      };
    }
    case FIND_CANDIDATE: {
      const { page, pageSize, orders, search } = action.payload;
      return {
        ...state,
        page,
        pageSize,
        orders,
        search,
        findingCandidate: true,
        findCandidateError: "",
      };
    }
    case FIND_CANDIDATE_COMPLETED: {
      return {
        ...state,
        findingCandidate: false,
        candidate: action.payload || {},
        draftCandidateId: action.payload && action.payload.candidateId,
      };
    }
    case FIND_CANDIDATE_FAILED: {
      return {
        ...state,
        findingCandidate: false,
        findCandidateError: action.payload.message,
      };
    }
    case CANDIDATE_VERIFY: {
      return {
        ...state,
        candidateVerified: false,
        verifyCandidateError: "",
        verifyingCandidate: true,
      };
    }
    case CANDIDATE_VERIFY_COMPLETED: {
      return {
        ...state,
        candidateVerified: true,
        verifyCandidateError: "",
        verifyingCandidate: false,
      };
    }
    case CANDIDATE_VERIFY_FAILED: {
      let verifyErrors;
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        if (Array.isArray(action.payload.response.data.message)) {
          verifyErrors = JSON.stringify(
            action.payload.response.data.message.reduce((errors, error) => {
              errors[error.field] = error.message;
              return errors;
            }, {})
          );
        } else {
          verifyErrors = action.payload.response.data.message;
        }
      } else {
        verifyErrors = action.payload.message;
      }
      return {
        ...state,
        candidateVerified: false,
        verifyCandidateError: verifyErrors,
        verifyingCandidate: false,
      };
    }
    case CANDIDATE_ACCEPT_TERMS: {
      return {
        ...state,
        candidateAcceptedTerms: false,
        acceptCandidateCandidateError: "",
        acceptingTermsForCandidate: true,
      };
    }
    case CANDIDATE_ACCEPT_TERMS_COMPLETED: {
      return {
        ...state,
        candidateAcceptedTerms: true,
        acceptCandidateCandidateError: "",
        acceptingTermsForCandidate: false,
      };
    }
    case CANDIDATE_ACCEPT_TERMS_FAILED: {
      let acceptCandidateErrors;
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        if (Array.isArray(action.payload.response.data.message)) {
          acceptCandidateErrors = JSON.stringify(
            action.payload.response.data.message.reduce((errors, error) => {
              errors[error.field] = error.message;
              return errors;
            }, {})
          );
          toast.error(
            JSON.stringify(
              action.payload.response.data.message.reduce((errors, error) => {
                errors[error.field] = error.message;
                return errors;
              }, {})
            )
          );
        } else {
          acceptCandidateErrors = action.payload.response.data.message;
          toast.error(action.payload.response.data.message);
        }
      } else {
        acceptCandidateErrors = action.payload.message;
        toast.error(action.payload.message);
      }
      return {
        ...state,
        candidateAcceptedTerms: false,
        acceptCandidateCandidateError: acceptCandidateErrors,
        acceptingTermsForCandidate: false,
      };
    }
    case CANDIDATE_INVITE: {
      return {
        ...state,
      };
    }
    case CANDIDATE_INVITE_COMPLETED: {
      return {
        ...state,
      };
    }
    case CANDIDATE_INVITE_FAILED: {
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        if (Array.isArray(action.payload.response.data.message)) {
          toast.error(
            JSON.stringify(
              action.payload.response.data.message.reduce((errors, error) => {
                errors[error.field] = error.message;
                return errors;
              }, {})
            )
          );
        } else {
          toast.error(action.payload.response.data.message);
        }
      } else {
        toast.error(action.payload.message);
      }
      return {
        ...state,
      };
    }
    case CANDIDATE_REJECT: {
      return {
        ...state,
        candidateRejected: false,
        rejectingForCandidate: true,
      };
    }
    case CANDIDATE_REJECT_COMPLETED: {
      return {
        ...state,
        candidateRejected: true,
        rejectingForCandidate: false,
      };
    }
    case CANDIDATE_REJECT_FAILED: {
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        if (Array.isArray(action.payload.response.data.message)) {
          toast.error(
            JSON.stringify(
              action.payload.response.data.message.reduce((errors, error) => {
                errors[error.field] = error.message;
                return errors;
              }, {})
            )
          );
        } else {
          toast.error(action.payload.response.data.message);
        }
      } else {
        toast.error(action.payload.message);
      }
      return {
        ...state,
        candidateRejected: false,
        rejectingForCandidate: false,
      };
    }
    default:
      return state;
  }
};

export default candidatesReducer;
