import axios from "axios";

const UPLOAD_FILE_URL = `${process.env.PUBLIC_URL}/api/v1/files/upload`;

export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_PROGRESS = 'UPLOAD_FILE_PROGRESS';
export const UPLOAD_FILE_COMPLETED = 'UPLOAD_FILE_COMPLETED';
export const UPLOAD_FILE_FAILED = 'UPLOAD_FILE_FAILED';

export function uploadFile({file}) {
  return async function(dispatch) {
    dispatch({type: UPLOAD_FILE, payload: {filename: file.name}});
    try {
      const formData = new FormData();
      formData.append('file', file, file.name);
      const res = await axios.post(UPLOAD_FILE_URL, formData, {
        onUploadProgress: (e) => dispatch({type: UPLOAD_FILE_PROGRESS, payload: e}),
      });
      dispatch({type: UPLOAD_FILE_COMPLETED, payload: res.data});
    } catch (err) {
      dispatch({type: UPLOAD_FILE_FAILED, payload: err});
      throw err;
    }
  }
}