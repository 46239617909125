import { command } from "../utils/api";

const USERS_ACTION_URL = `${process.env.PUBLIC_URL}/api/v1/account`;

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_COMPLETED = "CHANGE_PASSWORD_COMPLETED";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";

export const changePassword = command(
  USERS_ACTION_URL + "/changePassword",
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_COMPLETED,
  CHANGE_PASSWORD_FAILED
);

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_COMPLETED = "RESET_PASSWORD_COMPLETED";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

export const resetPassword = command(
  USERS_ACTION_URL + "/resetPassword",
  RESET_PASSWORD,
  RESET_PASSWORD_COMPLETED,
  RESET_PASSWORD_FAILED
);

export const CHANGE_PASSWORD_WITH_GUID = "CHANGE_PASSWORD_WITH_GUID";
export const CHANGE_PASSWORD_WITH_GUID_COMPLETED =
  "CHANGE_PASSWORD_WITH_GUID_COMPLETED";
export const CHANGE_PASSWORD_WITH_GUID_FAILED =
  "CHANGE_PASSWORD_WITH_GUID_FAILED";

export const changePasswordWithGuid = command(
  USERS_ACTION_URL + "/changePasswordWithGuid",
  CHANGE_PASSWORD_WITH_GUID,
  CHANGE_PASSWORD_WITH_GUID_COMPLETED,
  CHANGE_PASSWORD_WITH_GUID_FAILED
);
