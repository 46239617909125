import {useEffect, useState} from "react";
import {fetchEmployer} from "../api";
import {transformToForm} from "../utils";

const DEFAULT_FORM = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  companyName: "",
  jobTitle: "",
  street1: "",
  street2: "",
  city: "",
  state: "",
  country: "",
  postalCode: "",
  jobFunction: ""
}

const useEmployerForm = ({id}) => {
  const [form, setForm] = useState(DEFAULT_FORM);

  useEffect(() => {
    async function fetchEmployerProfile() {
      const {data = []} = await fetchEmployer(id);
      const employer = data.results[0];
      if (!employer) return;
      setForm(prevState => ({...prevState, ...transformToForm(employer)}))
    }

    fetchEmployerProfile();
  }, [id]);

  return {form, setForm}
}

export default useEmployerForm;
