function CandidateStatus({ candidate }) {
    const {
        candidateRejectedByEmployer, candidateInvitedByEmployer, verified, acceptedByAdmin, employerAgreedOnLicenseTerms,
    } = candidate;
    if (candidateInvitedByEmployer) return "Interview Requested";
    if (candidateRejectedByEmployer) return "Rejected by Employer";
    if (employerAgreedOnLicenseTerms) return "Under Employer Review";
    if (acceptedByAdmin === true) return "Approved by Admin";
    // Only visible to admin/recruiter from here
    if (acceptedByAdmin === false) return "Rejected by Admin";
    if (!verified) return "Pending Candidate Consent";
    return "Awaiting Admin Approval";
}

export default CandidateStatus;
