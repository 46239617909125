import { command, find, findOne } from "../utils/api";

const EMPLOYERS_ACTION_URL = `${process.env.PUBLIC_URL}/api/v1/employers`;

export const EMPLOYER_SIGN_UP = "EMPLOYER_SIGN_UP";
export const EMPLOYER_SIGN_UP_COMPLETED = "EMPLOYER_SIGN_UP_COMPLETED";
export const EMPLOYER_SIGN_UP_FAILED = "EMPLOYER_SIGN_UP_FAILED";

export const signUpEmployer = command(
  EMPLOYERS_ACTION_URL + "/signUpEmployer",
  EMPLOYER_SIGN_UP,
  EMPLOYER_SIGN_UP_COMPLETED,
  EMPLOYER_SIGN_UP_FAILED
);

export const EMPLOYER_VERIFY = "EMPLOYER_VERIFY";
export const EMPLOYER_VERIFY_COMPLETED = "EMPLOYER_VERIFY_COMPLETED";
export const EMPLOYER_VERIFY_FAILED = "EMPLOYER_VERIFY_FAILED";

export const verifyEmployer = command(
  EMPLOYERS_ACTION_URL + "/verifyEmployer",
  EMPLOYER_VERIFY,
  EMPLOYER_VERIFY_COMPLETED,
  EMPLOYER_VERIFY_FAILED
);

export const FIND_EMPLOYERS = "FIND_EMPLOYERS";
export const FIND_EMPLOYERS_COMPLETED = "FIND_EMPLOYERS_COMPLETED";
export const FIND_EMPLOYERS_FAILED = "FIND_EMPLOYERS_FAILED";

export const findEmployers = find(
  EMPLOYERS_ACTION_URL + "/find",
  FIND_EMPLOYERS,
  FIND_EMPLOYERS_COMPLETED,
  FIND_EMPLOYERS_FAILED
);

export const FIND_EMPLOYER = "FIND_EMPLOYER";
export const FIND_EMPLOYER_COMPLETED = "FIND_EMPLOYER_COMPLETED";
export const FIND_EMPLOYER_FAILED = "FIND_EMPLOYER_FAILED";

export const findEmployer = findOne(
  EMPLOYERS_ACTION_URL + "/find",
  FIND_EMPLOYER,
  FIND_EMPLOYER_COMPLETED,
  FIND_EMPLOYER_FAILED
);

export const FIND_LOGGED_EMPLOYER = "FIND_LOGGED_EMPLOYER";
export const FIND_LOGGED_EMPLOYER_COMPLETED = "FIND_LOGGED_EMPLOYER_COMPLETED";
export const FIND_LOGGED_EMPLOYER_FAILED = "FIND_LOGGED_EMPLOYER_FAILED";

export const findLoggedEmployer = findOne(
  EMPLOYERS_ACTION_URL + "/find",
  FIND_LOGGED_EMPLOYER,
  FIND_LOGGED_EMPLOYER_COMPLETED,
  FIND_LOGGED_EMPLOYER_FAILED
);

export const PURCHASE_A_JOB = "PURCHASE_A_JOB";
export const PURCHASE_A_JOB_COMPLETED = "PURCHASE_A_JOB_COMPLETED";
export const PURCHASE_A_JOB_FAILED = "PURCHASE_A_JOB_FAILED";

export const REMOVE_EMPLOYER = "REMOVE_EMPLOYER";
export const REMOVE_EMPLOYER_COMPLETED = "REMOVE_EMPLOYER_COMPLETED";
export const REMOVE_EMPLOYER_FAILED = "REMOVE_EMPLOYER_FAILED";

export const removeEmployer = command(
    EMPLOYERS_ACTION_URL + "/removeEmployer",
    REMOVE_EMPLOYER,
    REMOVE_EMPLOYER_COMPLETED,
    REMOVE_EMPLOYER_FAILED
);

export const useCode = command(
  EMPLOYERS_ACTION_URL + "/purchaseJob",
  PURCHASE_A_JOB,
  PURCHASE_A_JOB_COMPLETED,
  PURCHASE_A_JOB_FAILED
);
