import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import countries from "../../data/countries";
import states from "../../data/states";
import { toast } from "react-toastify";
import jobTypes from "../../data/jobTypes";
import {
  acceptJobCastTerms,
  activeJobCast,
  changeFavoriteJobStatus,
  changeRetailTermForJob,
  findAllJobs,
  findCandidatesForJobByStatus,
  getJobCasts,
  removeJobCast,
  pinJob,
  unpinJob,
} from "../../actions/jobs";
import { findRetailTerms } from "../../actions/retailTerms";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  FormControl,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import jobFunctions from "../../data/jobFunctions";
import {
  downloadResume,
  returnTextForArrayValue,
  startConversation,
  openResumeInTab
} from "../../utils/commons";
import industries from "../../data/industries";
import moment from "moment";
import terms from "../../data/terms";
import Auth from "../../utils/auth";
import Pagination from "react-js-pagination";
import { downloadGeneratedPdf, pdfTypes } from "../../utils/pdf"
import CandidateStatus from "../CandidateStatus";
import { findLoggedRecruiter } from "../../actions/recruiters";
import FileDropZone from '../common/FileDropZone';
import parsePdfData from '../../utils/pdfParser';
import { assessResume } from '../../actions/resumes.js';
import ResumeAssessmentResults from '../common/ResumeAssessmentResults';
import EarningsDisplay from '../common/EarningsDisplay';

const MAX_FILES = 3;

const AI_ASSESSMENT_STATUS = {
  IN_PROGRESS: 'assessment in progress',
  DONE: 'assessment completed',
  FAILED: 'assessment failed with error',
}

const SalaryDisplay = ({ from, to }) => {
  if (from || to) {
    return ` $${from / 1000 || ''}k - $${to / 1000 || ''}k`;
  }
  return "-";
}

/*
const JobFunctionFilter = ({active, onChange}) => (
  <DropdownButton id="recruiter-home-job-function-filter" title="Job Function Filter" drop="right">
    <Dropdown.Item as="button" active={active === 0} onClick={() => onChange(0)}>
      None
    </Dropdown.Item>
    {jobFunctions.map(({value, text}) => {
      const intValue = parseInt(value);
      return (
        <Dropdown.Item
          as="button" className="btn-sm" active={active === intValue} onClick={() => onChange(intValue)}
          key={value}
        >
          {text}
        </Dropdown.Item>
      );
    })}
  </DropdownButton>
);
*/

const popperConfig = { modifiers: [{ name: 'preventOverflow', options: { boundariesElement: 'window' } }] };

class RecruiterHomePage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    jobs: PropTypes.array.isRequired,
    changeRetailTermForJob: PropTypes.func.isRequired,
    findAllJobs: PropTypes.func.isRequired,
    getJobCasts: PropTypes.func.isRequired,
    removeJobCast: PropTypes.func.isRequired,
    activeJobCast: PropTypes.func.isRequired,
    acceptJobCastTerms: PropTypes.func.isRequired,
    userActiveJobCastsIds: PropTypes.array.isRequired,
    findCandidatesForJobByStatus: PropTypes.func.isRequired,
    findRetailTerms: PropTypes.func.isRequired,
    retailTerms: PropTypes.array.isRequired,
    itemsCount: PropTypes.number,
    changeFavoriteJobStatus: PropTypes.func.isRequired,
    findLoggedRecruiter: PropTypes.func.isRequired,
    loggedRecruiter: PropTypes.object.isRequired,
    pinJob: PropTypes.func.isRequired,
    unpinJob: PropTypes.func.isRequired,
  };
  timeout = null;
  state = {
    pageNr: 0,
    perPage: 20,
    search: "",
    provinceState: null,
    showTerms: false,
    showTermsReadOnly: false,
    termForJobId: null,
    openedRow: null,
    showJobDetails: false,
    jobDetails: null,
    selectedJobId: null,
    showEditRetailTerm: false,
    jobForRetailTerms: {},
    jobFunction: 0,
    onlyFavorites: false,
    showPdfAIAssessment: false,
    acceptedPdfFiles: [],
  };

  openRow = (e, id) => {
    e.stopPropagation();
    this.setState({
      openedRow: id,
    });
  };

  closeRow = (e) => {
    e.stopPropagation();
    this.setState({
      openedRow: null,
    });
  };

  _onSubmitCandidateClick = (jobId, event) => {
    if (event) event.stopPropagation();
    this.props.history.push("/candidate/" + jobId + "/new");
  };

  findAllJobs = async () => {
    const { search, jobFunction, onlyFavorites, provinceState } = this.state;
    const orderBy = 'postDate DESC';

    let payload = {
      offset: this.state.pageNr,
      limit: this.state.perPage,
      byJobFunction: jobFunction,
      onlyFavorites,
      orderBy,
      search: search || undefined,
      state: provinceState?.value,
    }
    await this.props.findAllJobs(payload);
  };

  pageChange = (pageNumber) => {
    const pageNr = pageNumber - 1;
    this.setState({ pageNr: pageNr }, () => {
      this.findAllJobs();
    });
  };

  componentDidMount() {
    this.findAllJobs();
    this.props.getJobCasts();
    const auth = Auth.getInstance();
    const userId = auth.getUserId();
    this.props.findLoggedRecruiter({ recruiterId: userId });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.jobFunction !== this.state.jobFunction) this.findAllJobs();
  }

  search = (e) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.setState(
      {
        search: e.target.value,
        pageNr: 0,
      },
      () =>
      (this.timeout = setTimeout(() => {
        this.findAllJobs();
      }, 900))
    );
  };

  clearAllFilters = (e) => {
    this.setState(
      {
        provinceState: null,
        search: "",
        pageNr: 0,
        onlyFavorites: false,
      },
      () => this.findAllJobs()
    );
  }

  handleProvinceStateDropDownChange = (e) => {
    const provinceState = e.target.value ? JSON.parse(e.target.value) : '';
    this.setState(
      {
        provinceState,
        pageNr: 0,
      },
      () => this.findAllJobs()
    );
  }

  showEditRetailTerm = (e, job, scope) => {
    e.stopPropagation();
    if (scope === "admin") {
      this.props.findRetailTerms();
    }
    this.setState({ showEditRetailTerm: true, jobForRetailTerms: job });
  };

  hideRetailTermsEdit = () => {
    this.setState({ showEditRetailTerm: false, jobForRetailTerms: {} });
  };

  changeRetailTerm = async (e) => {
    e.preventDefault();
    const form = e.target;
    if (!form.elements.retailTermId || !form.elements.retailTermId.value) {
      toast.error("You need to chose retail term");
      return;
    }
    const retailTermId = form.elements.retailTermId.value;
    await this.props.changeRetailTermForJob({
      jobId: this.state.jobForRetailTerms.jobId,
      retailTermId,
    });
    await this.findAllJobs();
    this.hideRetailTermsEdit();
  };

  handlePinJob = async (e, job) => {
    e.stopPropagation();
    const { jobId, isPinned } = job;
    await (isPinned ? this.props.unpinJob({ jobId }) : this.props.pinJob({ jobId }));
    await this.findAllJobs();
  }

  acceptJobCast = async (e, jobId) => {
    e.stopPropagation();
    this.setState({ showTerms: true, termForJobId: jobId });
  };

  showTerms = async (e) => {
    e.stopPropagation();
    this.setState({ showTermsReadOnly: true, termForJobId: null });
  };

  _agreeToTerms = async () => {
    try {
      await this.props.acceptJobCastTerms({
        terms: "",
      });
      await this.props.activeJobCast({
        jobId: this.state.termForJobId,
      });
      this.setState(
        { showTerms: false, termForJobId: null },
        () => { this.findAllJobs(); }
      );
    } catch (e) {
      console.error(e);
    }
  };

  _fetchCandidatesWithFilter = async (jobId, status) =>
    await this.props.findCandidatesForJobByStatus({ jobId, status });

  _onTermsHide = () =>
    this.setState({
      showTerms: false,
      showTermsReadOnly: false,
      termForJobId: null
    });

  _onJobDetailsHide = () =>
    this.setState({
      showJobDetails: false,
      jobDetails: null,
      selectedJobId: null,
    });

  _showJobDetails = (job) =>
    this.setState(
      {
        showJobDetails: true,
        jobDetails: jobToDetails(job),
        selectedJobId: job.jobId,
      }
    );

  _onCandidateDetailsHide = () =>
    this.setState({
      showCandidateDetails: false,
      selectedCandidate: null,
    });

  showCandidateDetails = (candidate, job) => {
    this.setState({
      showCandidateDetails: true,
      selectedCandidate: candidate,
    });
  };

  pluralize(count) {
    if (count <= 1) {
      return "";
    }
    return "s";
  }

  renderJobDetailsRow = (label, value) => {
    return (
      <div className="job-details-row" key={label}>
        <span className="span-label">{label}</span>: {value}
      </div>
    );
  };

  getJobDetails = (x, y) => {
    const { jobDetails } = this.state;
    return jobDetails ? jobDetails[x].body[y].content : "";
  };

  _changeFavoriteStatusWrapper = (job, e) => {
    e.stopPropagation();
    this._changeFavoriteStatus(job);
  };

  removeJobCastWrapper = (jobId, e) => {
    e.stopPropagation();
    this.props.removeJobCast(jobId);
  };

  showPdfAIAssessment = () => {
    this.setState({ showPdfAIAssessment: true });
  }

  hidePdfAIAssessment = () => {
    this.setState({ showPdfAIAssessment: false, acceptedPdfFiles: []});
  }

  renderCandidateDetails(job) {
    const recruiters = job.candidates.reduce((acc, candidate) => {
      const recruiterId = candidate.recruiter.recruiterId;
      if (!acc.find(r => r.recruiterId === recruiterId)) {
        acc.push(candidate.recruiter);
      }
      return acc;
    }, []);

    let adminView = null;
    const scope = Auth.getInstance().getScope();
    if (
      scope === 'employer'
      || (scope === 'recruiter' && !this.props.userActiveJobCastsIds.find((x) => x === job.jobId))
      || (scope === 'referralpartner' && !this.props.userActiveJobCastsIds.find((x) => x === job.jobId))
    ) {
      return (
        <div className="candidates-filter-cards">
          <div className="filterableCard">
            {job.totals.noOfCandidates} Candidate{this.pluralize(job.totals.noOfCandidates)}
          </div>
          <div className="filterableCard">
            {job.totals.noOfCandidatesSelectedForInterview} Selected for Interview
          </div>
          <div className="filterableCard">
            {job.totals.noOfRejectedCandidates} Rejected
          </div>
          <div className="filterableCard">
            {job.totals.noOfOffersMadeForCandidates} Offer{this.pluralize(job.totals.noOfOffersMadeForCandidates)} Made
          </div>
          <div className="filterableCard">
            {job.totals.noOfOffersAccepted} Offer{this.pluralize(job.totals.noOfOffersAccepted)} Accepted
          </div>
          <div className="filterableCard">
            {recruiters.length} Recruiter{this.pluralize(recruiters.length)}
          </div>
        </div>
      );
    } else if (scope === 'admin') {
      adminView = (
        <>
          <div
            className="candidates-filter-cards"
            style={{ fontWeight: '800', padding: '1em' }}
          >
            {recruiters.length} Recruiter{this.pluralize(recruiters.length)}
          </div>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              {recruiters.length === 0 ? (
                <tr>
                  <td colSpan={3} className="text-center">
                    <small>No recruiters</small>
                  </td>
                </tr>
              ) : null}
              {recruiters.map(recruiter => (
                <tr>
                  <td>{recruiter.name}</td>
                  <td>{recruiter.email}</td>
                  <td>{recruiter.phone}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )
    }

    return (
      <>
        <div
          className="candidates-filter-cards"
          style={{ marginLeft: "auto", marginRight: "auto", cursor: "pointer" }}
        >
          <div
            className={`filterableCard ${job.activeCandidateFilter === "all" ? "active" : ""
              }`}
            onClick={() => this._fetchCandidatesWithFilter(job.jobId, "all")}
          >
            <div className="text-center">
              {job.noOfCandidates} Candidate{this.pluralize(job.noOfCandidates)}
            </div>
            <div className="text-center">
              over {job.totals.noOfCandidates} total
            </div>
          </div>
          <div
            className={`filterableCard ${job.activeCandidateFilter === "selectedForInterview"
              ? "active"
              : ""
              }`}
            onClick={() =>
              this._fetchCandidatesWithFilter(job.jobId, "selectedForInterview")
            }
          >
            <div className="text-center">
              {job.noOfCandidatesSelectedForInterview} Selected for Interview
            </div>
            <div className="text-center">
              over {job.totals.noOfCandidatesSelectedForInterview} total
            </div>
          </div>
          <div
            className={`filterableCard ${job.activeCandidateFilter === "rejected" ? "active" : ""
              }`}
            onClick={() =>
              this._fetchCandidatesWithFilter(job.jobId, "rejected")
            }
          >
            <div className="text-center">
              {job.noOfRejectedCandidates} Rejected Candidate
              {this.pluralize(job.noOfRejectedCandidates)}
            </div>
            <div className="text-center">
              over {job.totals.noOfRejectedCandidates} total
            </div>
          </div>
          <div
            className={`filterableCard ${job.activeCandidateFilter === "offersMade" ? "active" : ""
              }`}
            onClick={() =>
              this._fetchCandidatesWithFilter(job.jobId, "offersMade")
            }
          >
            <div className="text-center">
              {job.noOfOffersMadeForCandidates} Offer
              {this.pluralize(job.noOfOffersMadeForCandidates)} Made
            </div>
            <div className="text-center">
              over {job.totals.noOfOffersMadeForCandidates} total
            </div>
          </div>
          <div
            className={`filterableCard ${job.activeCandidateFilter === "offersAccepted" ? "active" : ""
              }`}
            onClick={() =>
              this._fetchCandidatesWithFilter(job.jobId, "offersAccepted")
            }
          >
            <div className="text-center">
              {job.noOfOffersAccepted} Offer
              {this.pluralize(job.noOfOffersAccepted)} Accepted
            </div>
            <div className="text-center">
              over {job.totals.noOfOffersAccepted} total
            </div>
          </div>
        </div>
        <Table>
          <thead>
            <tr>
              <th>Candidate</th>
              <th>Status</th>
              <th>Salary Range</th>
              <th>Date Submitted</th>
              <th style={{ flex: 0.6 }} className="actions-transparent">Actions</th>
            </tr>
          </thead>
          <tbody>
            {!job.candidates || job.candidates.length === 0 ? (
              <tr key="noCandidatesKey">
                <td colSpan={6} className="text-center">
                  <small>
                    {this.props.findingCandidatesForJob
                      ? "Loading..."
                      : "No candidates with specified filter"}
                  </small>
                </td>
              </tr>
            ) : (
              job.candidates.map((candidate) => (
                <tr key={candidate.candidateId}>
                  <td>
                    {candidate.firstName} {candidate.lastName}
                  </td>
                  <td>
                    <CandidateStatus candidate={candidate} />
                  </td>
                  <td>
                    ${candidate.minSalary.toLocaleString()} - {candidate.maxSalary.toLocaleString()}
                  </td>
                  <td>
                    {moment(candidate.postDate).isValid()
                      ? (moment().diff(candidate.postDate, 'days') < 25)
                        ? moment(candidate.postDate).fromNow()
                        : "30+ days ago"
                      : ""}
                  </td>
                  <td style={{ flex: 0.6 }}>
                    <ButtonGroup className="btn-group-actions">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>View Details</Tooltip>}
                      >
                        <Button
                          size="sm"
                          variant="light"
                          onClick={(e) =>
                            this.showCandidateDetails(candidate, job)
                          }
                          className="material-icons"
                        >
                          search
                        </Button>
                      </OverlayTrigger>
                      {candidate.resume ? (
                        <>
                          <OverlayTrigger
                            placement="auto"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>View Resume</Tooltip>}
                            top
                          >
                            <Button
                              size="sm"
                              variant="primary"
                              onClick={(e) => openResumeInTab(e, candidate)}
                              className="material-icons"
                            >
                              launch
                            </Button>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="left"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>Download Resume</Tooltip>}
                            top
                          >
                            <Button
                              size="sm"
                              variant="primary"
                              onClick={(e) => downloadResume(e, candidate)}
                              className="material-icons"
                            >
                              attachment
                            </Button>
                          </OverlayTrigger>
                        </>
                      ) : null}{" "}
                    </ButtonGroup>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
        {adminView}
      </>
    );
  }

  _onJobFunctionFilterChange(jobFunction) {
    this.setState({ jobFunction });
  }

  render() {
    const { loggedRecruiter } = this.props;
    const auth = Auth.getInstance();
    const scope = auth.getScope();
    const blocked = auth.getIsBlocked();
    const userId = auth.getUserId();
    const {
      jobDetails,
      selectedCandidate,
      onlyFavorites,
    } = this.state;

    return (
      <>
        <Container fluid className="view-name-header">
          <Row>
            <Col sm="2" className="padding-none">
              <h4>Job Postings</h4>
            </Col>

            <Col sm="2" className="padding-none">
              <Form.Control as="select"
                name="provinceState"
                onChange={this.handleProvinceStateDropDownChange}
              >
                <option key='-1' value="">Select Province/State</option>
                {
                  states.map((option, index) => {
                    return (
                      <option
                        key={index}
                        value={JSON.stringify(option)}
                        selected={option.value === this.state.provinceState?.value}
                      >
                        {option.text}
                      </option>
                    )
                  })
                }
              </Form.Control>
            </Col>

            <Col sm="2">
              <Form.Control as="select" name="country" disabled>
                <option key='-1' value='none'></option>
                {
                  countries.map((country, index) => {
                    return (
                      <option
                        key={index} value={country.value}
                        selected={country.value === this.state.provinceState?.country}
                      >
                        {country.text}
                      </option>
                    )
                  })
                }
              </Form.Control>
            </Col>

            <Col sm="4">
              <FormControl
                type="text"
                placeholder="Search"
                value={this.state.search}
                onChange={this.search}
              />
            </Col>

            <Col sm="2" className="p-0 pr-2 text-left">
              <ButtonGroup>
                <Button
                  active={onlyFavorites}
                  onClick={this._onFavoriteFilterClick.bind(this)}
                >
                  <i className="material-icons">favorite</i>
                  Favorites
                </Button>

                <Button
                  // active={onlyFavorites}
                  onClick={this.clearAllFilters}
                  className="btn-light"
                >
                  Clear
                </Button>

                {/*<JobFunctionFilter*/}
                {/*  active={this.state.jobFunction}*/}
                {/*  onChange={this._onJobFunctionFilterChange.bind(this)}*/}
                {/*/>*/}
              </ButtonGroup>
            </Col>

          </Row>
        </Container>
        <div className="table-wrapper">
          {blocked ? (
            <Alert variant="danger">
              You are blocked to view job list, contact administrator
              <a href="mailto:info@recruitnrefer.ca">info@recruitnrefer.ca</a>
              to get more information
            </Alert>
          ) : (
            <Table style={{ padding: "10px 40px" }}>
              Found {this.props.itemsCount} job postings
              <tbody>
                {this.props.jobs.map((job) => (
                  <React.Fragment key={job.jobId}>
                    <Button variant="job-listing" onClick={() => this._showJobDetails(job)}>
                      <div className="job-listing-details">
                        <div className="job-listing-details-left">
                          <p className="company-name"> {job.companyName} </p>
                          <p className="job-name"> {job.jobTitle} </p>
                          <p>{returnTextForArrayValue(jobFunctions, job.jobFunction)} | {job.city}, {job.state} | <SalaryDisplay from={job.salaryFrom} to={job.salaryTo} /></p>
                        </div>
                        <div className="job-listing-details-right">
                          <div className="earnings-postdate">
                            {scope !== "employer" 
                              ? <p className="recruiter-earnings">
                                  <EarningsDisplay referValue={job.referValue} feeValue={job.feeValue}
                                    salaryFrom={job.salaryFrom} isFeePercent={job.isFeePercent}
                                    isReferrer={loggedRecruiter.referrer} /> Earnings
                                </p>
                              : null}
                            <p className="post-date">
                              {moment(job.postDate).isValid()
                                ? (moment().diff(job.postDate, 'days') < 25)
                                  ? moment(job.postDate).fromNow()
                                  : "30+ days ago"
                                : ""}
                            </p>
                          </div>
                          <div>
                            {scope === "recruiter" || scope === "admin" || scope === "referralpartner" ? (
                              <ButtonGroup className="btn-group-actions">
                                {this.props.userActiveJobCastsIds.find(
                                  (x) => x === job.jobId
                                ) ? (
                                  <>
                                  <OverlayTrigger
                                      placement="bottom"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={<Tooltip>Check T&C</Tooltip>}
                                      popperConfig={popperConfig}
                                    >
                                      <Button
                                        size="sm"
                                        variant="light"
                                        onClick={(e) => this.showTerms(e, { jobId: job.jobId })}
                                        className="material-icons"
                                      >
                                        gavel
                                      </Button>
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                      placement="bottom"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={<Tooltip>Remove Job Cast</Tooltip>}
                                      popperConfig={popperConfig}
                                    >
                                      <Button
                                        size="sm"
                                        variant="danger"
                                        onClick={(e) => this.removeJobCastWrapper({ jobId: job.jobId }, e)}
                                        className="material-icons"
                                      >
                                        delete
                                      </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      placement="bottom"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip>Submit a Candidate</Tooltip>
                                      }
                                      popperConfig={popperConfig}
                                    >
                                      <Button
                                        size="sm"
                                        variant="success"
                                        onClick={(e) =>
                                          this._onSubmitCandidateClick(job.jobId, e)
                                        }
                                        className="material-icons"
                                      >
                                        person_add
                                      </Button>
                                    </OverlayTrigger>
                                  </>
                                ) : (job.allowedRecruiterIds === null || job.allowedRecruiterIds.includes(userId)) ? (
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip>Accept Job Cast</Tooltip>}
                                    popperConfig={popperConfig}
                                  >
                                    <Button
                                      size="sm"
                                      variant="success"
                                      onClick={(e) =>
                                        this.acceptJobCast(e, job.jobId)
                                      }
                                      className="material-icons"
                                    >
                                      done
                                    </Button>
                                  </OverlayTrigger>
                                ) : null}
                                <OverlayTrigger
                                  placement="bottom"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={<Tooltip>View Candidates</Tooltip>}
                                  popperConfig={popperConfig}
                                >
                                  <Button
                                    size="sm"
                                    variant="light"
                                    onClick={(e) =>
                                      this.state.openedRow !== job.jobId
                                        ? this.openRow(e, job.jobId)
                                        : this.closeRow(e)}
                                    className="material-icons"
                                  >
                                    format_list_bulleted
                                  </Button>
                                </OverlayTrigger>
                                {scope === "recruiter" ? (
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                      <Tooltip>Ask a Question to Employer</Tooltip>
                                    }
                                    popperConfig={popperConfig}
                                  >
                                    <Button
                                      size="sm"
                                      variant="warning"
                                      onClick={(e) =>
                                        startConversation(
                                          job.jobId,
                                          this.props.history,
                                          e
                                        )
                                      }
                                      className="material-icons"
                                    >
                                      question_answer
                                    </Button>
                                  </OverlayTrigger>
                                ) : null}
                                {scope !== "recruiter" && scope !== "referralpartner" ? (
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={loggedRecruiter.referrer === true ? <Tooltip>Referral Partner's Earnings</Tooltip> : <Tooltip>Recruiter's Earnings</Tooltip>}
                                    popperConfig={popperConfig}
                                  >
                                    <Button
                                      size="sm"
                                      variant="info"
                                      onClick={(e) =>
                                        scope !== "employer"
                                          ? this.showEditRetailTerm(e, job, scope)
                                          : null
                                      }
                                      className="material-icons"
                                    >
                                      attach_money
                                    </Button>
                                  </OverlayTrigger>
                                ) : null}
                                {scope === "admin" ? (
                                  <Button
                                    size="sm"
                                    variant={job.isPinned ? "danger" : "light"}
                                    onClick={(e) => this.handlePinJob(e, job)}
                                    className="material-icons"
                                  >
                                    navigation
                                  </Button>
                                ) : null}
                                <OverlayTrigger
                                  placement="bottom"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={<Tooltip>Favorite</Tooltip>}
                                  popperConfig={popperConfig}
                                >
                                  <Button
                                    size="lg"
                                    variant="custom"
                                    onClick={(e) => this._changeFavoriteStatusWrapper(job, e)}
                                    className="material-icons"
                                  >
                                    {job.favorite ? 'favorite' : 'favorite_border'}
                                  </Button>
                                </OverlayTrigger>
                              </ButtonGroup>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </Button>
                    {this.state.openedRow === job.jobId ? (
                      <tr>
                        <td className="candidate-td">
                          {this.renderCandidateDetails(job)}
                        </td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          )}
        </div>
        <Pagination
          activePage={this.state.pageNr + 1}
          itemsCountPerPage={this.state.perPage}
          totalItemsCount={this.props.itemsCount}
          pageRangeDisplayed={5}
          onChange={this.pageChange}
        />
        <Modal
          centered
          show={this.state.showEditRetailTerm}
          onHide={this.hideRetailTermsEdit}
        >
          <Modal.Header closeButton>
            {scope === "admin" ? (
              <Modal.Title>Change retail term for job</Modal.Title>
            ) : (loggedRecruiter.referrer === true ?
              <Modal.Title>Referral Partner's Earnings</Modal.Title>
              : <Modal.Title>Recruiter's Earnings</Modal.Title>
            )}
          </Modal.Header>
          <Form onSubmit={this.changeRetailTerm}>
            {scope === "admin" ? (
              <Modal.Body>
                <Form.Control as="select" name="retailTermId">
                  <option />
                  {this.props.retailTerms.map(({ name, id }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Form.Control>
              </Modal.Body>
            ) : (
              <Modal.Body>
                {
                  this.state.jobForRetailTerms.isFeePercent
                    ? `Your recruitment fee for the successful placement of this role is ${loggedRecruiter.referrer === true ?
                      this.state.jobForRetailTerms.salaryFrom
                        ? `$${(parseInt(this.state.jobForRetailTerms.referValue) / 100) *
                        parseInt(this.state.jobForRetailTerms.salaryFrom)
                        }`
                        : ""
                      :
                      this.state.jobForRetailTerms.salaryFrom
                        ? `$${(parseInt(this.state.jobForRetailTerms.feeValue) / 100) *
                        parseInt(this.state.jobForRetailTerms.salaryFrom)
                        }`
                        : ""
                    }`
                    : `Your recruitment fee for the successful placement of this role is ${loggedRecruiter.referrer === true ?
                      this.state.jobForRetailTerms.referValue
                        ? `$${this.state.jobForRetailTerms.referValue}`
                        : ""
                      :
                      this.state.jobForRetailTerms.feeValue
                        ? `$${this.state.jobForRetailTerms.feeValue}`
                        : ""
                    }`
                }

              </Modal.Body>
            )}

            <Modal.Footer>
              <Button
                className="marign-right-10"
                variant="danger"
                onClick={this.hideRetailTermsEdit}
              >
                Close
              </Button>
              {scope === "admin" ? (
                <Button variant="primary" type="submit">
                  Save
                </Button>
              ) : null}
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal centered
          show={this.state.showTerms || this.state.showTermsReadOnly}
          onHide={this._onTermsHide}
          >
          <Modal.Header closeButton>
            <Modal.Title>Terms and Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body className="terms-body">
            <div
              dangerouslySetInnerHTML={{ __html: terms.recruiterAcceptJobCast }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="marign-right-10"
              variant="danger"
              onClick={this._onTermsHide}
            >
              Close
            </Button>
            {!this.state.showTermsReadOnly ? (
              <Button variant="primary" onClick={this._agreeToTerms} show="false">
              I Agree
            </Button>
              ) : null}
          </Modal.Footer>
        </Modal>

        <Modal
          size="lg"
          centered
          show={this.state.showJobDetails}
          onHide={this._onJobDetailsHide}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {`${jobDetails ? jobDetails[0].header : ""} | ${this.getJobDetails(6, 0)}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col>
                  <div className="job-desc-intro-box">
                    <div className="intro-box-earnings-fav">
                      {scope !== "employer" 
                        ? <h4 className="intro-box-recruiter-earnings">
                            <b>
                              {loggedRecruiter.referrer === true ? 'Referrer ' : 'Recruiter '}
                              Earnings:{" "}
                              <EarningsDisplay referValue={this.getJobDetails(8, 0).referValue} feeValue={this.getJobDetails(8, 0).feeValue}
                                salaryFrom={this.getJobDetails(5, 0)} isFeePercent={this.getJobDetails(8, 0).isFeePercent}
                                isReferrer={loggedRecruiter.referrer} />
                            </b>
                          </h4>
                        : null}
                      <div className="intro-box-buttons">
                        {['admin', 'recruiter', 'referralpartner'].includes(scope) ? (
                          <Button
                            size="lg"
                            variant="info"
                            onClick={() => this.showPdfAIAssessment()}
                          >
                            <i className="material-icons">star</i>
                            Assess PDF Resume
                          </Button>
                        ) : null }
                        <Button
                          size="lg"
                          variant="primary"
                          onClick={() => this._changeFavoriteStatus(this.getJobDetails(8, 0))}
                        >
                          <i className="material-icons">favorite</i>
                          {this.getJobDetails(8, 0).favorite ? 'Unfavorite' : 'Favorite'}
                        </Button>
                      </div>
                    </div>
                    <hr />
                    <div className="intro-box-job-details">
                      <div className="intro-box-job-details-left">
                        <p className="company-name">{this.getJobDetails(6, 0)}</p>
                        <p className="job-name">{jobDetails ? jobDetails[0].header : ""}</p>
                        <p>
                          {`${this.getJobDetails(3, 0)} |
                              ${this.getJobDetails(4, 0)},
                              ${this.getJobDetails(4, 1)} |`}
                          <SalaryDisplay from={this.getJobDetails(5, 0)} to={this.getJobDetails(5, 1)} />
                        </p>
                        <p className="post-date">{(moment().diff(this.getJobDetails(8, 0).postDate, 'days') < 25)
                          ? moment(this.getJobDetails(8, 0).postDate).fromNow()
                          : "30+ days ago"}</p>
                      </div>
                      <div className="intro-box-job-details-right">
                        <p className="num-of-pos-text">Number Of Positions: </p>
                        <p className="num-of-pos">x{this.getJobDetails(8, 0).numberOfPositions}</p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            <div className="job-desc-bottom-box">
              {(jobDetails || []).map((x, i) => {
                if (i === 0) return null;
                const arr = [];
                arr.push(<p key={`header-${i}`} style={{ fontWeight: 700, fontSize: '1.5em' }}>{x.header}</p>);
                if (Array.isArray(x.body)) {
                  let j = 0;
                  for (const { header, content } of x.body) {
                    if (header === "Job") {
                      return arr;
                    } else if (header) {
                      arr.push(<p key={`sub-header-${i}-${j}`} style={{ fontWeight: 700 }}>{header}</p>);
                    }
                    arr.push(<p style={{ whiteSpace: 'pre-line' }} key={`sub-content-${i}-${j}`}>{content || ' '}</p>);
                    j++;
                  }
                } else if (typeof x.body === 'string') {
                  arr.push(
                    <div
                      key={x.id}
                      id={x.id}
                      dangerouslySetInnerHTML={{
                        __html: x.body,
                      }}
                    />
                  );
                }
                if (x.highlight) return <div style={{ backgroundColor: x.highlight }}>{arr}</div>;
                arr.push(<hr />)
                return arr;
              })}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="marign-right-10"
              variant="primary"
              onClick={() => this._onDownloadPDF(pdfTypes.recruiterPdf)}
            >
              Recruiter PDF
            </Button>

            <Button
              className="marign-right-10"
              variant="light"
              onClick={() => this._onDownloadPDF(pdfTypes.candidatePdf)}
            >
              Share with Candidate
            </Button>

            <Button
              className="marign-right-10"
              variant="danger"
              onClick={this._onJobDetailsHide}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showPdfAIAssessment} onHide={this.hidePdfAIAssessment}>
          <Modal.Header closeButton> <Modal.Title>Assess Your Candidates</Modal.Title> </Modal.Header>
          <Modal.Body>
            <FileDropZone maxFiles={MAX_FILES} onAcceptedFile={this.handleAcceptedFile} />
            {this.state.acceptedPdfFiles?.length ? (
              <>
                <h6>Dropped files</h6>
                <ul>
                  {this.state.acceptedPdfFiles.map(file => (
                    <li key={file.path} className="assessment-list-item">
                      {this.acceptedFileTitle(file)}
                      {/* {this.assessmentForFile(file)} */}
                      {Array.isArray(file.assessment?.resumeAssessment)
                        ? (<ResumeAssessmentResults assessment={file.assessment.resumeAssessment} />)
                        : null}
                    </li>))
                  }
                </ul>
              </>
            )
            :null}
          </Modal.Body>
        </Modal>

        <Modal
          centered
          show={this.state.showCandidateDetails}
          onHide={this._onCandidateDetailsHide}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.showCandidateDetails
                ? `Candidate | ${selectedCandidate.firstName} ${selectedCandidate.lastName}`
                : null}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedCandidate ? (
              <>
                <h5 className="h5-job-details">Candidate Details</h5>
                {this.renderJobDetailsRow(
                  "First Name",
                  selectedCandidate.firstName
                )}
                {this.renderJobDetailsRow(
                  "Last Name",
                  selectedCandidate.lastName
                )}
                {this.renderJobDetailsRow(
                  "Email Address",
                  selectedCandidate.email
                )}
                {this.renderJobDetailsRow(
                  "Phone",
                  selectedCandidate.phone
                )}
                {this.renderJobDetailsRow(
                  "City",
                  selectedCandidate.city
                )}
                {this.renderJobDetailsRow(
                  "Province/State",
                  returnTextForArrayValue(states, selectedCandidate.province)
                )}
                {this.renderJobDetailsRow(
                  "Country",
                  returnTextForArrayValue(countries, selectedCandidate.country)
                )}
                {this.renderJobDetailsRow(
                  "LinkedIn Profile",
                  selectedCandidate.linkedInProfile
                )}
                <hr />
                <h5 className="h5-job-details">Candidate Profile</h5>
                {this.renderJobDetailsRow(
                  "CPA Designated",
                  selectedCandidate.highestCollegeDegree
                )}
                {this.renderJobDetailsRow(
                  "Most Recent Job Title",
                  selectedCandidate.mostRecentJobTitle
                )}
                {this.renderJobDetailsRow(
                  "Current Employer",
                  selectedCandidate.currentEmployer
                )}
                {this.renderJobDetailsRow(
                  "Minimum Salary",
                  selectedCandidate.minSalary
                )}
                {this.renderJobDetailsRow(
                  "Maximum Salary",
                  selectedCandidate.maxSalary
                )}
                {this.renderJobDetailsRow(
                  "Note From Recruiter",
                  selectedCandidate.recruiterNote
                )}
                {this.renderJobDetailsRow(
                  "Note From Candidate",
                  selectedCandidate.candidateNote
                )}
              </>
            ) : (
              "No details"
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="marign-right-10"
              variant="danger"
              onClick={this._onCandidateDetailsHide}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

      </>
    );
  }

  _changeFavoriteStatus = async ({ jobId, favorite }) => {
    try {
      await this.props.changeFavoriteJobStatus({ jobId, favorite: !favorite });
    } catch (e) {
      console.error(e);
    }
  };

  _onDownloadPDF = async (pdfType) => {
    const { jobDetails } = this.state;
    try {
      await downloadGeneratedPdf(jobDetails, pdfType);
    } catch (e) {
      console.error(e);
    }
  };

  _onFavoriteFilterClick() {
    this.setState(
      { onlyFavorites: !this.state.onlyFavorites },
      () => this.findAllJobs()
    );
  }

  acceptedFileTitle = (file) => {
    return (
      <>
        <span className="assessment-file-name">{file.path}</span>
        - {file.size} bytes
        - {(!!file.parsingError)
                ? <span className="assessment-parsing-error">error parsing file</span>
                : 'file parsed OK - '}
        {file.status} {file.status === AI_ASSESSMENT_STATUS.IN_PROGRESS ? <div className="assessment-loader"></div> : '' }
      </>
    );
  }

  handleAcceptedFile = async (newFile) => {
    if (MAX_FILES <= this.state.acceptedPdfFiles.length) {
      console.error(`You can assess maximum ${MAX_FILES} files. Limit Exceeded`);
      toast.error(`You can assess maximum ${MAX_FILES} files. Limit Exceeded`);
    }
    else if (this.state.acceptedPdfFiles.find((file) => file.path === newFile.path)) {
      console.error(`File '${newFile.path}' already added for assessment`);
      toast.error(`File '${newFile.path}' already added for assessment`);
    }
    else {
      newFile.text = await parsePdfData(newFile.data);
      if (!newFile.text) {
        newFile.parsingError = true;
      }
      this.setState(
        {acceptedPdfFiles: this.state.acceptedPdfFiles ? [...this.state.acceptedPdfFiles, newFile] : [newFile]},
        async () => {
          if (!newFile.parsingError) {
            await this.assessResumeFile(newFile);
          }
      });
    }
  }

  assessResumeFile = async (file) => {
    await this.setAcceptedFileStatus(file, AI_ASSESSMENT_STATUS.IN_PROGRESS);

    let res;
    try {
      res = await assessResume(this.state.selectedJobId, file.text, file.path);
      // console.log('assessResume.res: ', res);
    }
    catch (err) {
      toast.error(`Error assessing file '${file.path}': ${err.message}'`);
      this.setAcceptedFileStatus(file, AI_ASSESSMENT_STATUS.FAILED);
      return;
    }

    if (!res) {
      console.error('[assessResumeFile] empty response from assessResume');
      toast.error(`Error assessing file '${file.path}': empty server response'`);
      this.setAcceptedFileStatus(file, AI_ASSESSMENT_STATUS.FAILED);
      return;
    }

    this.setAcceptedFileStatus(file, AI_ASSESSMENT_STATUS.DONE);

    const idx = this.acceptedFileIndex(file);
    const acceptedPdfFiles = [...this.state.acceptedPdfFiles];
    acceptedPdfFiles[idx].assessment = res;
    this.setState({ acceptedPdfFiles }, () => {
      // console.log('[assessResumeFile]: in callback updated acceptedPdfFiles: ', this.state.acceptedPdfFiles);
    });
  }

  acceptedFileIndex = (file) => {
    const idx = this.state.acceptedPdfFiles.findIndex((f) => f.path === file.path);
    return idx === -1 ? undefined : idx;
  }

  setAcceptedFileStatus = async (file, status) => {
    const idx = this.acceptedFileIndex(file);
    // if (!idx) {
    //   console.log(`[handleAcceptedFile]: file.path (${file.path}) not found in this.state.acceptedPdfFiles`);
    // }
    const acceptedPdfFiles = [...this.state.acceptedPdfFiles];
    acceptedPdfFiles[idx].status = status;
    // console.log('[setAcceptedFileLoading] locally updated acceptedPdfFiles: ', acceptedPdfFiles);

    this.setState({ acceptedPdfFiles }, () => {
      // console.log('[setAcceptedFileStatus]: in callback updated acceptedPdfFiles: ', this.state.acceptedPdfFiles);
    });
  }

}

const jobToDetails = (job) => [
  {
    header: job.jobTitle,
  },
  {
    header: 'Job Highlights:',
    body: [
      {
        header: "Skills - Must Have:",
        content: job.mustHave,
      },
      {
        header: "Ideal Companies to Recruit From:",
        content: job.skillsConsideredBonus,
      },
      {
        header: "Hiring Status:",
        content: job.commissionPayment,
      },
    ]
  },
  {
    header: 'About this Job:',
    body: job.jobDescription,
    id: 'recruiter-home-page-job-description'
  },
  {
    header: '',
    body: [
      {
        header: "Job Function:",
        content: returnTextForArrayValue(jobFunctions, job.jobFunction),
      },
      {
        header: "Reporting To:",
        content: job.reportingTo,
      },
      {
        header: "Job Type:",
        content: returnTextForArrayValue(jobTypes, job.jobType),
      },
      {
        header: "Team Size To Manage:",
        content: job.teamSizeToManage,
      },
    ]
  },
  {
    header: 'Job Location:',
    body: [
      {
        header: "City:",
        content: job.city,
      },
      {
        header: "Province/State:",
        content: returnTextForArrayValue(states, job.state),
      },
      {
        header: "Country:",
        content: returnTextForArrayValue(countries, job.country),
      },
      {
        header: "Location Type:",
        content: job.locationType,
      },
      {
        header: "Other Location:",
        content: job.otherLocation,
      },
      {
        header: "Candidate Relocation:",
        content: job.candidateRelocation,
      },
    ]
  },
  {
    header: 'Compensation:',
    body: [
      {
        header: "Salary From:",
        content: job.salaryFrom,
      },
      {
        header: "Salary To:",
        content: job.salaryTo,
      },
      {
        header: "Bonus Payment:",
        content: job.bonusPayment,
      },
    ]
  },
  {
    header: 'About this Company:',
    body: [
      {
        header: "Company Name:",
        content: job.companyName,
      },
      {
        header: "Industry:",
        content: returnTextForArrayValue(industries, job.industry),
      },
      {
        header: "Company Description:",
        content: job.companyDescription,
      },
    ]
  },
  {
    header: 'Culture, Perks and Benefits:',
    body: job.culturePerksAndBenefits,
    id: 'recruiter-home-page-CPB-description'
  },
  {
    header: '',
    body: [
      {
        header: "Job",
        content: job,
      },
    ]
  },
];

export default connect(
  (state) => ({
    jobs: state.jobs.allJobs,
    userActiveJobCastsIds: state.jobs.userActiveJobCastsIds,
    findingCandidatesForJob: state.jobs.findingCandidatesForJob,
    retailTerms: state.retailTerms.retailTerms,
    itemsCount: state.jobs.itemsCount,
    loggedRecruiter: state.recruiters.loggedRecruiter,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        findAllJobs: findAllJobs,
        acceptJobCastTerms: acceptJobCastTerms,
        removeJobCast: removeJobCast,
        activeJobCast: activeJobCast,
        getJobCasts: getJobCasts,
        findCandidatesForJobByStatus: findCandidatesForJobByStatus,
        findRetailTerms: findRetailTerms,
        changeRetailTermForJob: changeRetailTermForJob,
        findLoggedRecruiter: findLoggedRecruiter,
        changeFavoriteJobStatus,
        pinJob,
        unpinJob,
      },
      dispatch
    )
)(RecruiterHomePage);
