import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({
  component: Component,
  auth,
  exact,
  path,
  scopes = [],
  ...rest
}) => {
  const scopesArr = scopes.length ? [...scopes, "admin"] : ["admin"];
  return (
    <Route
      exact={exact}
      path={path}
      render={props =>
        auth.isAuthenticated() &&
        typeof scopesArr.find(x => x === auth.getScope()) !== "undefined" ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};
PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object
};
export default PrivateRoute;
