import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Alert, Button,  Form, InputGroup, Modal,
} from 'react-bootstrap';
import { validateEmail, validatePassword } from "../utils/validators";
import { connect } from "react-redux";
import { login } from "./actions";
import { bindActionCreators } from "redux";
import Auth from "../utils/auth";
import "./index.scss";
import logo from "../assets/img/logo.png";
import { toast } from "react-toastify";
import {Link} from "react-router-dom"
import SignInWithLinkedIn from './SignInWithLinkedIn';

const validators = {
  email: validateEmail,
  password: validatePassword,
};

function redirectIfAuthenticated(token, history) {
  if (!token) return;
  const auth = Auth.getInstance();
  const scope = auth.getScope();
  if (auth.isAuthenticated() && scope) {
    switch (scope) {
      case "admin":
        history.push(`recruiter/home`);
        break;
      case "employer":
        history.push(`employer/jobs?limit=20&offset=0`);
        break;
      default:
        history.push(`${scope}/home`);
    }
  }
}

class LoginPage extends Component {
  state = {
    email: "",
    password: "",
    validationErrors: {},
    submitted: false,
    showLogIn: false,
  };

  constructor(props) {
    super(props);

    const search = props.location.search.slice(1);
    if (search.startsWith("error=")) {
      const msg = decodeURIComponent(search.slice(6));
      toast.error(msg);
    }
    else if (search.startsWith("verifyFail=")) {
      const msg = decodeURIComponent(search.slice(11));
      toast.error(msg);
    }
    else if (search.startsWith("verifySuccess=")) {
      const msg = search.slice(14);
      toast.success(msg);
      this.state.textTitle = 'ACCOUNT VERIFIED';
      this.state.textContent = 'Your account has been verified. You can now login.';
    }
  }

  componentDidMount() {
    const { token } = this.props;
    redirectIfAuthenticated(token, this.props.history);
  }

  componentDidUpdate(prevProps) {
    const { token, history } = this.props;
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    if (token && token !== prevProps.token) history.push(from);
    redirectIfAuthenticated(token, this.props.history);
  }

  _validateField = (validator) => (e) =>
    this.setState({
      validationErrors: {
        ...this.state.validationErrors,
        [e.target.name]: validator(e.target.value),
      },
    });

  _validateForm = (form) => {
    const validationErrors = {};
    let errors = 0;
    for (let i = 0; i < form.length; i++) {
      const field = form[i];
      const validator = validators[field.name];
      const error = validator && validator(field.value);
      if (error) errors++;
      validationErrors[field.name] = error;
    }
    if (errors) this.setState({ validationErrors });
    return errors === 0;
  };

  _renderFormError() {
    if (!this.props.error || !this.state.submitted) return null;
    return (
      <Alert variant="danger">
        {this.props.error}
      </Alert>
    );
  }

  _renderInputGroup(ariaLabel, inputType, placeHolder, fieldName) {
    const validationError = this.state.validationErrors[fieldName];
    const isInvalid = !!validationError;
    const validator = validators[fieldName];
    const onBlur = validator ? this._validateField(validator) : undefined;
    return (
      <Form.Row>
        <Form.Group controlId={fieldName}>
          <InputGroup>
              <Form.Label>{placeHolder}</Form.Label>
              <Form.Control
                type={inputType}
                placeholder={placeHolder}
                name={fieldName}
                value={this.state[fieldName]}
                onChange={this._onChange}
                onBlur={onBlur}
                isInvalid={isInvalid}
              />
              <Form.Control.Feedback type="invalid">
                {validationError}
              </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Form.Row>
    );
  }

  _onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  _onSubmit = async (e) => {
    e.preventDefault();
    if (this._validateForm(e.target)) {
      const { email: username, password } = this.state;
      this.props.login({ username, password });
    }
    this.setState({ submitted: true });
  };

  showLogIn = () => {
    this.setState({ showLogIn: true })
  };

  hideLogIn = () => {
    this.setState({ showLogIn: false });
  };

  render() {
    return (
      <div className="outer-container">
        <div className="header">
          <img alt="logo" src={logo} style={{width: "120px"}}/>
          <button className="login-button" onClick={() => this.showLogIn()}>
            Log In
          </button>
          <Modal show={this.state.showLogIn} onHide={this.hideLogIn}>
            <Modal.Header className="border-0" closeButton/>
              <Modal.Body>
              {this._renderFormError()}
                <div className="login-container">
                  <div className="login-text">Log In</div>
                    <Form className="form-container" onSubmit={this._onSubmit}>
                      {this._renderInputGroup("e-mail", "text", "Email", "email")}
                      {this._renderInputGroup("lock", "password", "Password", "password")}
                      <div className="btns-wrapper-login">
                        <Link to="/password-reset" style={{color: "#222222", fontSize: "14px"}} >
                          Forgot Password?
                        </Link>
                        {/* <OverlayTrigger
                          trigger="focus"
                          placement="left"
                          overlay={
                            <Popover id="popover-trouble" style={{minWidth: '400px'}}>
                              <Popover.Title as="h3">Troubleshooting</Popover.Title>
                              <Popover.Content>
                                <ul>
                                  <li className="mb-2">
                                    Have you verified your account?<br />
                                    <i>A verification link has been sent to your email, please also check your spam folder.</i>
                                  </li>
                                  <li className="mb-2">
                                    Have you been approved by the administrator?<br />
                                    <i>Recruiters will have to be approved first. An email will be sent when you are approved!</i>
                                  </li>
                                  <li className="mb-2">
                                    Why can't I sign in with LinkedIn?<br />
                                    <i>Your LinkedIn email must match your RNR email, otherwise a new account will be
                                       created. Your account must also be verified and approved first.</i>
                                  </li>
                                </ul>
                                <a
                                  href="https://inforecruitnrefer.ca/im-a-recruiter-1"
                                  className="float-right"
                                >
                                  Recruiter FAQ
                                </a>
                                <a
                                  href="https://inforecruitnrefer.ca/im-an-employer-1"
                                >
                                  Employer FAQ
                                </a>
                              </Popover.Content>
                            </Popover>
                          }
                        >
                          <Button variant="link" style={{color: "#222222", fontSize: "14px"}}>
                            Having trouble?
                          </Button>
                        </OverlayTrigger> */}
                        <Button variant="primary" type="submit" size={"lg"}>
                          Log In
                        </Button>
                      </div>
                      <hr style={{margin: "2em 0"}}/>
                    </Form>
                    <div className="linkedin-button"> 
                      <SignInWithLinkedIn style={{ cursor: 'pointer' }} />
                    </div>
                  </div>
              </Modal.Body>
          </Modal>
        </div>
        <div className="title-container">
          <h1 className="h1-title">
            Recruiting Powered by <span className="text-orange"> Technology, </span>
            Delivered by <span className="text-orange"> Humans.</span>
          </h1>
          <div className="sub-title">
            Where Every Placement is a Payday!
          </div>
          <div className="button-container">
            <button className="sign-up-button">
              <Link to="/sign-up" style={{color: "white", fontWeight: "600"}}>
                Sign Up Now!
              </Link>
            </button>
            <button className="watch-demo-button">
              <a href="#demo-video" style={{color: "rgb(247, 147, 30)"}}>Watch Demo</a>
            </button>
          </div>
        </div>
        <div className="companies-container">
          <div className="companies-text">
            Partnered With Leading Accounting Firms Such As:
          </div>
          <div className="companies-logos">
            <img className="logos" src={require("../assets/img/Logo_Rise.png")} alt="Rise CPA Logo"/>
            <img className="logos" src={require("../assets/img/Logo_Crowe.png")} alt="Crowe Logo"/>
            <img className="logos" src={require("../assets/img/Logo_KPMG.png")} alt="KPMG Logo"/>
            <img className="logos" src={require("../assets/img/Logo_BDO.png")} alt="BDO Logo"/>
            <img className="logos" src={require("../assets/img/Logo_BT.png")} alt="Baker Tilly Logo"/>
            <img className="logos" src={require("../assets/img/Logo_Davidson.png")} alt="Davidson & Company Logo"/>
          </div>
        </div>
        <div id="demo-video" className="demo-video-container">
          <div className="demo-video-text">Watch Our Demo</div>
          <iframe className="demo-video" src="https://www.youtube.com/embed/LysdhRrewLk?si=HJdL-NB0Djw3iGBE" title="RNR Demo Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"></iframe>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  token: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  login: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  loading: state.auth.loading,
  error: state.auth.error,
});
const mapDispatchToProps = (dispatch) => ({
  login: bindActionCreators(login, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
