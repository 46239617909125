const msBeforeRefresh = 30 * 1000; // 30 seconds

class Auth {
  static _singleton = null;

  static getInstance() {
    if (!this._singleton) {
      this._singleton = new Auth("__PRIVATE__");
    }
    return this._singleton;
  }

  constructor(secret) {
    if (secret !== "__PRIVATE__") throw new Error("Auth constructor is private");
    this._access_token = "";
    this._expires = 0;
    this._refresh_token = "";
    this._scope = null;
  }

  _setupTimers() {
    if (this !== Auth._singleton) return;
    if (this._refreshTimeout) {
      clearTimeout(this._refreshTimeout);
      this._refreshTimeout = null;
    }
    if (this._expiresTimeout) {
      clearTimeout(this._expiresTimeout);
      this._expiresTimeout = null;
    }
    const refreshDelay = Math.max(this._expires * 1000 - Date.now() - msBeforeRefresh, 0);
    const expiresDelay = Math.max(this._expires * 1000 - Date.now(), 0);
    this._refreshTimeout = setTimeout(
      this._refreshToken.bind(this),
      refreshDelay
    );
    if (expiresDelay > 0) {
      this._expiresTimeout = setTimeout(
        this._onTokenExpired.bind(this),
        expiresDelay
      );
    }
  }

  setOnTokenExpiredCallback(cb) {
    if (this !== Auth._singleton) return;
    this._onTokenExpiredCallback = cb;
  }

  _onTokenExpired() {
    //console.debug("Auth._onTokenExpired()", new Date(this._expires*1000));
    if (this._onTokenExpiredCallback)
      this._onTokenExpiredCallback({ expires: this._expires });
  }

  setRefreshTokenCallback(cb) {
    if (this !== Auth._singleton) return;
    this._refreshTokenCallback = cb;
  }

  _refreshToken() {
    console.debug(
      "Auth._refreshToken()",
      new Date(this._expires * 1000),
      this._refresh_token
    );
    if (this._refreshTokenCallback)
      this._refreshTokenCallback({
        expires: this._expires,
        refresh_token: this._refresh_token
      });
  }

  isAuthenticated() {
    if (!this._access_token) return false;
    if (Date.now() >= this._expires * 1000) {
      return false;
    }
    if (!this.getScope()) {
      return false;
    }
    return true;
  }

  getScope() {
    return this._scope;
  }

  getUserName() {
    return this._data ? this._data.username : null;
  }

  getUserId() {
    return this._data ? this._data.sub : null;
  }

  getIsBlocked() {
    return this._data ? this._data.isBlocked : false;
  }

  getFirstName() {
    return this._data ? this._data.firstName : null;
  }

  getLastName() {
    return this._data ? this._data.lastName : null;
  }

  authenticate(token) {
    if (!token) throw new Error("token is missing");
    if (typeof token !== "object")
      throw new TypeError("token must be an object");
    this._access_token = token.access_token;
    this._expires = token.expires || (token.created_at + token.expires_in);
    if (!this._expires) throw new Error("token expiration unknown");
    this._refresh_token = token.refresh_token;
    this._scope = token.scope;
    const parts = this._access_token.split(".");
    this._data = JSON.parse(atob(parts[1]));
    this._setupTimers();
  }

  logout() {
    //console.debug("Auth.logout()");
    this._refresh_token = "";
    this._access_token = "";
    this._expires = 0;
    this._scope = null;
    if (this._refreshTimeout) {
      clearTimeout(this._refreshTimeout);
      this._refreshTimeout = null;
    }
    if (this._expiresTimeout) {
      clearTimeout(this._expiresTimeout);
      this._expiresTimeout = null;
    }
  }

  getAccessToken() {
    return this._access_token;
  }
}

export default Auth;
