import {validateName, validatePostalCode, validatePresent, validateState} from "../../../utils/validators";
import states from "../../../data/states";
import countries from "../../../data/countries";
import jobFunctions from "../../../data/jobFunctions";

export const validators = {
  firstName: validateName,
  lastName: validateName,
  phoneNumber: validatePresent,
  companyName: validateName,
  jobTitle: validateName,
  street1: validatePresent,
  city: validatePresent,
  state: validateState,
  country: validatePresent,
  postalCode: validatePostalCode,
  jobFunction: validatePresent,
};

export const options = {
  state: states,
  country: countries,
  jobFunction: jobFunctions,
}

export const transformToCities = (cities) => {
  return cities.map(x => ({
    value: x.name, //DO not use id
    text: x.name
  }))
}

export const transformToForm = (employer) => {
  return {
    firstName: employer.firstName,
    lastName: employer.lastName,
    phoneNumber: employer.phoneNumber,
    companyName: employer.companyName,
    jobTitle: employer.jobTitle,
    jobFunction: employer.jobFunction,
    ...employer.companyAddress,
  }
}

export const transformFormToApiRequest = (form) => {
  const {
    firstName,
    lastName,
    phoneNumber,
    companyName,
    jobTitle,
    street1,
    street2,
    city,
    state,
    country,
    postalCode,
    jobFunction,
  } = form;

  return {
    firstName,
    lastName,
    phoneNumber,
    companyName,
    jobTitle,
    companyAddress: {
      street1,
      street2,
      city,
      state,
      country,
      postalCode
    },
    jobFunction
  }
}
