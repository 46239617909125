import React from "react";
import Pagination from "react-js-pagination";
import GridRow from "./GridRow";
import FilterBar from "./FilterBar";
import useJobsGrid from "../../../hooks/useJobsGrid";
import GridError from "./GridError";
import GridLoading from "./GridLoading";
import GridNoData from "./GridNoData";
import Auth from "../../../utils/auth";
import {clsx} from "clsx";

function JobsGrid({title}) {
  const auth = Auth.getInstance();
  const scope = auth.getScope();
  const isAdmin = scope === "admin";

  const {
    jobsV2,
    queryParams,
    handleChangeQueryParams,
    handleOpenRemoveJobPostModal,
    handleRedirectToJobPage,
    handleRedirectToJobPostingPage,
    handleRedirectToCandidatesPage,
    handlePinJob,
  } = useJobsGrid();

  const startIdx = (queryParams.offset * queryParams.limit) + 1;
  const endIdx = Math.min(startIdx + jobsV2.jobs.length - 1, jobsV2.totalCount);

  const renderBody = () => {
    if (jobsV2.findJobsError) {
      return <GridError error={jobsV2.findJobsError}/>
    }

    if (jobsV2.findingJobs && !jobsV2.jobs.length) {
      return <GridLoading/>
    }

    if (jobsV2.jobs.length === 0) {
      return <GridNoData/>
    }

    return jobsV2.jobs.map((job) => {
      return (
        <GridRow
          key={job.jobId}
          className={clsx({
            'jobs-grid-row--with-pin': isAdmin,
          })}
          job={job}
          scope={scope}
          onRemoveJob={handleOpenRemoveJobPostModal}
          onRedirectToJobPage={handleRedirectToJobPage}
          onRedirectToJobPostingPage={handleRedirectToJobPostingPage}
          onRedirectToCandidatesPage={handleRedirectToCandidatesPage}
          onPinJob={handlePinJob}
        />)
    })
  }

  return (
    <>
      <div className='jobs-grid'>
        <header className='jobs-grid-header'>
          <div className='jobs-grid-header__title'>{title}</div>
          <FilterBar
            queryParams={queryParams}
            onChange={handleChangeQueryParams}
          />
        </header>
        <div className='jobs-grid-body'>
          {renderBody()}
        </div>
        <div className="jobs-grid-pagination pagination-grid">
          <div className="pagination-grid-total">
            {startIdx}–{endIdx} of {jobsV2.totalCount} posts
          </div>
          <Pagination
            activePage={queryParams.offset + 1}
            itemsCountPerPage={queryParams.limit}
            totalItemsCount={jobsV2.totalCount}
            pageRangeDisplayed={5}
            onChange={(page) => handleChangeQueryParams({offset: page - 1})}
          />
        </div>
      </div>
    </>
  )
}

export default JobsGrid;
