import * as Icon from "react-bootstrap-icons";
import {calculateColorByScore, formatDateToUS} from "../../../utils/commons";
import AssessmentDetailsDropdown from "./AssessmentDetailsDropdown";
import StatusDropdown from "./StatusDropdown";
import {Button} from "react-bootstrap";
import React from "react";

const getPostDate = (postDate) => formatDateToUS(postDate);
const getSalaryRange = ({ from = '', to = '' }) => {
  return `${from} - ${to}`.trim();
}

const GridRow = ({
  candidate,
  job,
  employer,
  scope,
  onShowFeedback,
  onOpenResume,
  onDownloadResume,
  onRevertCandidate,
  onRejectCandidateByEmployer,
  onVerifyCandidate,
  onAcceptCandidate,
  onScheduleInterview,
  onEnvelopeClick
}) => {
  const {firstName, lastName, status, minSalary, maxSalary, score = 80, postDate} = candidate;
  const fullName = `${firstName} ${lastName}`;
  const postDateFormated = getPostDate(postDate);
  const salaryRange = getSalaryRange({from: minSalary, to: maxSalary});

  return (
    <>
      <div className='ec-grid-row'>
        <div className="ec-grid-col ec-grid-col-name">{fullName}</div>
        <div className="ec-grid-col ec-grid-col-date"><span>{postDateFormated}</span></div>
        <div className="ec-grid-col ec-grid-col-resume">
          <a href='#' className="ec-link" onClick={(e) => onOpenResume(e, candidate)}>View</a>
          <div className="ec-download" onClick={(e) => onDownloadResume(e, candidate)}>
            <Icon.Download size={16}/>
          </div>
        </div>
        <div className="ec-grid-col ec-grid-col-status">
          <StatusDropdown
            status={status}
            scope={scope}
            candidate={candidate}
            onRevertCandidate={onRevertCandidate}
            onRejectCandidateByEmployer={onRejectCandidateByEmployer}
            onVerifyCandidate={onVerifyCandidate}
            onAcceptCandidate={onAcceptCandidate}
            onScheduleInterview={onScheduleInterview}
          />
        </div>
        <div className="ec-grid-col ec-grid-col-salary">
          <span>{salaryRange}</span>
        </div>
        <div className="ec-grid-col ec-grid-col-envelope">
          <Button style={{padding: '18px 12px'}} bsPrefix='button' className='button--transparent' onClick={() => onEnvelopeClick({candidate, job, employer})}>
            <Icon.EnvelopePlus size={20} fill='#000'/>
          </Button>
        </div>
        <div className="ec-grid-col ec-grid-col-score">
          <span className='ec-score' style={{color: calculateColorByScore(score)}} onClick={() => onShowFeedback({show: true, data: candidate})}>{score}%</span>
          <AssessmentDetailsDropdown candidate={candidate} fullName={fullName} color={calculateColorByScore(score)}/>
        </div>
      </div>
    </>
  )
};

export default GridRow;
