import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { findInterviewTimes } from "../actions/candidates";
import {
  Table,
  FormControl,
  Col,
  Row,
  Container,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
  ButtonGroup,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import moment from "moment";
import Auth from "../utils/auth";

class InterviewTimesPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,

    interviewTimes: PropTypes.array.isRequired,
    interviewTimesRecordCounts: PropTypes.number,
    findInterviewTimes: PropTypes.func.isRequired,
  };
  timeout = null;
  state = {
    pageNr: 0,
    perPage: 25,
    search: "",
    selectedInterview: {},
    showInterviewDetails: false,
  };
  componentDidMount() {
    this.findInterviewTimes();
  }
  findInterviewTimes = async () => {
    const search = this.state.search;
    if (!search) {
      await this.props.findInterviewTimes({
        offset: this.state.pageNr,
        limit: this.state.perPage,
      });
    } else {
      await this.props.findInterviewTimes({
        offset: this.state.pageNr,
        limit: this.state.perPage,
        search: this.state.search,
      });
    }
  };
  pageChange = (pageNumber) => {
    const pageNr = pageNumber - 1;
    this.setState({ pageNr: pageNr }, () => {
      this.findInterviewTimes();
    });
  };
  search = (e) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.setState(
      {
        search: e.target.value,
        pageNr: 0,
      },
      () =>
        (this.timeout = setTimeout(() => {
          this.findInterviewTimes();
        }, 900))
    );
  };
  onInterviewDetailsHide = () =>
    this.setState({
      showInterviewDetails: false,
      selectedInterview: {},
    });
  showInterviewDetails = (row) => {
    this.setState({
      showInterviewDetails: true,
      selectedInterview: row,
    });
  };
  renderDetailsRow = (label, value) => {
    return (
      <p className="job-details-row" key={label}>
        <span className="span-label">{label}</span>: {value}
      </p>
    );
  };
  render() {
    const { selectedInterview } = this.state;
    const scope = Auth.getInstance().getScope();
    const isRecruiter = scope === 'recruiter';
    return (
      <>
        <Container fluid className="view-name-header">
          <Row>
            <Col sm="9" className="padding-none">
              <h4>Interview requests</h4>
            </Col>
            <Col sm="3" className="padding-none">
              <FormControl
                type="text"
                placeholder="Search"
                value={this.state.search}
                onChange={this.search}
              />
            </Col>
          </Row>
        </Container>
        <div className="table-wrapper">
          {isRecruiter ? this._renderRecruiter() : this._renderAdmin()}
        </div>
        <Pagination
          activePage={this.state.pageNr + 1}
          itemsCountPerPage={this.state.perPage}
          totalItemsCount={this.props.interviewTimesRecordCounts}
          pageRangeDisplayed={5}
          onChange={this.pageChange}
        />
        <Modal
          centered
          show={this.state.showInterviewDetails}
          onHide={this.onInterviewDetailsHide}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.showInterviewDetails
                ? `Interview for job - ${selectedInterview.jobTitle}`
                : null}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedInterview ? (
              <>
                <h5 className="h5-job-details">Candidate Details</h5>
                {this.renderDetailsRow(
                  "First Name",
                  selectedInterview.candidateFirstName
                )}
                {this.renderDetailsRow(
                  "Last Name",
                  selectedInterview.candidateLastName
                )}
                {this.renderDetailsRow(
                  "Email",
                  selectedInterview.candidateEmail
                )}
                {this.renderDetailsRow(
                  "Phone",
                  selectedInterview.candidatePhone
                )}
                <hr></hr>
                <h5 className="h5-job-details">Recruiter Details</h5>
                {this.renderDetailsRow(
                  "First Name",
                  selectedInterview.recruiterFirstName
                )}
                {this.renderDetailsRow(
                  "Last Name",
                  selectedInterview.recruiterLastName
                )}
                {this.renderDetailsRow(
                  "Email",
                  selectedInterview.recruiterEmail
                )}
                {this.renderDetailsRow(
                  "Phone",
                  selectedInterview.recruiterPhoneNumber
                )}
                <hr></hr>
                <h5 className="h5-job-details">Interview Details</h5>
                {this.renderDetailsRow("Job title", selectedInterview.jobTitle)}
                {this.renderDetailsRow(
                  "Date slot 1",
                  moment(selectedInterview.dateSlot1).isValid()
                    ? moment(selectedInterview.dateSlot1).format(
                        "YYYY-MM-DD HH:mm"
                      )
                    : ""
                )}
                {this.renderDetailsRow(
                  "Date slot 2",
                  moment(selectedInterview.dateSlot2).isValid()
                    ? moment(selectedInterview.dateSlot2).format(
                        "YYYY-MM-DD HH:mm"
                      )
                    : ""
                )}
                {this.renderDetailsRow(
                  "Date slot 3",
                  moment(selectedInterview.dateSlot3).isValid()
                    ? moment(selectedInterview.dateSlot3).format(
                        "YYYY-MM-DD HH:mm"
                      )
                    : ""
                )}
                {this.renderDetailsRow("Note", selectedInterview.note)}
              </>
            ) : (
              "No details"
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="marign-right-10"
              variant="danger"
              onClick={this.onInterviewDetailsHide}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  _renderAdmin() {
    return (
      <Table>
        <thead>
        <tr>
          <th>Job title</th>
          <th>Note</th>
          <th>Candidate email</th>
          <th>Recruiter email</th>
          <th className="actions-transparent">Actions</th>
        </tr>
        </thead>
        <tbody>
        {this.props.interviewTimes.map((interview) => (
          <tr
            className="tr-main"
            key={interview.jobId + interview.candidateId}
          >
            <td>{interview.jobTitle}</td>
            <td>
              <div className="trim-td-text">{interview.note}</div>
            </td>
            <td>{interview.candidateEmail}</td>
            <td>{interview.recruiterEmail}</td>
            <td>
              <ButtonGroup className="btn-group-actions">
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>View details</Tooltip>}
                >
                  <Button
                    size="sm"
                    variant="light"
                    onClick={(e) => this.showInterviewDetails(interview)}
                    className="material-icons"
                  >
                    search
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
    );
  }

  _renderRecruiter() {
    return (
      <Table>
        <thead>
        <tr>
          <th>Name and surname</th>
          <th>Contact</th>
          <th>Company</th>
          <th>Job</th>
          <th>Note</th>
          <th>Time Slots</th>
        </tr>
        </thead>
        <tbody>
        {this.props.interviewTimes.map((interview) => {
          const dateSlot1 = moment(interview.dateSlot1);
          const dateSlot2 = moment(interview.dateSlot2);
          const dateSlot3 = moment(interview.dateSlot3);
          return (
            <tr className="tr-main" key={interview.jobId + interview.candidateId}>
              <td>{interview.candidateFirstName} {interview.candidateLastName}</td>
              <td>
                <div>
                  <b>Email:</b> <a href={'mailto:'+interview.candidateEmail}>{interview.candidateEmail}</a><br/>
                  <b>Phone:</b> {interview.candidatePhone}
                </div>
              </td>
              <td>{interview.companyName}</td>
              <td>{interview.jobTitle}</td>
              <td>
                <div className="trim-td-text">{interview.note}</div>
              </td>
              <td>
                <div>
                  <b>Slot 1:</b> {dateSlot1.isValid() ? dateSlot1.format("YYYY-MM-DD HH:mm") : ""}<br />
                  <b>Slot 2:</b> {dateSlot2.isValid() ? dateSlot2.format("YYYY-MM-DD HH:mm") : ""}<br />
                  <b>Slot 3:</b> {dateSlot3.isValid() ? dateSlot3.format("YYYY-MM-DD HH:mm") : ""}<br />
                </div>
              </td>
            </tr>
          );
        })}
        </tbody>
      </Table>
    );
  }
}

export default connect(
  (state) => ({
    interviewTimes: state.candidates.interviewTimes,
    interviewTimesRecordCounts: state.candidates.interviewTimesRecordCounts,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        findInterviewTimes: findInterviewTimes,
      },
      dispatch
    )
)(InterviewTimesPage);
