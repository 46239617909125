import axios from "axios";
import { toast } from "react-toastify";

const AUTH_LOGIN_URL = `${process.env.PUBLIC_URL}/api/v1/auth/token`;
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGIN_COMPLETED = "AUTH_LOGIN_COMPLETED";
export const AUTH_LOGIN_FAILED = "AUTH_LOGIN_FAILED";
export function login({ username, password }) {
  return async dispatch => {
    dispatch({ type: AUTH_LOGIN, payload: { username } });
    try {
      const res = await axios.post(AUTH_LOGIN_URL, {
        username,
        password,
        grant_type: "password",
        scope: "profile"
      });
      dispatch({ type: AUTH_LOGIN_COMPLETED, payload: res.data });
    } catch (err) {
      //TODO handle statusCode to have specific errors
      dispatch({
        type: AUTH_LOGIN_FAILED,
        error: "Invalid credentials"
      });
    }
  };
}

const AUTH_REFRESH_URL = `${process.env.PUBLIC_URL}/api/v1/auth/token`;
export const AUTH_REFRESH = "AUTH_REFRESH";
export const AUTH_REFRESH_COMPLETED = "AUTH_REFRESH_COMPLETED";
export const AUTH_REFRESH_FAILED = "AUTH_REFRESH_FAILED";
export function refreshToken({ refresh_token }) {
  return async dispatch => {
    dispatch({ type: AUTH_REFRESH });
    try {
      const res = await axios.post(AUTH_REFRESH_URL, {
        refresh_token,
        grant_type: "refresh_token"
      });
      dispatch({ type: AUTH_REFRESH_COMPLETED, payload: res.data });
    } catch (err) {
      dispatch({
        type: AUTH_REFRESH_FAILED,
        error: () => {
          toast.error("Can't refresh token");
          return "Can't refresh token";
        }
      });
    }
  };
}

const AUTH_LOGOUT_URL = `${process.env.PUBLIC_URL}/api/v1/auth/revoke`;
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_LOGOUT_COMPLETED = "AUTH_LOGOUT_COMPLETED";
export const AUTH_LOGOUT_FAILED = "AUTH_LOGOUT_FAILED";
export function logout(token) {
  const { refresh_token } = token || {};
  return async dispatch => {
    dispatch({ type: AUTH_LOGOUT });
    try {
      const res = await axios.post(AUTH_LOGOUT_URL, { refresh_token });
      dispatch({ type: AUTH_LOGOUT_COMPLETED, payload: res.data });
    } catch (err) {
      dispatch({
        type: AUTH_LOGOUT_FAILED,
        error: () => {
          toast.error("Can't revoke token");
          return "Can't revoke token";
        }
      });
    }
  };
}

export const AUTH_EXPIRE_TOKEN = "EXPIRE_TOKEN";
export function expireToken() {
  return { type: AUTH_EXPIRE_TOKEN };
}
