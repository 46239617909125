import {
  AUTH_EXPIRE_TOKEN,
  AUTH_LOGIN,
  AUTH_LOGIN_COMPLETED,
  AUTH_LOGIN_FAILED,
  AUTH_LOGOUT,
  AUTH_REFRESH,
  AUTH_REFRESH_COMPLETED,
  AUTH_REFRESH_FAILED
} from "./actions";

let defaultToken;
try {
  if (window.location.search.startsWith('?token=')) {
    defaultToken = atob(window.location.search.slice(7));
    localStorage.setItem("token", defaultToken);
  } else {
    defaultToken = localStorage.getItem("token");
  }
  defaultToken = JSON.parse(defaultToken);
} catch (e) {
  defaultToken = null;
}

const defaultState = Object.freeze({
  token: defaultToken,
  error: null,
  loading: false,
  refreshing: false,
  refreshError: null
});

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case AUTH_LOGIN: {
      return { ...state, loading: true };
    }
    case AUTH_LOGIN_COMPLETED: {
      localStorage.setItem("token", JSON.stringify(action.payload));
      return { ...state, loading: false, error: null, token: action.payload };
    }
    case AUTH_LOGIN_FAILED: {
      return { ...state, loading: false, error: action.error };
    }
    case AUTH_REFRESH: {
      return { ...state, refreshing: true };
    }
    case AUTH_REFRESH_COMPLETED: {
      localStorage.setItem("token", JSON.stringify(action.payload));
      return { ...state, refreshing: false, token: action.payload };
    }
    case AUTH_REFRESH_FAILED: {
      localStorage.removeItem("token");
      return {
        ...state,
        refreshing: false,
        refreshError: action.error,
        token: null
      };
    }
    case AUTH_EXPIRE_TOKEN: {
      localStorage.removeItem("token");
      return { ...state, token: null };
    }
    case AUTH_LOGOUT: {
      localStorage.removeItem("token");
      return defaultState;
    }
    default:
      return state;
  }
}
