const jobFunctions = Object.freeze([
  { value: "1", text: "Accounting" },
  { value: "13", text: "Business Development" },
  { value: "18", text: "Consultant" },
  { value: "8", text: "Customer Service" },
  { value: "15", text: "Design" },
  { value: "21", text: "Educational Institution" },
  { value: "14", text: "Engineering" },
  { value: "17", text: "Executive" },
  { value: "26", text: "Finance" },
  { value: "7", text: "General Administration" },
  { value: "5", text: "Human Resources" },
  { value: "4", text: "IT Administration" },
  { value: "2", text: "IT Hardware, Network & Support" },
  { value: "11", text: "Manufacturing" },
  { value: "19", text: "Marketing" },
  { value: "25", text: "Other" },
  { value: "12", text: "Production" },
  { value: "10", text: "Project Management" },
  { value: "20", text: "Purchasing" },
  { value: "16", text: "Quality Assurance" },
  { value: "6", text: "Sales" },
  { value: "3", text: "Software Development" },
  { value: "24", text: "Strategy & Planning" },
  { value: "23", text: "Supply Chain & Distribution" },
  { value: "22", text: "Trade Labor & Service" },
]);

export default jobFunctions;
