import { find, findOne, command } from "../utils/api";

const USERS_ACTION_URL = `${process.env.PUBLIC_URL}/api/v1/users`;

export const FIND_USERS = "FIND_USERS";
export const FIND_USERS_COMPLETED = "FIND_USERS_COMPLETED";
export const FIND_USERS_FAILED = "FIND_USERS_FAILED";

export const findUsers = find(
  USERS_ACTION_URL + "/find",
  FIND_USERS,
  FIND_USERS_COMPLETED,
  FIND_USERS_FAILED
);

export const FIND_USER = "FIND_USER";
export const FIND_USER_COMPLETED = "FIND_USER_COMPLETED";
export const FIND_USER_FAILED = "FIND_USER_FAILED";

export const findUser = findOne(
  USERS_ACTION_URL + "/find",
  FIND_USER,
  FIND_USER_COMPLETED,
  FIND_USER_FAILED
);

export const USER_BLOCK = "USER_BLOCK";
export const USER_BLOCK_COMPLETED = "USER_BLOCK_COMPLETED";
export const USER_BLOCK_FAILED = "USER_BLOCK_FAILED";

export const blockUser = command(
  USERS_ACTION_URL + "/blockUser",
  USER_BLOCK,
  USER_BLOCK_COMPLETED,
  USER_BLOCK_FAILED
);

export const USER_UNBLOCK = "USER_UNBLOCK";
export const USER_UNBLOCK_COMPLETED = "USER_UNBLOCK_COMPLETED";
export const USER_UNBLOCK_FAILED = "USER_UNBLOCK_FAILED";

export const unblockUser = command(
  USERS_ACTION_URL + "/unblockUser",
  USER_UNBLOCK,
  USER_UNBLOCK_COMPLETED,
  USER_UNBLOCK_FAILED
);

export const USER_DELETE = "USER_DELETE";
export const USER_DELETE_COMPLETED = "USER_DELETE_COMPLETED";
export const USER_DELETE_FAILED = "USER_DELETE_FAILED";

export const deleteUser = command(
  USERS_ACTION_URL + "/deleteUser",
  USER_DELETE,
  USER_BLOCK_COMPLETED,
  USER_DELETE_FAILED
);
