import moment from "moment";
import {
  Button,
  Modal,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";

import { returnTextForArrayValue } from "../../utils/commons";
import jobFunctions from "../../data/jobFunctions";
import industries from "../../data/industries";

import { fetchJobDetails } from '../../actions/jobs';
import { fetchResumeAssessments } from '../../actions/candidates';
import { fetchRecruiterDetails } from '../../actions/recruiters';

import ResumeAssessmentResults from './ResumeAssessmentResults';

// NOTE: Duplicate with ListOfCandidatesForAdmin.js!
// TODO:  Move to a common component
const renderDetailsRow = (label, value) => {
  return (
    <p className="job-details-row" key={label}>
      <span className="span-label">{label}</span>: {value}
    </p>
  );
};


function StatusCounterRow({ statusCounter, status }) {
  const formatPercentage = (value) => {
    return new Intl.NumberFormat('en-CA', { style: 'percent' }).format(value)
  }
  const statusRatio = (statusCounter, status) => {
    if (statusCounter?.total === 0) return 0;
    return statusCounter[status] / statusCounter.total;
  }
  if (!statusCounter || !(status in statusCounter)) {
    return (
      <Row>
        <Col xs="3">
          N/A
        </Col>
      </Row>
    );
  }
  return (
    <Row>
      <Col xs="1">
        <div className="p-2"><span className={`dot-${status}`}></span></div>
      </Col>
      <Col xs="1">
        <div className="p-2">{statusCounter[status]}</div>
      </Col>
      <Col xs="1">
        <div className="p-2">
          {formatPercentage(statusRatio(statusCounter, status))}
        </div>
      </Col>
    </Row>
  );
}

function ResumeAssessmentDetails({ assessment }) {
  return (
    <>
      <h5 className="h5-job-details">Resume Assessment Details</h5>
      <Container fluid className="view-name-header">
        <Row>
          <Col xs="6">
            {renderDetailsRow(
              "Processed At",
              assessment?.lastProcessedAt
            )}
          </Col>
          <Col xs="6">
            {renderDetailsRow(
              "Job Requirements",
              '\n' + assessment?.jobRequirements
            )}
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col xs="6">
            <ResumeAssessmentResults assessment={assessment?.resumeAssessment} />

            {assessment.lastError || (Array.isArray(assessment.errors) && 0 < assessment.errors.length)
              ? <h5 className="assessment-parsing-error">Error Processing Assessment</h5>
              : null }
            {assessment.lastError 
              ? <>
                  <h6 className="assessment-parsing-error">Last Error:</h6>
                  <ul>
                    <li> {assessment.lastError}</li>
                  </ul>
                </>
              : null}
            {Array.isArray(assessment.errors) && 0 < assessment.errors.length
            ? <>
                <h6 className="assessment-parsing-error">All Errors:</h6>
                <ul>
                  {assessment.errors.map(e => <li>{e}</li>)}
                </ul>
              </>
            : null}
          </Col>
          <Col xs="6">
          <h6>Scores</h6>
            <Container fluid>
              <Row>
                <Col xs="1">
                  <div className="assessment-counter-label">Color</div>
                </Col>
                <Col xs="1">
                  <div className="assessment-counter-label">Count</div>
                </Col>
                <Col xs="1">
                  <div className="assessment-counter-label">Ratio</div>
                </Col>
              </Row>
              <StatusCounterRow statusCounter={assessment?.assessmentStatusCounters} status="red" />
              <StatusCounterRow statusCounter={assessment?.assessmentStatusCounters} status="yellow" />
              <StatusCounterRow statusCounter={assessment?.assessmentStatusCounters} status="green" />
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}

function ResumeAssessmentQueue({ assessmentQueue }) {
  const duration = moment.duration(moment().diff(assessmentQueue?.createdAt)).humanize();
  return (
    <>
      <h5 className="h5-job-details">Resume Assessment Still in Processing Queue</h5>
      {renderDetailsRow(
        "assessmentId",
        assessmentQueue?.assessmentId
      )}
      {renderDetailsRow(
        "createdAt",
        assessmentQueue?.createdAt
      )}
      {renderDetailsRow(
        "Time passed",
        duration
      )}
    </>
  );
}

function RecruiterDetails({ recruiterDetails }) {
  if (!recruiterDetails) {
    return (<p>No data</p>);
  }
  return (
    <>
      {renderDetailsRow(
        "Name",
        `${recruiterDetails.firstName} ${recruiterDetails.lastName}`
      )}
      {renderDetailsRow(
        "Email",
        `${recruiterDetails.email}`
      )}
      {renderDetailsRow(
        "Phone",
        `${recruiterDetails.phoneNumber}`
      )}
    </>
  );
};

function CandidateAssessmentModal({ candidate, onHide }) {
  const [jobDetails, setJobDetails] = useState(null);
  const [assessment, setAssessment] = useState(null);
  const [recruiterDetails, setRecruiterDetails] = useState(null);
  const [assessmentQueue, setAssessmentQueue] = useState(null);

  const clearAssessmentState = () => {
    setAssessment(null);
    setAssessmentQueue(null);
  }

  useEffect(() => {
    async function fetchData() {
      const jobRequest = fetchJobDetails(candidate.jobId);
      const assessmentRequest = fetchResumeAssessments(candidate.candidateId);
      const recuiterRequest = fetchRecruiterDetails(candidate.recruiterId);

      const [jobData, assessmentData, recruiterData] = await Promise
        .all([jobRequest, assessmentRequest, recuiterRequest])
        .catch((err) => {
          toast.error(`Failed fetching data: ${err.message}'`);
        });

      if (!ignore) {
        setJobDetails(jobData[0]);
        setRecruiterDetails(recruiterData.results[0]);
        const data = assessmentData.items[0];
        
        clearAssessmentState();
        switch (assessmentData?._type) {
          case 'assessmentQueue':
            setAssessmentQueue(data);
            break;
          case 'assessments':
            setAssessment(data);
            break;
          default:
            console.error('[CandidateAssessmentModal]: unknown type of assessmentData', assessmentData);
        }
      }
    }

    if (!candidate?.jobId) return;
    let ignore = false;
    fetchData();
    return () => { ignore = true; };

  }, [candidate]);

  if (!candidate || !jobDetails) {
    return (<></>);
  }

  return (
    <>
    <Modal
      centered
      show={candidate && candidate.candidateId !== null}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {`Resume Assessment details - ${candidate.firstName} ${candidate.lastName}`} 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Container fluid className="view-name-header">
          <Row>
            <Col xs="6">
              <h5 className="h5-job-details">Candidate Details</h5>
              {renderDetailsRow(
                "Name",
                `${candidate.firstName} ${candidate.lastName}`
              )}
              {renderDetailsRow(
                "Email",
                `${candidate.email}`
              )}
            </Col>
            <Col xs="6">
              <h5 className="h5-job-details">Recruiter Details</h5>
              <RecruiterDetails recruiterDetails={recruiterDetails} />
            </Col>
          </Row>
        </Container>
        <hr/>
        <h5 className="h5-job-details">Job Details</h5>
        <Container fluid className="view-name-header">
          <Row>
            <Col xs="6">
              {renderDetailsRow(
                "Job Title",
                jobDetails.jobTitle
              )}
              {renderDetailsRow(
                "Job Function",
                returnTextForArrayValue(jobFunctions, jobDetails.jobFunction)
              )}
              {renderDetailsRow(
                "Industry",
                returnTextForArrayValue(industries, jobDetails.industry)
              )}
            </Col>
            <Col xs="6">
              {renderDetailsRow(
                "Company Name",
                jobDetails.companyName
              )}
              {renderDetailsRow(
                "Company Description",
                jobDetails.companyDescription
              )}
            </Col>
          </Row>
        </Container>

        <hr/>
        {assessment 
          ? <ResumeAssessmentDetails assessment={assessment} /> 
          : null
        }
        {assessmentQueue 
          ? <ResumeAssessmentQueue assessmentQueue={assessmentQueue} /> 
          : null
        }
        {!assessment && !assessmentQueue 
          ? <p className="assessment-parsing-error">No Resume Assessment found</p>
          : null}
        
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="marign-right-10"
          variant="danger"
          // onClick={() => this.clearJobDetails()}
          onClick={onHide}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  );
}

export default CandidateAssessmentModal;