import {
  RECRUITER_SIGN_UP,
  RECRUITER_SIGN_UP_COMPLETED,
  RECRUITER_SIGN_UP_FAILED,
  RECRUITER_VERIFY,
  RECRUITER_VERIFY_COMPLETED,
  RECRUITER_VERIFY_FAILED,
  RECRUITER_APPROVE,
  RECRUITER_APPROVE_COMPLETED,
  RECRUITER_APPROVE_FAILED,
  FIND_RECRUITERS,
  FIND_RECRUITERS_COMPLETED,
  FIND_RECRUITERS_FAILED,
  FIND_RECRUITER,
  FIND_RECRUITER_COMPLETED,
  FIND_RECRUITER_FAILED,
  FIND_LOGGED_RECRUITER,
  FIND_LOGGED_RECRUITER_COMPLETED,
  FIND_LOGGED_RECRUITER_FAILED,
  INVITE_RECRUITER,
  INVITE_RECRUITER_COMPLETED,
  INVITE_RECRUITER_FAILED,
  CLEAR_INVITATION_ERRORS,
  CREATE_RECRUITER,
  CREATE_RECRUITER_COMPLETED,
  CREATE_RECRUITER_FAILED,
} from "../actions/recruiters";
import { PAGE_INIT } from "../actions/pages";
import { toast } from "react-toastify";

export const defaultState = Object.freeze({
  signingUp: false,
  signUpErrors: {},
  signedUp: true,
  veryfingRecruiter: false,
  recruiterVerified: false,
  verifyRecruiterError: "",
  findingRecruiters: false,
  recruiters: [],
  findRecruitersError: "",
  findingRecruiter: false,
  findRecruiterError: "",
  recruiter: {},
  approveRecruiterError: "",
  approvingRecruiter: false,
  inivationErrors: [],
  totalCount: 0,
  verifyingRecruiter: false,
  loggedRecruiter: {},
  orders: undefined,
});

const recruitersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case PAGE_INIT: {
      return defaultState;
    }
    case FIND_RECRUITERS: {
      const { page, pageSize, orders, search } = action.payload;
      return {
        ...state,
        page,
        pageSize,
        orders,
        search,
        findingRecruiters: true,
        findRecruitersError: "",
      };
    }
    case FIND_RECRUITERS_COMPLETED: {
      return {
        ...state,
        findingRecruiters: false,
        recruiters: action.payload.results,
        totalCount: action.payload.total,
      };
    }
    case FIND_RECRUITERS_FAILED: {
      return {
        ...state,
        findingRecruiters: false,
        findRecruitersError: action.payload.message,
      };
    }
    case FIND_RECRUITER: {
      return {
        ...state,
        findingRecruiter: true,
        findRecruiterError: "",
      };
    }
    case FIND_RECRUITER_COMPLETED: {
      return {
        ...state,
        findingRecruiter: false,
        recruiter: action.payload || {},
        draftJobId: action.payload && action.payload.jobId,
      };
    }
    case FIND_RECRUITER_FAILED: {
      return {
        ...state,
        findingRecruiter: false,
        findRecruiterError: action.payload.message,
      };
    }
    case CLEAR_INVITATION_ERRORS: {
      return {
        ...state,
        inivationErrors: [],
      };
    }
    case INVITE_RECRUITER: {
      return {
        ...state,
        inivationErrors: [],
      };
    }
    case INVITE_RECRUITER_COMPLETED: {
      return {
        ...state,
        inivationErrors: [],
      };
    }
    case INVITE_RECRUITER_FAILED: {
      let inivationErrors = [];
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        toast.error(action.payload.response.data.message);
      } else {
        inivationErrors = action.payload.response.data;
      }
      return {
        ...state,
        inivationErrors: inivationErrors,
      };
    }
    case CREATE_RECRUITER: {
      return {
        ...state,
      };
    }
    case CREATE_RECRUITER_COMPLETED: {
      return {
        ...state,
        recruiters: [...state.recruiters, action.payload],
      };
    }
    case CREATE_RECRUITER_FAILED: {
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        toast.error(action.payload.response.data.message);
      } else {
        toast.error(action.payload.response.data);
      }
      return {
        ...state,
      };
    }
    case RECRUITER_APPROVE: {
      return {
        ...state,
        approveRecruiterError: "",
        approvingRecruiter: true,
      };
    }
    case RECRUITER_APPROVE_COMPLETED: {
      return {
        ...state,
        approveRecruiterError: "",
        approvingRecruiter: false,
        recruiters: state.recruiters.map((x) => {
          if (x.recruiterId === action.payload.recruiterId) {
            x.approved = true;
          }
          return x;
        }),
      };
    }
    case RECRUITER_APPROVE_FAILED: {
      let approveErrors;
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        if (Array.isArray(action.payload.response.data.message)) {
          approveErrors = JSON.stringify(
            action.payload.response.data.message.reduce((errors, error) => {
              errors[error.field] = error.message;
              return errors;
            }, {})
          );
        } else {
          approveErrors = action.payload.response.data.message;
        }
      } else {
        approveErrors = action.payload.message;
      }
      return {
        ...state,
        approveRecruiterError: approveErrors,
        approvingRecruiter: false,
      };
    }
    case RECRUITER_VERIFY: {
      return {
        ...state,
        recruiterVerified: false,
        verifyRecruiterError: "",
        verifyingRecruiter: true,
      };
    }
    case RECRUITER_VERIFY_COMPLETED: {
      return {
        ...state,
        recruiterVerified: true,
        verifyRecruiterError: "",
        verifyingRecruiter: false,
      };
    }
    case RECRUITER_VERIFY_FAILED: {
      let verifyErrors;
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        if (Array.isArray(action.payload.response.data.message)) {
          verifyErrors = JSON.stringify(
            action.payload.response.data.message.reduce((errors, error) => {
              errors[error.field] = error.message;
              return errors;
            }, {})
          );
        } else {
          verifyErrors = action.payload.response.data.message;
        }
      } else {
        verifyErrors = action.payload.message;
      }
      return {
        ...state,
        recruiterVerified: false,
        verifyRecruiterError: verifyErrors,
        verifyingRecruiter: false,
      };
    }
    case RECRUITER_SIGN_UP: {
      return {
        ...state,
        signingUp: true,
        signUpErrors: {},
      };
    }
    case RECRUITER_SIGN_UP_COMPLETED: {
      return {
        ...state,
        signingUp: false,
        signedUp: true,
      };
    }
    case RECRUITER_SIGN_UP_FAILED: {
      let signUpErrors;
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        if (Array.isArray(action.payload.response.data.message)) {
          signUpErrors = action.payload.response.data.message.reduce(
            (errors, error) => {
              errors[error.field] = error.message;
              return errors;
            },
            {}
          );
        } else {
          signUpErrors = { $form: action.payload.response.data.message };
        }
      } else {
        signUpErrors = { $form: action.payload.message };
      }
      return {
        ...state,
        signingUp: false,
        signUpErrors,
      };
    }
    case FIND_LOGGED_RECRUITER: {
      return {
        ...state,
        findingRecruiter: true,
        findingRecruiterError: "",
      };
    }
    case FIND_LOGGED_RECRUITER_COMPLETED: {
      return {
        ...state,
        findingRecruiter: false,
        loggedRecruiter: action.payload || {},
      };
    }
    case FIND_LOGGED_RECRUITER_FAILED: {
      return {
        ...state,
        findingRecruiter: false,
        findRecruiterError: action.payload.message,
      };
    }
    default:
      return state;
  }
};

export default recruitersReducer;
