
function ResumeAssessmentResults({ assessment }) {
  if (!Array.isArray(assessment) || !assessment?.length) {
    return (
      <>
        <p>Error: No Assessment found</p>
      </>
    );
  }

  return (
    <>
      <h6>Assessment result</h6>
      <ul>
        {assessment.map((item, idx) => (
          <li key={`${idx}`} className="assessment-status">
            <span className={`dot-${item.status}`}></span>&nbsp;
            <span className="assessment-requirement-title">Requirement:</span> 
            &nbsp;
            <span className="assessment-requirement">{item.requirement}</span>
            <br/>&nbsp;
            <span className="assessment-feedback-title">Feedback:</span> 
            &nbsp;
            <span className={`assessment-feedback-${item.status}`}>
            {item.feedback}
            </span>
          </li>
          ))
        }
      </ul>
    </>
  );
}

export default ResumeAssessmentResults;