import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_COMPLETED,
  CHANGE_PASSWORD_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_COMPLETED,
  RESET_PASSWORD_FAILED,
  CHANGE_PASSWORD_WITH_GUID,
  CHANGE_PASSWORD_WITH_GUID_COMPLETED,
  CHANGE_PASSWORD_WITH_GUID_FAILED
} from "../actions/account";
import { PAGE_INIT } from "../actions/pages";
import { toast } from "react-toastify";

export const defaultState = Object.freeze({});

const accountReducer = (state = defaultState, action) => {
  switch (action.type) {
    case PAGE_INIT: {
      return defaultState;
    }
    case CHANGE_PASSWORD_WITH_GUID: {
      return {
        ...state
      };
    }
    case CHANGE_PASSWORD_WITH_GUID_COMPLETED: {
      toast.success("Password change success");
      return {
        ...state
      };
    }
    case CHANGE_PASSWORD_WITH_GUID_FAILED: {
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        toast.error(action.payload.response.data.message);
      } else {
        toast.error(`${action.payload.response.data}`);
      }
      return {
        ...state
      };
    }
    case CHANGE_PASSWORD: {
      return {
        ...state
      };
    }
    case CHANGE_PASSWORD_COMPLETED: {
      toast.success("Password change success");
      return {
        ...state
      };
    }
    case CHANGE_PASSWORD_FAILED: {
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        toast.error(action.payload.response.data.message);
      } else {
        toast.error(`${action.payload.response.data}`);
      }
      return {
        ...state
      };
    }
    case RESET_PASSWORD: {
      return {
        ...state
      };
    }
    case RESET_PASSWORD_COMPLETED: {
      toast.success("Password reset link sent");
      return {
        ...state
      };
    }
    case RESET_PASSWORD_FAILED: {
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        toast.error(action.payload.response.data.message);
      } else {
        toast.error(`${action.payload.response.data}`);
      }
      return {
        ...state
      };
    }
    default:
      return state;
  }
};

export default accountReducer;
