import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useCode } from "../actions/employers";

class JobPostingPlanPage extends Component {
  static propTypes = {
    useCode: PropTypes.func.isRequired,
  };
  state = {
    useCodeModalOpen: false,
    code: "",
  };
  openUseCodeModal = () => {
    this.setState({
      useCodeModalOpen: true,
      code: "",
    });
  };
  closeUseCodeModal = () => {
    this.setState({
      useCodeModalOpen: false,
    });
  };
  onCodeChange = (e) => {
    const value = e.target.value;
    this.setState({
      code: value,
    });
  };
  useCode = async () => {
    await this.props.useCode({ customCode: this.state.code });
    this.closeUseCodeModal();
  };
  render() {
    return (
      <>
        <Modal
          centered
          show={this.state.useCodeModalOpen}
          onHide={this.closeUseCodeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Use code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label>Code</Form.Label>
            <Form.Control
              type="text"
              onChange={this.onCodeChange}
              value={this.state.code}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="marign-right-10"
              variant="danger"
              onClick={this.closeUseCodeModal}
            >
              Close
            </Button>
            <Button variant="primary" onClick={this.useCode}>
              Use
            </Button>
          </Modal.Footer>
        </Modal>
        <Container fluid className="view-name-header">
          <Row>
            <Col sm="10" className="padding-none">
              <h4>Purchase a job post</h4>
            </Col>
            <Col sm="2" className="padding-none">
              <Button
                className="float-right"
                style={{ marginRight: "10px" }}
                onClick={this.openUseCodeModal}
              >
                Use code
              </Button>
            </Col>
          </Row>
          <Row>
            <div className="purchase-plans-container">
              <div className="purchase-plan-box">
                <div className="plan-title">1-Job Posting</div>
                <div className="plan-content-wrapper">
                  <div className="plan-content">1 Job</div>
                  <div className="plan-content">Contact administrator</div>
                  <div className="plan-content">
                    Email: <a href="mailto:info@recruitnrefer.ca">info@recruitnrefer.ca</a>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({ useCode: useCode }, dispatch)
)(JobPostingPlanPage);
