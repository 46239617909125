// eslint-disable-next-line no-useless-escape
const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const urlRegEx = new RegExp(
  "^(https?:\\/\\/)?" + // protocol
  "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
  "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
  "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
  "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
  "i"
); // fragment locator
const postalCodeRegEx = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
const zipCodeRegEx = /^\d{5}$/;
const numberRegEx = /^\d+$/;

export function validateName(value) {
  if (value.length < 2 || value.length > 40) {
    return "must be between 2 and 40 characters";
  }
}

export function validateNameOrEmpty(value) {
  if (value.length === 0) return;
  if (value.length < 2 || value.length > 40) return "must be between 2 and 40 characters";
}

export function validatePresent(value) {
  if (!value) {
    return "must be present";
  }
}

export function validateNumber(value) {
  if (numberRegEx.test(value)) return;
  return "must be a valid number";
}

export function validateEmail(value) {
  if (emailRegEx.test(value)) return;
  return "must be a valid email address";
}

export function isStateRequired(country) {
  return ['', 'US', 'CA'].includes(country)
}

export function isCityRequired(country) {
  return ['', 'US', 'CA'].includes(country)
}

export function validateState(value, state) {
  if (isStateRequired(state.country) && !value) return "must be present";
}

export function validateCity(value, state) {
  if (isCityRequired(state.country) && !value) return "must be present";
}

export function validatePostalCode(value, state) {
  if (state.country === 'US' && !zipCodeRegEx.test(value)) return "must be a valid zip code";
  if (state.country === 'CA' && !postalCodeRegEx.test(value)) return "must be a valid postal code";
  if (!value) return "must be present";
}

export function validatePostalCodeOrEmpty(value, state) {
  if (value.length === 0) return;
  if (state.country === 'US' && !zipCodeRegEx.test(value)) return "must be a valid zip code";
  if (state.country === 'CA' && !postalCodeRegEx.test(value)) return "must be a valid postal code";
}

export function validatePassword(value) {
  if (value.length < 8) return "must be at least 8 characters";
  // TODO 1 capital letter
  // TODO 1 digit
  // TODO 1 special char
}

export function validateUrl(value) {
  if (urlRegEx.test(value)) return;
  return "must be a valid url";
  // TODO 1 capital letter
  // TODO 1 digit
  // TODO 1 special char
}
