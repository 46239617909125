import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { verifyEmployer } from "../actions/employers";
import { Alert, Spinner, Jumbotron, Button } from "react-bootstrap";

class VerifyEmployerPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    verifyingEmployer: PropTypes.bool.isRequired,
    employerVerified: PropTypes.bool.isRequired,
    verifyEmployerError: PropTypes.string.isRequired,
    verifyEmployer: PropTypes.func.isRequired,
  };
  state = {
    show: false,
  };
  _renderError() {
    if (!this.props.verifyEmployerError) return null;
    return <Alert variant="danger">{this.props.verifyEmployerError}</Alert>;
  }
  async componentDidMount() {
    const { employerId } = this.props.match.params;
    setTimeout(() => {
      this.setState({
        show: true,
      });
    }, 5000);
    try {
      await this.props.verifyEmployer({ employerId });
      this.props.history.push(
        `/employer?verifySuccess=Account verification success`
      );
    } catch (e) {
      this.props.history.push(
        `/employer?verifyFail=${this.props.verifyEmployerError}`
      );
    }
  }

  render() {
    const { verifyingEmployer, employerVerified } = this.props;
    return (
      <div>
        {this.state.show ? (
          <Jumbotron>
            <h1>Account verification</h1>
            <div>
              {this._renderError()}
              {employerVerified ? (
                <Alert variant="success">Account verification success</Alert>
              ) : null}
              {verifyingEmployer ? (
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : null}
            </div>
            <div>
              <Button
                onClick={() => this.props.history.push(`/employer`)}
                variant="primary"
              >
                Home page
              </Button>
            </div>
          </Jumbotron>
        ) : null}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    verifyingEmployer: state.employers.verifyingEmployer,
    employerVerified: state.employers.employerVerified,
    verifyEmployerError: state.employers.verifyEmployerError,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        verifyEmployer,
      },
      dispatch
    )
)(VerifyEmployerPage);
