import React, { Component } from "react";
import { Button, Jumbotron } from "react-bootstrap";

export default class SignUpEmployerSuccess extends Component {
  render() {
    return (
      <div>
        <Jumbotron>
          <h1>One last step!</h1>
          <p className="my-5">Before you can login, you have to go to your email inbox and click the activation link we sent you.</p>
          <Button
            onClick={() => this.props.history.push(`/employer`)}
            variant="primary"
          >
            Home page
          </Button>
        </Jumbotron>
      </div>
    );
  }
}
