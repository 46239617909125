const EarningsDisplay = ({ referValue, feeValue, salaryFrom, isFeePercent, isReferrer }) => {
  if (salaryFrom) {
    if (isFeePercent) {
      if (isReferrer === true) {
        return `$${(referValue / 100 * salaryFrom).toLocaleString()}`;
      } else {
        return `$${(feeValue / 100 * salaryFrom).toLocaleString()}`;
      }
    } else {
      if (isReferrer === true) {
        return `$${(referValue ?? 0).toLocaleString()}`;
      } else {
        return `$${(feeValue ?? 0).toLocaleString()}`;
      }
    }
  }
  return "-";
}

export default EarningsDisplay;