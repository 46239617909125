import React from "react";
import PropTypes from "prop-types";

import LinkedInSignInDefault from "../assets/img/Sign-In-Small---Default.png";
import LinkedInSignInHover from "../assets/img/Sign-In-Small---Hover.png";
import LinkedInSignInActive from "../assets/img/Sign-In-Small---Active.png";

const redirectTo = () => window.location.href = `${process.env.PUBLIC_URL}/api/v1/auth/linkedin`;

class SignInWithLinkedIn extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    hidden: PropTypes.bool,
    style: PropTypes.object,
  };

  state = {
    src: LinkedInSignInDefault,
  };

  render() {
    return (
      <img
        {...this.props}
        src={this.state.src}
        alt="LinkedIn"
        onMouseEnter={() => this.setState({ src: LinkedInSignInHover })}
        onMouseLeave={() => this.setState({ src: LinkedInSignInDefault })}
        onClick={() => this.setState({ src: LinkedInSignInActive }, redirectTo)}
      />
    );
  }
}

export default SignInWithLinkedIn;