import {
  FIND_RETAIL_TERMS,
  FIND_RETAIL_TERMS_COMPLETED,
  FIND_RETAIL_TERMS_FAILED,
  CREATE_RETAIL_TERM,
  CREATE_RETAIL_TERM_COMPLETED,
  CREATE_RETAIL_TERM_FAILED, DELETE_RETAIL_TERM, DELETE_RETAIL_TERM_COMPLETED, DELETE_RETAIL_TERM_FAILED,
} from "../actions/retailTerms";
import { toast } from "react-toastify";
import { PAGE_INIT } from "../actions/pages";

export const defaultState = Object.freeze({
  retailTerms: [],
  retailTermsLoading: false,
  findingRetailTermsErrors: "",
  creatingCodeErrors: {},
});

const customCodesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case PAGE_INIT: {
      return defaultState;
    }
    case FIND_RETAIL_TERMS: {
      return {
        ...state,
        retailTermsLoading: true,
        findingRetailTermsErrors: "",
      };
    }
    case FIND_RETAIL_TERMS_COMPLETED: {
      return {
        ...state,
        retailTermsLoading: false,
        retailTerms: action.payload,
      };
    }
    case FIND_RETAIL_TERMS_FAILED: {
      return {
        ...state,
        retailTermsLoading: false,
        findingRetailTermsErrors: action.payload.message,
      };
    }
    case CREATE_RETAIL_TERM: {
      return {
        ...state,
        retailTermsLoading: true,
        creatingCodeErrors: {},
      };
    }
    case CREATE_RETAIL_TERM_COMPLETED: {
      return {
        ...state,
        retailTermsLoading: false,
        creatingCodeErrors: {},
      };
    }
    case CREATE_RETAIL_TERM_FAILED: {
      let creatingCodeErrors;
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        if (Array.isArray(action.payload.response.data.message)) {
          creatingCodeErrors = action.payload.response.data.message.reduce(
            (errors, error) => {
              errors[error.field] = error.message;
              return errors;
            },
            {}
          );
        } else {
          toast.error(action.payload.response.data.message);
        }
      } else {
        toast.error(action.payload.message);
      }
      return {
        ...state,
        retailTermsLoading: false,
        creatingCodeErrors,
      };
    }
    case DELETE_RETAIL_TERM: {
      return {
        ...state,
        retailTermsLoading: true,
        creatingCodeErrors: {},
      };
    }
    case DELETE_RETAIL_TERM_COMPLETED: {
      return {
        ...state,
        retailTermsLoading: false,
        creatingCodeErrors: {},
      };
    }
    case DELETE_RETAIL_TERM_FAILED: {
      return {
        ...state,
        retailTermsLoading: false,
        deletingRetailTermsErrors: action.payload.message,
      };
    }
    default:
      return state;
  }
};

export default customCodesReducer;
