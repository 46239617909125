import axios from "axios";
import {authorizationHeaders} from "../utils/api";

/**
 * TODO: integrate with Redux later - like uploadFile method
 */

export async function assessResume(jobId, resumeText, resumeFileName) {
  if (!resumeText) {
    throw new Error('resumeText is missing');
  }
  try {
    const { data } = await axios.post(
      `${process.env.PUBLIC_URL}/api/v1/resume/assess`,
      {
        jobId,
        resumeFileName,
        resumeText,
      },
      { headers: authorizationHeaders() }
    );
    // console.log('[assessResume] response data: ', data);
    return data;
  }
  catch(err) {
    console.error('[assessResume]: error: ', err);
    console.error(`[assessResume]: error: ${err.code} -> ${JSON.stringify(err.response.data.message)}`);
    throw new Error(err.response.data.message);
  }
};