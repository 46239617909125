import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Jumbotron, Button, Col, Form } from "react-bootstrap";
import { resetPassword } from "../actions/account";
import { validateEmail } from "../utils/validators";

class PasswordResetPage extends Component {
  static propTypes = {
    resetPassword: PropTypes.func.isRequired,
  };
  state = {
    email: "",
    isValidEmail: true,
  };
  handleInputChange = (e) => {
    this.setState({
      email: e.target.value,
    });
  };
  sendLink = () => {
    this.setState({
      isValidEmail: true,
    });
    if (validateEmail(this.state.email)) {
      this.setState({
        isValidEmail: false,
      });
      return;
    }
    this.props.resetPassword({
      email: this.state.email,
    });
  };
  render() {
    return (
      <Jumbotron>
        <h1>Reset password</h1>
        <p><i>
          An email will be sent to the email address below only if a valid account is found. An account is valid if the
          email was verified and for a recruiter/referral partner, the admin also approved it.
        </i></p>
        <Form className="my-4">
          <Form.Row className="mb-2">
            <Col sm={4}>
              <Form.Label>Email</Form.Label>
            </Col>
            <Col sm={8}>
              <Form.Control
                type="text"
                value={this.state.email}
                onChange={this.handleInputChange}
                name="email"
                isInvalid={!this.state.isValidEmail}
              />
              <Form.Control.Feedback type="invalid">
                {!this.state.isValidEmail ? "invalid email" : ""}
              </Form.Control.Feedback>
            </Col>
          </Form.Row>
          <Button
            className="pull-right"
            onClick={this.sendLink}
            variant="primary"
          >
            Send password reset link
          </Button>
          <Button
            onClick={() => this.props.history.push(`/recruiter`)}
            variant="secondary"
          >
            Home page
          </Button>
        </Form>
      </Jumbotron>
    );
  }
}

export default connect(
  (state) => ({}),
  (dispatch) =>
    bindActionCreators(
      {
        resetPassword,
      },
      dispatch
    )
)(PasswordResetPage);
