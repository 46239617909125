import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Alert, Button, Card, Col, Form, Modal, ToggleButton } from 'react-bootstrap';
import {
  validateEmail,
  validateName,
  validatePassword,
  validatePresent,
  validateNameOrEmpty,
  validatePostalCodeOrEmpty,
  validateState, validatePostalCode,
} from '../utils/validators';
import { signUpRecruiter } from "../actions/recruiters";
import { initPage } from "../actions/pages";
import countries from "../data/countries";
import states from "../data/states";
import sizesOfCompany from "../data/sizesOfCompany";
import terms from "../data/terms";
import axios from 'axios'
import Autocomplete from 'react-autocomplete'
import jobFunctions from '../data/jobFunctions';
import industries from '../data/industries';
import FileUpload from './common/FileUpload';
import Auth from '../utils/auth';
import { signUpEmployer } from '../actions/employers';
import SignInWithLinkedIn from "../Auth/SignInWithLinkedIn";

const validators = {
  recruiter: {
    firstName: validateName,
    lastName: validateName,
    phoneNumber: validatePresent,
    companyName: validateNameOrEmpty,
    postalCode: validatePostalCodeOrEmpty,
    email: validateEmail,
    password: validatePassword,
  },
  employer: {
    firstName: validateName,
    lastName: validateName,
    phoneNumber: validatePresent,
    companyName: validateName,
    jobTitle: validateName,
    street1: validatePresent,
    city: validatePresent,
    state: validateState,
    country: validatePresent,
    postalCode: validatePostalCode,
    industry: validatePresent,
    jobFunction: validatePresent,
    email: validateEmail,
    password: validatePassword,
  },
  referralPartner: {
    firstName: validateName,
    lastName: validateName,
    phoneNumber: validatePresent,
    companyName: validateNameOrEmpty,
    postalCode: validatePostalCodeOrEmpty,
    email: validateEmail,
    password: validatePassword,
  }
};

const autoCompleteMenuStyle = {
  position: 'fixed',
  zIndex: 1000,
  border: 'solid 1px #777777',
  padding: '5px 10px',
  background: 'white',
  overflow: 'auto'
}

class SignUpPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,

    signingUp: PropTypes.bool.isRequired,
    signUpErrors: PropTypes.object.isRequired,

    initPage: PropTypes.func.isRequired,
    signUpRecruiter: PropTypes.func.isRequired,
    signUpEmployer: PropTypes.func.isRequired,
  };

  state = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    linkedinProfileUrl: "",
    companyName: "",
    jobTitle: "",
    street: "",
    street1: "",
    street2: "",
    sizeOfCompany: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    paypalAccountEmail: "",
    termsAgreement: 0,
    email: "",
    password: "",
    validationErrors: {},
    cities: [],
    jobFunction: "",
    industry: "",
    companyLogo: "",
    isRecruiter: true,
    isEmployer: false,
    isReferralPartner: false,
    isThirdPartyLogin: false,
  };

  options = {
    state: states,
    country: countries,
    sizeOfCompany: sizesOfCompany,
    jobFunction: jobFunctions,
    industry: industries,
  };

  constructor(props) {
    super(props);
    const auth = Auth.getInstance();
    if (auth.isAuthenticated() && auth.getScope() === 'third-party') {
      // console.log(auth)
      this.state.isThirdPartyLogin = true;
      this.state.firstName = auth.getFirstName();
      this.state.lastName = auth.getLastName();
      this.state.email = auth.getUserName();
      this.state.password = 'PASSWORD';
    }
  }

  _onChange = (e) => {
      let extra = {}, cb = function() {}
      if (e.target.name === "state") {
        extra = { city: "" }
        cb = this._fetchCities;
      } else if (e.target.name === "country") {
        extra = { state: "", city: "", cities: [] }
      }
      this.setState({ [e.target.name]: e.target.value, ...extra }, cb);
    };

  _fetchCities = async() => {
    try {
      const { data } = await axios.get(`${process.env.PUBLIC_URL}/api/v1/data/cities`, {
        params: { state: this.state.state }
      });
      this.setState({
        cities: data.map(x => ({
          value: x.name, //DO not use id
          text: x.name
        }))
      });
    } catch (e) {
      console.error(e);
    }
  }

  _onTermsChange = (e) =>
    this.setState({ termsAgreement: e.target.checked ? 1 : 0 });

  _renderSectionHeader(title, optional = false) {
    return (
      <Form.Row className="mb-2">
        <Col>
          <h5>{title}{optional ? <sub> (Optional)</sub> : ''}</h5>
        </Col>
      </Form.Row>
    );
  }

  _validateField = (validator) => (e) =>
    this.setState({
      validationErrors: {
        ...this.state.validationErrors,
        [e.target.name]: validator(e.target.value, this.state),
      },
    });

  _getFormType = () => {
    if (this.state.isRecruiter) return 'recruiter';
    else if (this.state.isEmployer) return 'employer';
    else if (this.state.isReferralPartner) return 'referralPartner';
    else throw new Error('unreachable');
  }

  _validateForm = (form) => {
    const formType = this._getFormType();
    const validationErrors = {};
    let errors = 0;
    for (let i = 0; i < form.length; i++) {
      const field = form[i];
      const validator = validators[formType][field.name];
      const error = validator && validator(field.value, this.state);
      if (error) errors++;
      validationErrors[field.name] = error;
    }
    if (errors) this.setState({ validationErrors });
    return errors === 0;
  };

  _renderRow(label, fieldName, fieldRender, required, disabled) {
    const formType = this._getFormType();
    const isRequired = required;
    const labelProps = { sm: 12 };
    const fieldProps = { sm: 12 };
    const validationError = this.state.validationErrors[fieldName];
    const isInvalid = !!validationError;
    const validator = validators[formType][fieldName];
    const onBlur = validator ? this._validateField(validator) : undefined;
    return (
      <Col sm={6}>
        {label ? (
            <Col {...labelProps}>
              <Form.Label>
                {label}{" "}
                {isRequired === true ? <span className="required-field">*</span> : ''}
                {isRequired === false ? <sub>(Optional)</sub> : ''}
              </Form.Label>
            </Col>
        ) : null}
        <Col {...fieldProps}>
          {fieldRender({
            name: fieldName,
            value: this.state[fieldName],
            onChange: this._onChange,
            isInvalid,
            onBlur,
            validationError,
            disabled,
          })}
          <Form.Control.Feedback type="invalid">
            {validationError}
          </Form.Control.Feedback>
        </Col>
      </Col>
    );
  }

  _renderTextInput = ({validationError, ...props}) => <Form.Control type="text" {...props} />;

  _renderDropdown = ({validationError, ...props}) => {
    const options = {
      ...this.options,
      state: this.state.country
        ? states.filter(x => x.country === this.state.country)
        : states,
      city: this.state.cities
    };
    return (
      <Form.Control as="select" {...props}>
        <option />
        {options[props.name].map(({ value, text }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </Form.Control>
    );
  };

  _renderAutoComplete = (props) => {
    //TODO adapt this to be used by more than one field
    return (
      <Autocomplete
        getItemValue={item => item.value}
        items={this.state.city
          ? this.state.cities.filter(x => x.text.startsWith(this.state.city))
          : this.state.cities
        }
        renderItem={(item, isHighlighted) =>
          <div key={item.value} style={{background: isHighlighted ? 'lightgray' : 'white'}}>
            {item.text}
          </div>
        }
        renderInput={inputProps =>
          <Form.Control {...inputProps}
                        type="text"
                        name={props.name}
                        autoComplete="new-password"
                        onBlur={e => {
                          e.persist()
                          props.onBlur(e)
                          inputProps.onBlur(e)
                        }} />
        }
        renderMenu={(items, value, style) =>
          <div style={{ ...style, ...autoCompleteMenuStyle, maxHeight: `calc(100vmin - ${style.top}px)` }}
               children={items}
          />
        }
        value={this.state.city}
        onChange={(e, value) =>
          props.onChange({target: {name: props.name, value}})
        }
        onSelect={value =>
          props.onChange({target: {name: props.name, value}})
        }
        wrapperStyle={{
          display: 'block'
        }}
      />
    )
  }

  _agreeToTerms = () => this.setState({ termsAgreement: 2 });

  _onTermsHide = () => this.setState({ termsAgreement: 0 });

  _goHome = () => this.props.history.replace('/');

  _signUp = async (e) => {
    e.preventDefault();

    if (this._validateForm(e.target)) {
      try {
        const {
          firstName,
          lastName,
          phoneNumber,
          linkedinProfileUrl,
          companyName,
          jobTitle,
          street,
          street1,
          street2,
          city,
          sizeOfCompany,
          paypalAccountEmail,
          state,
          country,
          postalCode,
          email,
          password,
          industry,
          jobFunction,
          companyLogo,
          isEmployer,
          isReferralPartner,
          isThirdPartyLogin,
        } = this.state;
        if (isEmployer) {
          await this.props.signUpEmployer({
            firstName,
            lastName,
            phoneNumber,
            companyName,
            jobTitle,
            companyAddress: {
              street1,
              street2,
              city,
              state,
              country,
              postalCode,
            },
            industry,
            jobFunction,
            companyLogo,
            email,
            password,
            terms: "",
          });
          if (!isThirdPartyLogin) this.props.history.push(`/employer/sign-up/success`);
        } else {
          await this.props.signUpRecruiter({
            firstName,
            lastName,
            phoneNumber,
            linkedinProfileUrl,
            companyName,
            sizeOfCompany,
            companyAddress: {
              street,
              city,
              state,
              country,
              postalCode,
            },
            paypalAccountEmail,
            email,
            password,
            isReferrer: isReferralPartner,
            terms: "",
          });
          if (!isThirdPartyLogin) this.props.history.push(`/${isReferralPartner ? 'referralpartner' : 'recruiter'}/sign-up/success`);
        }
        if (isThirdPartyLogin) setTimeout(() => { window.location.href = `${process.env.PUBLIC_URL}/api/v1/auth/linkedin/callback?access_token=${Auth.getInstance().getAccessToken()}`; }, 1000);
      } catch (e) {
        console.log(e);
        window.scrollTo(0, 0);
      }
    }
  };

  _renderFormError() {
    if (!this.state.validationErrors["$form"]) return null;
    return (
      <Alert variant="danger">{this.state.validationErrors["$form"]}</Alert>
    );
  }

  componentDidMount() {
    this.props.initPage("SignUpRecruiter");
  }

  componentDidUpdate(prevProps) {
    if (prevProps.signUpErrors !== this.props.signUpErrors)
      this.setState({ validationErrors: this.props.signUpErrors });
  }

  render() {
    const { termsAgreement, isRecruiter, isEmployer, isReferralPartner, isThirdPartyLogin } = this.state;
    return (
      <React.Fragment>
        <Form onSubmit={this._signUp}>
          <Card style={{ width: "52rem", margin: "50px auto" }}>
            <Card.Header>
              {isRecruiter ? 'Recruiter ' : ''}
              {isEmployer ? 'Employer ' : ''}
              {isReferralPartner ? 'Referral Partner ' : ''}
              Sign Up
            </Card.Header>
            <Card.Body>
              <div className="signup-button-container">
                {this._renderFormError()}
                  <ToggleButton
                    size="lg"
                    type="radio"
                    variant="success"
                    name="radio"
                    value="recruiter"
                    checked={isRecruiter}
                    onClick={() => this.setState({ isRecruiter: true, isEmployer: false, isReferralPartner: false })}
                  >
                    I'm a recruiter
                  </ToggleButton>
                  <ToggleButton
                    size="lg"
                    type="radio"
                    variant="success"
                    name="radio"
                    value="employer"
                    checked={isEmployer}
                    onClick={() => this.setState({ isRecruiter: false, isEmployer: true, isReferralPartner: false })}
                    style={{margin: "0 15px 4.8px 15px"}}
                  >
                    I'm an employer
                  </ToggleButton>
                  <ToggleButton
                    size="lg"
                    type="radio"
                    variant="success"
                    name="radio"
                    value="referralpartner"
                    checked={isReferralPartner}
                    onClick={() => this.setState({ isRecruiter: false, isEmployer: false, isReferralPartner: true })}
                  >
                    I'm a referral partner
                  </ToggleButton>
                  {isRecruiter ? <SignInWithLinkedIn style={{ cursor: 'pointer', marginLeft: "auto", width: "230px"}} /> : null}
              </div>
              {this._renderSectionHeader("Personal Information")}
              <Form.Row className="mb-2">
                {this._renderRow("First Name", "firstName", this._renderTextInput, true, isThirdPartyLogin)}
                {this._renderRow("Last Name", "lastName", this._renderTextInput, true, isThirdPartyLogin)}
              </Form.Row>
              <Form.Row className="mb-2">
                {this._renderRow("Phone Number", "phoneNumber", this._renderTextInput, true)}
                {isEmployer
                  ? this._renderRow("Job Title", "jobTitle", this._renderTextInput, true)
                  : this._renderRow("Linkedin Profile", "linkedinProfileUrl", this._renderTextInput)
                }
              </Form.Row>
              <hr />
              {this._renderSectionHeader("Company Information", isRecruiter || isReferralPartner)}
              <Form.Row className="mb-2">
                {this._renderRow("Company Name", "companyName", this._renderTextInput, isEmployer)}
                {isEmployer
                  ? this._renderRow("Job Function", "jobFunction", this._renderDropdown, isEmployer)
                  : this._renderRow("Size Of Company", "sizeOfCompany", this._renderDropdown)
                }
              </Form.Row>
              <Form.Row className="mb-2">
                {this._renderRow("Country", "country", this._renderDropdown, isEmployer)}
                {this._renderRow("Province/State", "state", this._renderDropdown, isEmployer)}
              </Form.Row>
              <Form.Row className="mb-2">
                {this._renderRow("City", "city", this._renderAutoComplete, isEmployer)}
                {this._renderRow("Postal/Zip Code", "postalCode", this._renderTextInput, isEmployer)}
              </Form.Row>
              <Form.Row className="mb-2">
                {this._renderRow("Company Address", isEmployer ? "street1" : "street", this._renderTextInput, isEmployer)}
                {isEmployer
                  ? this._renderRow("Company Address continuation...", "street2", this._renderTextInput)
                  : null /* this._renderRow("Paypal Account Email", "paypalAccountEmail", this._renderTextInput) */
                }
              </Form.Row>
              {isEmployer ? <>
                <Form.Row className="mb-2">
                  {this._renderRow("Industry", "industry", this._renderDropdown, true)}
                </Form.Row>
                <Form.Row className="mb-2">
                  {this._renderRow(
                    "Company Logo",
                    "companyLogo",
                    ({ name, onChange }) => (
                      <FileUpload name={name} onChange={onChange} />
                    ),
                    false
                  )}
                </Form.Row>
              </> : null}
              <hr />
              {this._renderSectionHeader("Login Details")}
              <Form.Row className="mb-2">
                {this._renderRow("Email", "email", this._renderTextInput, true, isThirdPartyLogin)}
                {this._renderRow("Password", "password", ({validationError, ...props}) => (
                  <Form.Control type="password" {...props} />
                ), true, isThirdPartyLogin)}
              </Form.Row>
              <Form.Row className="mt-4 mb-2 font-13">
                {this._renderRow(
                  "",
                  "termsAgreement",
                  ({ value, onChange, validationError, ...props }) => [
                    <Form.Check
                      key="1"
                      type="checkbox"
                      checked={!!value}
                      onChange={this._onTermsChange}
                      {...props}
                    />,
                    "I Agree With The Terms And Conditions",
                  ],
                  false
                )}
              </Form.Row>
            </Card.Body>
            <Card.Footer className="text-right">
              <Button
                className="marign-right-5"
                variant="danger"
                onClick={this._goHome}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={termsAgreement !== 2}
              >
                Sign Up
              </Button>
            </Card.Footer>
          </Card>
        </Form>
        <Modal centered show={termsAgreement === 1} onHide={this._onTermsHide}>
          <Modal.Header closeButton>
            <Modal.Title>Terms and Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body className="terms-body">
            <div
              dangerouslySetInnerHTML={{ __html: isEmployer ? terms.employerRegister : terms.recruiterRegister }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={this._onTermsHide}>
              Close
            </Button>
            <Button variant="primary" onClick={this._agreeToTerms}>
              I Agree
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default connect(
  (state) => ({
    signingUp: state.recruiters.signingUp,
    signUpErrors: state.recruiters.signUpErrors,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        initPage,
        signUpRecruiter,
        signUpEmployer,
      },
      dispatch
    )
)(SignUpPage);
