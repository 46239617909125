const cities = Object.freeze([
  { value: "A", text: "Self-employed" },
  { value: "B", text: "1-10 employees" },
  { value: "C", text: "11-50 employees" },
  { value: "D", text: "51-200 employees" },
  { value: "E", text: "201-500 employees" },
  { value: "F", text: "501-1000 employees" },
  { value: "G", text: "1001-5000 employees" },
  { value: "H", text: "5001-10,000 employees" },
  { value: "I", text: "10,001+ employees" }
]);

export default cities;
