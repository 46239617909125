import Pagination from "react-js-pagination"
import GridRow from "./GridRow";
import FilterBar from "./FilterBar";
import GridRowHeader from "./GridRowHeader";
import GridError from "./GridError";
import GridLoading from "./GridLoading";
import GridNoData from "./GridNoData";
import useCandidatesGrid from "../../../hooks/useCandidatesGrid";
import React from "react";
import Auth from "../../../utils/auth";

function CandidatesGrid({employer, job}) {
  const {
    candidatesForJob,
    queryParams,
    handleChangeQueryParams,
    handleShowFeedback,
    handleOpenResume,
    handleDownloadResume,
    handleRevertCandidate,
    handleRejectCandidateByEmployer,
    handleVerifyCandidate,
    handleAcceptCandidate,
    handleScheduleInterview,
    handleEnvelopeClick,
  } = useCandidatesGrid();

  const startIdx = (queryParams.offset * queryParams.limit) + 1;
  const endIdx = Math.min(startIdx + candidatesForJob.candidates.length - 1, candidatesForJob.totalCount);

  const renderBody = () => {
    const auth = Auth.getInstance();
    const scope = auth.getScope();

    if (candidatesForJob.findCandidatesError) {
      return <GridError error={candidatesForJob.findCandidatesError}/>
    }

    if (candidatesForJob.findingCandidates && !candidatesForJob.candidates.length) {
      return <GridLoading/>
    }

    if (candidatesForJob.candidates.length === 0) {
      return <GridNoData/>
    }

    return candidatesForJob.candidates.map((candidate) => {
      return (
        <GridRow
          key={candidate.candidateId}
          scope={scope}
          job={job}
          employer={employer}
          candidate={candidate}
          onShowFeedback={handleShowFeedback}
          onOpenResume={handleOpenResume}
          onDownloadResume={handleDownloadResume}
          onRevertCandidate={handleRevertCandidate}
          onRejectCandidateByEmployer={handleRejectCandidateByEmployer}
          onVerifyCandidate={handleVerifyCandidate}
          onAcceptCandidate={handleAcceptCandidate}
          onScheduleInterview={handleScheduleInterview}
          onEnvelopeClick={handleEnvelopeClick}
        />
      )
    });
  }

  return (
    <>
      <div className="ec-grid">
        <FilterBar
          title='Candidates Submitted'
          queryParams={queryParams}
          onChange={handleChangeQueryParams}
        />
        <div className="ec-grid-table-wrp">
          <div className="ec-grid-table">
            <GridRowHeader
              queryParams={queryParams}
              onChange={handleChangeQueryParams}
            />
            <div className="ec-grid-body">
              {renderBody()}
            </div>
          </div>
        </div>
        <div className="ec-grid-pagination pagination-grid">
          <div className="pagination-grid-total">
            {startIdx}–{endIdx} of {candidatesForJob.totalCount} posts
          </div>
          <Pagination
            activePage={queryParams.offset + 1}
            itemsCountPerPage={queryParams.limit}
            totalItemsCount={candidatesForJob.totalCount}
            pageRangeDisplayed={5}
            onChange={(page) => handleChangeQueryParams({offset: page - 1})}
          />
        </div>
      </div>
    </>
  )
}

export default CandidatesGrid;
