import React, { Component } from "react";
import { Button, Jumbotron } from "react-bootstrap";

export default class SignUpReferralPartnerSuccessPage extends Component {
  render() {
    return (
      <div>
        <Jumbotron>
          <h1>Almost there!</h1>
          <p className="my-4">
            First you have to go to your email inbox and click the activation link we sent you.
          </p>
          <p className="my-4">
            Then you'll need to wait for an administrator to approve your account.
            We'll notify you by email when this happen.&nbsp;
            <strong>Until then, you won't be able to login.</strong>
          </p>
          <p>
            <Button
              onClick={() => this.props.history.push(`/referralpartner`)}
              variant="primary"
            >
              Home page
            </Button>
          </p>
        </Jumbotron>
      </div>
    );
  }
}
