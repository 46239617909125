import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { findCodes, sendCode } from "../actions/codes";
import {
  Table,
  Col,
  Row,
  Container,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import { validateEmail } from "../utils/validators";
import Pagination from "react-js-pagination";

class ListOfCodes extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    codes: PropTypes.array.isRequired,
    totalCount: PropTypes.number,
  };
  timeout = null;
  state = {
    pageNr: 0,
    perPage: 25,
    search: "",
    showCodeModal: false,
    form: {
      emailAddress: "",
      isValidEmail: true,
      noOfJobs: 1,
      code: "",
    },
  };
  componentDidMount() {
    this.findCodes();
  }
  findCodes = async () => {
    const search = this.state.search;
    if (!search) {
      await this.props.findCodes({
        offset: this.state.pageNr,
        limit: this.state.perPage,
      });
    } else {
      await this.props.findCodes({
        offset: this.state.pageNr,
        limit: this.state.perPage,
        search: this.state.search,
      });
    }
  };
  pageChange = (pageNumber) => {
    const pageNr = pageNumber - 1;
    this.setState({ pageNr: pageNr }, () => {
      this.findCodes();
    });
  };
  handleEmailInputChange = (e) => {
    const value = e.target.value;
    this.setState((prevState) => {
      return {
        form: {
          ...prevState.form,
          emailAddress: value,
        },
      };
    });
  };
  handleNumberInputChange = (e) => {
    const value = e.target.value;
    this.setState((prevState) => {
      return {
        form: {
          ...prevState.form,
          noOfJobs: value,
        },
      };
    });
  };
  genCode = () => {
    const code = Math.random().toString(36).substring(3);
    return code;
  };
  submitCode = async () => {
    this.setState((prevState) => {
      return {
        form: {
          ...prevState.form,
          isValidEmail: true,
        },
      };
    });
    if (validateEmail(this.state.form.emailAddress)) {
      this.setState((prevState) => {
        return {
          form: {
            ...prevState.form,
            isValidEmail: false,
          },
        };
      });
      return;
    }
    await this.props.sendCode({
      ...this.state.form,
      noOfJobs: Number(this.state.form.noOfJobs),
    });
    this.hideCodeModal();
    this.findCodes();
  };
  showCodeModal = () => {
    this.setState({
      showCodeModal: true,
      form: {
        emailAddress: "",
        noOfJobs: 1,
        isValidEmail: true,
        code: this.genCode(),
      },
    });
  };
  hideCodeModal = () => {
    this.setState({
      showCodeModal: false,
    });
  };
  search = (e) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.setState(
      {
        search: e.target.value,
        pageNr: 0,
      },
      () =>
        (this.timeout = setTimeout(() => {
          this.findCodes();
        }, 900))
    );
  };
  render() {
    return (
      <>
        <Container fluid className="view-name-header">
          <Modal
            centered
            show={this.state.showCodeModal}
            onHide={this.hideCodeModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Send code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Row className="mb-2">
                <Col sm={6}>
                  <Col sm={12}>
                    <Form.Label>Code</Form.Label>
                  </Col>
                  <Col sm={12}>{this.state.form.code}</Col>
                </Col>
                <Col sm={6}>
                  <Col sm={12}>
                    <Form.Label>Email</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Control
                      type="text"
                      value={this.state.form.emailAddress}
                      onChange={this.handleEmailInputChange}
                      isInvalid={!this.state.form.isValidEmail}
                    />
                    <Form.Control.Feedback type="invalid">
                      {!this.state.form.isValidEmail ? "invalid email" : ""}
                    </Form.Control.Feedback>
                  </Col>
                </Col>
                <Col sm={12}>
                  <Col sm={12}>
                    <Form.Label>Number of jobs</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Control
                      type="text"
                      value={this.state.form.noOfJobs}
                      onChange={this.handleNumberInputChange}
                      isInvalid={this.state.form.noOfJobs < 1}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.form.noOfJobs < 1
                        ? "number of jobs must be greater then 0"
                        : ""}
                    </Form.Control.Feedback>
                  </Col>
                </Col>
              </Form.Row>
              <Form.Row className="mb-2"></Form.Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="marign-right-10"
                variant="danger"
                onClick={this.hideCodeModal}
              >
                Close
              </Button>
              <Button variant="primary" onClick={this.submitCode}>
                Send
              </Button>
            </Modal.Footer>
          </Modal>
          <Row>
            <Col sm="9" className="padding-none">
              <h4>Codes</h4>
            </Col>
            <Col sm="3" className="padding-none">
              <Button
                onClick={this.showCodeModal}
                className="pull-right"
                style={{ marginRight: "10px" }}
              >
                Send code
              </Button>
            </Col>
          </Row>
        </Container>
        <div className="table-wrapper">
          <Table>
            <thead>
              <tr>
                <th>Code</th>
                <th>Email</th>
                <th>Number of jobs</th>
                <th>Used</th>
              </tr>
            </thead>
            <tbody>
              {this.props.codes.map((code) => (
                <tr className="tr-main" key={code.code}>
                  <td>{code.code}</td>
                  <td>{code.emailAddress}</td>
                  <td>{code.noOfJobs}</td>
                  <td>{code.used ? "Yes" : "No"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Pagination
          activePage={this.state.pageNr + 1}
          itemsCountPerPage={this.state.perPage}
          totalItemsCount={this.props.totalCount}
          pageRangeDisplayed={5}
          onChange={this.pageChange}
        />
      </>
    );
  }
}

export default connect(
  (state) => ({
    codes: state.customCodes.codes,
    totalCount: state.customCodes.totalCount,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        findCodes,
        sendCode,
      },
      dispatch
    )
)(ListOfCodes);
