import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  findRetailTerms,
  createRetailTerm,
  editRetailTerm,
  deleteRetailTerm
} from "../actions/retailTerms";
import {
  Table,
  Col,
  Row,
  Container,
  Button,
  Modal,
  Form,
} from "react-bootstrap";

class ListOfRetailTerms extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    retailTerms: PropTypes.array.isRequired,
    editRetailTerm: PropTypes.func.isRequired,
    createRetailTerm: PropTypes.func.isRequired,
    findRetailTerms: PropTypes.func.isRequired,
    deleteRetailTerm: PropTypes.func.isRequired
  };
  timeout = null;
  state = {
    pageNr: 0,
    perPage: 10000,
    showAddModal: false,
    validationErrors: {},
    isPercent: false,
    editedRow: {},
  };
  changePercent = (e) => {
    const isChecked = e.target.checked;
    this.setState({
      isPercent: isChecked,
    });
  };
  componentDidMount() {
    this.props.findRetailTerms({
      offset: this.state.pageNr,
      limit: this.state.perPage,
    });
  }
  showAddModal = () => {
    this.setState({
      showAddModal: true,
      validationErrors: {},
      isPercent: false,
      editedRow: {},
    });
  };
  showEditModal = (row) => {
    this.setState({
      showAddModal: true,
      validationErrors: {},
      isPercent: false,
      editedRow: { ...row },
    });
  };
  hideAddModal = () => {
    this.setState({
      showAddModal: false,
    });
  };
  isFormValid = (elements) => {
    let errors = 0;
    for (const i of elements) {
      const name = i.name;
      const type = i.type;
      if (name && type !== "checkbox") {
        if (!i.value) {
          this.setState((prevState) => ({
            validationErrors: {
              ...prevState.validationErrors,
              [name]: true,
            },
          }));
          errors++;
        }
      }
    }
    return errors > 0 ? false : true;
  };
  createTerm = async (e) => {
    e.preventDefault();
    const data = {};
    this.setState({
      validationErrors: {},
    });
    if (this.isFormValid(e.target.elements)) {
      for (const i of e.target.elements) {
        const name = i.name;
        const type = i.type;
        if (name && type === "text") {
          if (!i.value) {
            this.setState((prevState) => ({
              validationErrors: {
                ...prevState.validationErrors,
                [name]: true,
              },
            }));
          }
          data[name] = i.value;
        }
        if (name && type === "number") {
          if (!i.value) {
            this.setState((prevState) => ({
              validationErrors: {
                ...prevState.validationErrors,
                [name]: true,
              },
            }));
          }
          data[name] = parseInt(i.value);
        }
        if (name && type === "checkbox") {
          data[name] = i.checked;
        }
      }
      await this.props.createRetailTerm({ ...data });
      this.hideAddModal();
      this.props.findRetailTerms({
        offset: this.state.pageNr,
        limit: this.state.perPage,
      });
    }
  };
  deleteTerm = async(retailTerm) => {

    await this.props.deleteRetailTerm({retailTermId: retailTerm.id});

    this.props.findRetailTerms({
      offset: this.state.pageNr,
      limit: this.state.perPage,
    });
  };
  editTerm = async (e) => {
    e.preventDefault();
    const data = {
      retailTermId: this.state.editedRow.id,
    };
    this.setState({
      validationErrors: {},
    });
    if (this.isFormValid(e.target.elements)) {
      for (const i of e.target.elements) {
        const name = i.name;
        const type = i.type;
        if (name && type === "text") {
          if (!i.value) {
            this.setState((prevState) => ({
              validationErrors: {
                ...prevState.validationErrors,
                [name]: true,
              },
            }));
          }
          data[name] = i.value;
        }
        if (name && type === "number") {
          if (!i.value) {
            this.setState((prevState) => ({
              validationErrors: {
                ...prevState.validationErrors,
                [name]: true,
              },
            }));
          }
          data[name] = parseInt(i.value);
        }
        if (name && type === "checkbox") {
          data[name] = i.checked;
        }
      }
      await this.props.editRetailTerm({ ...data });
      this.hideAddModal();
      this.props.findRetailTerms({
        offset: this.state.pageNr,
        limit: this.state.perPage,
      });
    }
  };
  search = (e) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.setState(
      {
        search: e.target.value,
      },
      () =>
        (this.timeout = setTimeout(() => {
          this.props.findRetailTerms({
            offset: this.state.pageNr,
            limit: this.state.perPage,
          });
        }, 900))
    );
  };
  render() {
    const { editedRow } = this.state;
    return (
      <>
        <Container fluid className="view-name-header">
          <Modal
            centered
            show={this.state.showAddModal}
            onHide={this.hideAddModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Retail Term</Modal.Title>
            </Modal.Header>
            <Form onSubmit={!editedRow.id ? this.createTerm : this.editTerm}>
              <Modal.Body>
                <Form.Row className="mb-2">
                  <Col sm={12}>
                    <Form.Label>Name</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Control
                      defaultValue={editedRow.name || ""}
                      name="name"
                      type="text"
                      isInvalid={this.state.validationErrors.name}
                    />
                  </Col>
                </Form.Row>
                <Form.Row className="mb-2">
                  <Col sm={12}>
                    <Form.Label>Is percent</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Control
                      defaultChecked={editedRow.isPercent || false}
                      name="isPercent"
                      type="checkbox"
                      onChange={this.changePercent}
                    />
                  </Col>
                </Form.Row>
                <Form.Row className="mb-2">
                  <Col sm={12}>
                    <Form.Label>Recruiter Value</Form.Label>
                  </Col>
                  <Col sm={11}>
                    <Form.Control
                      name="value"
                      type="number"
                      defaultValue={editedRow.value || ""}
                      isInvalid={this.state.validationErrors.value}
                    />
                  </Col>
                  <Col style={{ paddingTop: "6px" }} sm={1}>
                    {this.state.isPercent ? "%" : "$"}
                  </Col>
                </Form.Row>
                <Form.Row className="mb-2">
                  <Col sm={12}>
                    <Form.Label>Referral Value</Form.Label>
                  </Col>
                  <Col sm={11}>
                    <Form.Control
                        name="referValue"
                        type="number"
                        defaultValue={editedRow.referValue || ""}
                        isInvalid={this.state.validationErrors.referValue}
                    />
                  </Col>
                  <Col style={{ paddingTop: "6px" }} sm={1}>
                    {this.state.isPercent ? "%" : "$"}
                  </Col>
                </Form.Row>
                <Form.Row className="mb-2">
                  <Col sm={12}>
                    <Form.Label>Is default</Form.Label>
                  </Col>
                  <Col sm={12}>
                    <Form.Control
                      disabled={editedRow.id ? true : false}
                      defaultChecked={editedRow.isDefault || false}
                      name="isDefault"
                      type="checkbox"
                    />
                  </Col>
                </Form.Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="marign-right-10"
                  variant="danger"
                  onClick={this.hideAddModal}
                >
                  Close
                </Button>
                <Button variant="primary" type="submit">
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <Row>
            <Col sm="9" className="padding-none">
              <h4>Recruiter Terms</h4>
            </Col>
            <Col sm="3" className="padding-none">
              <Button
                onClick={this.showAddModal}
                className="pull-right"
                style={{ marginRight: "10px" }}
              >
                Add Term
              </Button>
            </Col>
          </Row>
        </Container>
        <div className="table-wrapper">
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Recruiter Value</th>
                <th>Referral Value</th>
                <th>isDefault</th>
                <th className="actions-transparent">Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.props.retailTerms.map((retailTerm) => (
                <tr className="tr-main" key={retailTerm.id}>
                  <td>{retailTerm.name}</td>
                  <td>{`${retailTerm.value}${
                    retailTerm.isPercent ? "%" : "$"
                  }`}</td>
                  <td>{`${retailTerm.referValue}${
                      retailTerm.isPercent ? "%" : "$"
                  }`}</td>
                  <td>{retailTerm.isDefault ? "Yes" : "No"}</td>
                  <td>
                    <Button
                      className="ml-2"
                      onClick={() => this.showEditModal(retailTerm)}
                    >
                      Edit
                    </Button>
                    <Button
                        className="ml-2"
                        onClick={async (e)=>{
                            e.preventDefault();
                            await this.deleteTerm(retailTerm);
                          }
                        }
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    retailTerms: state.retailTerms.retailTerms,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        findRetailTerms,
        createRetailTerm,
        editRetailTerm,
        deleteRetailTerm
      },
      dispatch
    )
)(ListOfRetailTerms);
