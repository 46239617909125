import ColumnSorter from "../../../utils/ColumnSorter";
import React from "react";

const GridRowHeader = ({queryParams, onChange}) => {
  return (
    <div className='ec-grid-row-header ec-grid-row'>
      <div className="ec-grid-col ec-grid-col-name">
        Candidate
        <ColumnSorter
          field="firstName"
          orders={queryParams.orders}
          onChange={(orders) => onChange({orders})}
          className="ml-1" role="button"
        />
      </div>
      <div className="ec-grid-col ec-grid-col-date">
        Submitted
        <ColumnSorter
          field="postDate"
          orders={queryParams.orders}
          onChange={(orders) => onChange({orders})}
          className="ml-1" role="button"
        />
      </div>
      <div className="ec-grid-col ec-grid-col-resume">Resume</div>
      <div className="ec-grid-col ec-grid-col-status">Status</div>
      <div className="ec-grid-col ec-grid-col-salary">
        Salary Range
        <ColumnSorter
          field="minSalary"
          orders={queryParams.orders}
          onChange={(orders) => onChange({orders})}
          className="ml-1" role="button"
        />
      </div>
      <div className="ec-grid-col ec-grid-col-envelope"></div>
      <div className="ec-grid-col ec-grid-col-score">Score</div>
    </div>
  )
}

export default GridRowHeader;
