import React, {Component} from "react";
import {Badge, Button, Col, Form, FormControl, InputGroup, ListGroup, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
  getConversations,
  getMessages,
  handleConversationUpdate,
  handleNewMessage,
  readConversation,
  sendMessage
} from "../actions/conversation";
import auth from "./../utils/auth";
import * as signalR from '@microsoft/signalr';
import Autosuggest from 'react-autosuggest';
import axios from "axios";

const logError = (err) => console.error(err);

class ChatPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,

    conversations: PropTypes.array.isRequired,
    messages: PropTypes.array.isRequired,

    getConversations: PropTypes.func.isRequired,
    getMessages: PropTypes.func.isRequired,
    sendMessage: PropTypes.func.isRequired,
    handleNewMessage: PropTypes.func.isRequired,
    handleConversationUpdate: PropTypes.func.isRequired,
    readConversation: PropTypes.func.isRequired,
  };

  /**
   * @type {Auth}
   * @private
   */
  _authInstance = null;
  _hubConnection = null;

  constructor(props) {
    super(props);
    
    this._authInstance = auth.getInstance();
    this._hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(
        window.location.port
          ? 'http://localhost:5000/chatservice' // "http://www.rnr/chatservice"
          : (process.env.PUBLIC_URL + "/chatservice"),
        {accessTokenFactory: () => this._authInstance.getAccessToken()}
      )
      .configureLogging(signalR.LogLevel.Critical)
      .withAutomaticReconnect()
      .build();
    
    this._scrollToBottomOfTheChat = this._scrollToBottomOfTheChat.bind(this);
    console.log(JSON.stringify(process.env.PUBLIC_URL));

    this.state = {
      message: "",
      forwardingMessageId: "",
      newContact: "",
      suggestions: [],
      canForwardMessage: false,
      term: "",
      lastCreatedConversation: "",
    };

    this._hubConnection.onreconnected(() => {
      this._subscribeToConversation(this.props.currentConversationId);
      this._connectToConversationUpdater();
    });

    this._hubConnection.on('SendToConversation', (messageId, senderId, content) => {
      this.props.handleNewMessage({senderId, messageId, content})
        .then()
        .then(this._scrollToBottomOfTheChat);
    });

    this._hubConnection.on('ConversationLastMessageDateChanged', (conversationId, changedDate) => {
      const userId = this._authInstance.getUserId();
      const p = Promise.resolve();
      if (!this.props.conversations.find(x => x.conversationId === conversationId)) {
        p.then(this._getConversations);
      }
      p.then(() => this.props.handleConversationUpdate({conversationId, changedDate, userId}));
    });
  }

  async componentDidMount() {
    await this._hubConnection
      .start()
      .catch(err => console.log('Error while establishing connection :('));

    await this._getConversations();

    await this._selectConversation(this.props.match.params.conversationId ||
      this.props.currentConversationId ||
      (this.props.conversations[0] ? this.props.conversations[0].conversationId : null)
    );
    await this._connectToConversationUpdater();
  }

  async componentWillUnmount() {
    await this._hubConnection.stop();
  }
  
  _getConversations = () => this.props.getConversations({term: this.state.term});

  _handleChange = (event) => this.setState({message: event.target.value});

  _onChange = (event, {newValue}) => this.setState({newContact: newValue});

  _onTermChange = (event) => this.setState({term: event.target.value}, this._getConversations);

  _onSuggestionsFetchRequested = ({value}) => 
    axios.get(`${process.env.PUBLIC_URL}/api/v1/conversations/contacts`, {
      params: {term: value},
      headers: {
        Authorization: "Bearer " + this._authInstance.getAccessToken()
      }
    }).then(x => this.setState({
      suggestions: x.data
    }));

  _onSuggestionSelected = (suggestion) =>
    axios.post(`${process.env.PUBLIC_URL}/api/v1/conversations/createConversation`, {
      ownerId: suggestion.contactId
    }, {
      headers: {
        Authorization: "Bearer " + this._authInstance.getAccessToken()
      }
    }).then(x =>
      this.setState({newContact: '', lastCreatedConversation: x.data.conversationId}, () =>
        this._selectConversation(x.data.conversationId)));

  render() {
    return (
      <Row className="justify-content-md-center">
        <Col xs={2} sm={2} md={3} lg={4} xl={3} style={{overflowY: 'scroll', height: 'calc(100vh - 156px)'}}>
          <InputGroup style={{marginTop: "10px", marginBottom: "10px", display: "sticky"}}>
            <FormControl type="text" style={{display: "inline"}} placeholder="Search using job name..."
                         value={this.state.term} onChange={this._onTermChange}/>
            {this._authInstance ? this._isAdmin() ? (
              <InputGroup.Append>
                <Button size="sm"
                        onClick={() => this.setState({showModal: true}, () => this.contactNamePicker.input.focus())}
                        style={{display: "inline"}}
                        className="material-icons">
                  person_add</Button></InputGroup.Append>) : null : null}
          </InputGroup>
          <ListGroup>
            {this.props.conversations && this.props.conversations.length > 0 ? this.props.conversations.map(x => (
              this._renderConversation(x.conversationId, x)
            )) :       <ListGroup.Item action={false}>
              {
                <div className={"small"}>
                  No conversations found
              </div>}
            </ListGroup.Item>
            }
          </ListGroup>
        </Col>
        <Col xs={10} sm={10} md={9} lg={8} xl={9} style={{position: 'relative'}}>
          {this.state.showModal && this._authInstance && this._isAdmin() ? (
              <div style={{marginTop: "10px"}}><Autosuggest
                ref={(el) => this.contactNamePicker = el}
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={this._onSuggestionsFetchRequested}
                onSuggestionsClearRequested={() => this.setState({suggestions: []})}
                getSuggestionValue={(x) => x.contactId}
                renderSuggestion={(x) => (<div style={{cursor: "pointer"}}>{x.firstName + " " + x.lastName}</div>)}
                onSuggestionSelected={(e, {suggestion}) => this._onSuggestionSelected(suggestion)}
                inputProps={
                  {
                    placeholder: 'Enter contact name...',
                    value: this.state.newContact,
                    onChange: this._onChange
                  }
                }
                theme={{
                  container: 'react-autosuggest__container',
                  containerOpen: 'react-autosuggest__container--open',
                  input: 'react-autosuggest__input, form-control',
                  inputOpen: 'react-autosuggest__input--open',
                  inputFocused: 'react-autosuggest__input--focused',
                  suggestionsContainer: 'react-autosuggest__suggestions-container',
                  suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
                  suggestionsList: 'react-autosuggest__suggestions-list',
                  suggestion: 'react-autosuggest__suggestion list-group-item',
                  suggestionFirst: 'react-autosuggest__suggestion--first',
                  suggestionHighlighted: 'react-autosuggest__suggestion--highlighted active',
                  sectionContainer: 'react-autosuggest__section-container',
                  sectionContainerFirst: 'react-autosuggest__section-container--first',
                  sectionTitle: 'react-autosuggest__section-title'
                }}
              /></div>) :
            <div style={{
              overflowY: 'scroll',
              height: `calc(100vh - ${this.state.forwardingMessageId ? '216px' : '196px'})`,
              padding: "10px",
              marginBottom: "10px"
            }}
                 ref={(el) => this.messagesContainer = el}>
              {this.state.showModal ? null : this.props.messages.length > 0 ? this.props.messages.map(x => (<div key={x.messageId} style={{
                width: "100%",
                clear: "both"
              }}> {x.senderId === this._authInstance.getUserId() ? (
                <div className="text-right" style={{
                  maxWidth: '70%',
                  float: "right",
                  backgroundColor: "#6e7686",
                  color: "white",
                  padding: "10px",
                  marginTop: "1px",
                  marginBottom: "1px",
                  borderRadius: "20px"
                }}>
                  {x.content}
                </div>
              ) : (
                <div style={{display: "flex", alignItems: "center"}}>
                  <div className="text-left" style={{
                    maxWidth: '70%',
                    float: "left",
                    backgroundColor: "#f0f1f2",
                    padding: "10px",
                    marginTop: "1px",
                    marginBottom: "1px",
                    marginRight: "5px",
                    borderRadius: "20px"
                  }}>
                    {x.content}
                  </div>
                  {this.state.canForwardMessage ? (
                    <Badge className="material-icons" style={{fontSize: "16px", cursor: "pointer", backgroundColor: "#7f9e8d"}}
                           variant="primary"
                           onClick={() => this._forwardMessage(x.messageId)}>reply_all</Badge>) : null}
                </div>
              )}</div>)) : <div key="emptyConversation" className="text-muted text-center">{this.props.currentConversationId ? "There are no messages in this conversation": "Select a conversation from the list" }</div>}
            </div>}

          <div style={{
            position: 'absolute',
            bottom: '0',
            width: '100%',
            right: '0',
            paddingLeft: '20px',
            paddingRight: '20px',
            height: this.state.forwardingMessageId ? '66px' : '40px'
          }}>
            <Form.Text
              className="text-muted">{this.state.forwardingMessageId ? "Forwarding message..." : null}</Form.Text>
            <FormControl type="text" name="message" placeholder="Enter your message here..."
                         style={{width: "100%"}}
                         disabled={!this.props.currentConversationId}
                         value={this.state.message} onChange={this._handleChange}
                         onFocus={this._onMessageTextInputFocus}
                         onKeyDown={(e) => e.key === "Enter" ? this._sendMessage() : false}
                         ref={(el) => this.messageInput = el}/>
          </div>
        </Col>
      </Row>
    );
  }

  _renderConversation(key, x) {
    if (x.isEmpty && key !== this.state.lastCreatedConversation) return null;
    const readBy = this._authInstance.getUserId() === x.ownerId ? 'readByOwner' : 'readByOther';
    return (
      <ListGroup.Item active={this.props.currentConversationId === key} action={true} key={key}
                      onClick={() => this._selectConversation(key)}
                      className={x[readBy] ? '' : 'font-weight-bold'}>
        {
          this._isAdmin()
            ? (
              <div>
                <div className={this.props.currentConversationId !== key ? "text-muted small" : "small"}>
                  <div>{(x.ownerType === 0 ? "Employer" : "Recruiter")}</div>
                  <div>{x.otherPartyId ? x.conversationName : x.ownerCompanyName}</div>
                </div>
                <div>{x.ownerFirstName + " " + x.ownerLastName}</div>
              </div>
            ) : x.conversationName
        }
      </ListGroup.Item>
    )
  }

  _scrollToBottomOfTheChat(instant) {
    try {
      this.messagesContainer.children[this.messagesContainer.childNodes.length - 1]
        .scrollIntoView(instant ? true : {behavior: "smooth"});
    } catch {
      // oh well
    }
  }

  _subscribeToConversation = (conversationId) =>
    this._hubConnection.invoke("SubscribeToConversation", conversationId)
      .catch(logError);

  _unsubscribeFromConversation = (conversationId) =>
    this._hubConnection.invoke("UnsubscribeFromConversation", conversationId)
      .catch(logError);

  async _connectToConversationUpdater(ownerId) {
    let conversationsToSubscribe = [];
    if (ownerId) {
      conversationsToSubscribe.push(ownerId);
    } else {
      conversationsToSubscribe.push(this._authInstance.getUserId());
      if (this._isAdmin()) {
        conversationsToSubscribe.push(this.props.conversations.map(x => x.ownerId).filter((v, i, a) => a.indexOf(v) === i));
      }
      conversationsToSubscribe = conversationsToSubscribe.flat(1);
    }
    for (const ownerId of conversationsToSubscribe) {
      await this._hubConnection.invoke("SubscribeToConversationDateChanges", ownerId)
        .catch(logError);
    }
  }

  async _forwardMessage(messageId) {
    const message = this.props.messages.find(x => x.messageId === messageId);
    const sourceConversation = this.props.conversations.find(x => x.conversationId === message.conversationId);
    let destinationConversation = this.props.conversations.find(x => x.ownerId === sourceConversation.otherPartyId && x.jobId === sourceConversation.jobId);
    if (!destinationConversation) {
      const result = await axios.post(`${process.env.PUBLIC_URL}/api/v1/conversations/createConversation`, {
        ownerId: sourceConversation.otherPartyId,
        otherPartyId: sourceConversation.ownerId,
        jobId: sourceConversation.jobId
      }, {
        headers: {
          Authorization: "Bearer " + this._authInstance.getAccessToken()
        }
      });
      destinationConversation = {conversationId: result.data.conversationId};
      await this._connectToConversationUpdater(sourceConversation.otherPartyId);
    }
    await this._selectConversation(destinationConversation.conversationId);

    this.setState({
      message: message.content,
      forwardingMessageId: messageId
    });
  }

  async _sendMessage() {
    await this.props.sendMessage({
      content: this.state.message,
      conversationId: this.props.currentConversationId,
      sourceMessageId: this.state.forwardingMessageId !== "" ? this.state.forwardingMessageId : undefined,
    });
    this.setState({message: "", forwardingMessageId: ""});
  }

  async _selectConversation(conversationId) {
    if (!conversationId) return;

    let conversation = this.props.conversations.find(x => x.conversationId === conversationId);
    if (!conversation) {
      await this._getConversations();
      conversation = this.props.conversations.find(x => x.conversationId === conversationId);
      if (!conversation) {
        console.error("Trying to access conversation that doesn't exists");
        return;
      }
    }

    await this.setState({
      message: "",
      forwardingMessageId: "",
      canForwardMessage: this._isAdmin() &&
        typeof conversation.otherPartyId !== "undefined" &&
        conversation.otherPartyId !== null &&
        conversation.otherPartyId !== this._authInstance.getUserId(),
      showModal: false,
      newContact: '',
      term: ''
    });

    await this._unsubscribeFromConversation(this.props.currentConversationId);
    this.props.history.push(`/chat/${conversationId}`);
    await this.props.getMessages({conversationId});
    this._scrollToBottomOfTheChat(true);
    this.messageInput.focus();
    await this._subscribeToConversation(conversationId);
  }
  
  _isAdmin = () => this._authInstance.getScope() === "admin";

  _onMessageTextInputFocus = () => {
    const {currentConversationId: conversationId} = this.props;
    const conversation = this.props.conversations.find(c => c.conversationId === conversationId);
    const readBy = conversation.ownerId === this._authInstance.getUserId() ? 'readByOwner' : 'readByOther';
    if (conversation && conversation[readBy]) return;
    this.props.readConversation({conversationId});
  }
}

export default connect(
  state => ({
    conversations: state.conversations.conversations,
    messages: state.conversations.messages,
    currentConversationId: state.conversations.currentConversationId
  }),
  dispatch =>
    bindActionCreators(
      {
        getConversations: getConversations,
        getMessages: getMessages,
        sendMessage: sendMessage,
        handleNewMessage: handleNewMessage,
        handleConversationUpdate: handleConversationUpdate,
        readConversation: readConversation,
      },
      dispatch
    )
)(ChatPage);
