import { find, command } from "../utils/api";

const CODES_ACTION_URL = `${process.env.PUBLIC_URL}/api/v1/customCodes`;

export const FIND_CODES = "FIND_CODES";
export const FIND_CODES_COMPLETED = "FIND_CODES_COMPLETED";
export const FIND_CODES_FAILED = "FIND_CODES_FAILED";

export const findCodes = find(
  CODES_ACTION_URL + "/find",
  FIND_CODES,
  FIND_CODES_COMPLETED,
  FIND_CODES_FAILED
);

export const SEND_CODE = "SEND_CODE";
export const SEND_CODE_COMPLETED = "SEND_CODE_COMPLETED";
export const SEND_CODE_FAILED = "SEND_CODE_FAILED";

export const sendCode = command(
  CODES_ACTION_URL + "/createCustomCode",
  SEND_CODE,
  SEND_CODE_COMPLETED,
  SEND_CODE_FAILED
);
