import { Button, Card, Col, Form } from "react-bootstrap";
import {
  isStateRequired,
} from "../../../utils/validators";
import React, {useState} from "react";
import states from "../../../data/states";
import {Link, useParams} from "react-router-dom";
import Autocomplete from "react-autocomplete";
import {updateEmployer} from "./api";
import {toast} from "react-toastify";
import {options, transformFormToApiRequest, validators} from "./utils";
import useEmployerForm from "./hooks/useEmployerForm";
import useCities from "./hooks/useCities";

const autoCompleteMenuStyle = {
  position: 'fixed',
  zIndex: 1000,
  border: 'solid 1px #777777',
  padding: '5px 10px',
  background: 'white',
  overflow: 'auto'
}

const Profile = () => {
  const {id} = useParams()
  const [loading, setLoading] = useState(false);

  const {form, setForm} = useEmployerForm({id});
  const [validationErrors, setValidationErrors] = useState({});

  const {cities, setCities} = useCities({state: form.state});

  const renderSectionHeader = (title) => {
    return (
      <Form.Row className="mb-2">
        <Col>
          <h5>{title}</h5>
        </Col>
      </Form.Row>
    );
  }

  const validateField = (validator) => (e) => {
    const {name, value} = e.target;
    const error = validator(value);

    setValidationErrors(prevErrors => {
      const newErrors = {...prevErrors};

      if(error) {
        newErrors[name] = error;
      } else {
        delete newErrors[name];
      }

      return newErrors;
    })
  }

  const renderTextInput = ({validationError, ...props}) => {
    return <Form.Control type="text" {...props} />;
  }

  const renderDropdown = ({validationError, ...props}) => {
    const dropdownOptions = {
      ...options,
      state: form.country
        ? states.filter(x => x.country === form.country)
        : states,
      city: cities
    };
    return (
      <Form.Control as="select" {...props}>
        <option />
        {dropdownOptions[props.name].map(({ value, text }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </Form.Control>
    );
  }

  const onChange = (e) => {
    // fix crash for onchange event
    // https://legacy.reactjs.org/docs/legacy-event-pooling.html
    e.persist?.();
    let extra = {};
    if (e.target.name === "state") {
      extra = { city: "" };
    } else if (e.target.name === "country") {
      extra = { state: "", city: "" };
      setCities([]);
    }
    setForm((prevForm) => ({
      ...prevForm,
      [e.target.name]: e.target.value,
      ...extra,
    }));
  };

  const renderRow = (label, fieldName, fieldRender, required = true) => {
    const isRequired = required;
    const labelProps = { sm: 12 };
    const fieldProps = { sm: 12 };
    const validationError = validationErrors[fieldName];
    const isInvalid = !!validationError;
    const validator = validators[fieldName];
    const onBlur = validator ? validateField(validator) : undefined;
    return (
      <Col sm={6}>
        {label ? (
          <Col {...labelProps}>
            <Form.Label>
              {label}{" "}
              {isRequired ? <span className="required-field">*</span> : <sub>(Optional)</sub>}
            </Form.Label>
          </Col>
        ) : null}
        <Col {...fieldProps}>
          {fieldRender({
            name: fieldName,
            value: form[fieldName],
            onChange,
            isInvalid,
            onBlur,
            validationError,
          })}
          <Form.Control.Feedback type="invalid">
            {validationError}
          </Form.Control.Feedback>
        </Col>
      </Col>
    );
  }

  const renderAutoComplete = (props) => {
    return (
      <Autocomplete
        getItemValue={item => item.value}
        items={cities.filter(x => x.text.startsWith(form.city))}
        renderItem={(item, isHighlighted) =>
          <div key={item.value} style={{background: isHighlighted ? 'lightgray' : 'white'}}>
            {item.text}
          </div>
        }
        renderInput={inputProps =>
          <>
            <Form.Control
              {...inputProps}
              name={props.name}
              type="text" autoComplete="new-password" isInvalid={props.isInvalid}
              onBlur={e => {
                e.persist()
                props.onBlur(e)
                inputProps.onBlur(e)
              }}
            />
            <Form.Control.Feedback type="invalid">
              {props.validationError}
            </Form.Control.Feedback>
          </>
        }
        renderMenu={(items, value, style) =>
          <div style={{ ...style, ...autoCompleteMenuStyle, maxHeight: `calc(100vmin - ${style.top}px)` }}
               children={items} />
        }
        value={form.city}
        onChange={props.onChange}
        onSelect={value =>
          props.onChange({target: {name: props.name, value}})
        }
        wrapperStyle={{
          display: 'block'
        }}
      />
    )
  }

  const validateForm = (form) => {
    const validationErrors = {};
    let errors = 0;

    for(const field of form) {
      const validator = validators[field.name];
      const error = validator && validator(field.value, form.state);
      if (error) {
        errors++;
        validationErrors[field.name] = error;
      }
    }

    if (errors) {
      setValidationErrors(validationErrors);
    }

    return errors === 0;
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validateForm(e.target)) {
      setLoading(true);
      try {
        const data = transformFormToApiRequest(form);
        await updateEmployer(id, data);
        toast.success("Profile updated successfully.");
      } catch (e) {
        console.error(`[onSubmit]: ${e.message}`, e);
        toast.error("You cannot update your profile at this time. Please try again later.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Form onSubmit={onSubmit}>
        <Card style={{ width: "52rem", margin: "50px auto" }}>
          <Card.Header>
            Profile
          </Card.Header>
          <Card.Body>
            {renderSectionHeader("Personal Information")}
            <Form.Row className="mb-2">
              {renderRow(
                "First Name",
                "firstName",
                renderTextInput
              )}
              {renderRow(
                "Last Name",
                "lastName",
                renderTextInput
              )}
            </Form.Row>
            <Form.Row className="mb-2">
              {renderRow(
                "Phone Number",
                "phoneNumber",
                renderTextInput
              )}
              {renderRow(
                "Job Title",
                "jobTitle",
                renderTextInput
              )}
            </Form.Row>
            <hr />
            {renderSectionHeader("Company Information")}
            <Form.Row className="mb-2">
              {renderRow(
                "Company Name",
                "companyName",
                renderTextInput
              )}
              {renderRow(
                "Job Function",
                "jobFunction",
                renderDropdown
              )}
            </Form.Row>
            <Form.Row className="mb-2">
              {renderRow("Country", "country", renderDropdown)}
              {renderRow("Province/State", "state", renderDropdown, isStateRequired(form.country))}
            </Form.Row>
            <Form.Row className="mb-2">
              {renderRow("City", "city", renderAutoComplete)}
              {renderRow(
                "Postal/Zip Code",
                "postalCode",
                renderTextInput
              )}
            </Form.Row>
            <Form.Row className="mb-2">
              {renderRow(
                "Company Address",
                "street1",
                renderTextInput
              )}
              {renderRow(
                "Company Address continuation...",
                "street2",
                renderTextInput,
                false
              )}
            </Form.Row>
          </Card.Body>
          <Card.Footer className="text-right">
            <Link to='/'>
              <Button
                className="marign-right-5"
                variant="danger"
              >
                Cancel
              </Button>
            </Link>
            <Button
              variant="primary"
              type="submit"
              disabled={loading}
            >
              Update
            </Button>
          </Card.Footer>
        </Card>
      </Form>
    </>
  )
}

export default Profile;
