import {useEffect, useState} from "react";
import {fetchCities} from "../api";
import {transformToCities} from "../utils";

const useCities = ({state}) => {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    async function fetchCitiesForCountry(state) {
      const { data = [] } = await fetchCities(state);
      const newCities = transformToCities(data);

      setCities(newCities);
    }

    if (state) {
      fetchCitiesForCountry(state);
    }
  }, [state]);

  return {
    cities,
    setCities
  };
}

export default useCities
