import {Col, Container, Row} from "react-bootstrap";
import PostNewJobCard from "../../common/Cards/PostNewJobCard";
import StartAIAssessmentCard from "../../common/Cards/StartAIAssessmentCard";
import TermsAndConditionsCard from "../../common/Cards/TermAndConditionsCard";
import QuestionsCard from "../../common/Cards/QuestionsCard";
import JobsGrid from "./JobsGrid";
import AiAssessmentModal from "../../common/AiAssessmentModal";
import useInitPage from "../../../hooks/useInitPage";
import RemoveJobPostModal from "../../common/RemoveJobPostModal";

function JobsPage() {
  // init state when page load
  useInitPage('JobsPage');

  return <>
    <Container fluid className='jobs-page'>
      <Row>
        <Col sm={3}>
          <div className='jobs-sidebar'>
            <PostNewJobCard />
            <StartAIAssessmentCard />
            <TermsAndConditionsCard />
            <QuestionsCard />
          </div>
        </Col>
        <Col sm={9}>
          <JobsGrid title='All Jobs Posted' />
        </Col>
      </Row>
    </Container>
    <AiAssessmentModal />
    <RemoveJobPostModal />
  </>
}

export default JobsPage;
