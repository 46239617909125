import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  findRecruiters,
  approveRecruiter,
  inviteRecruiter,
  createRecruiter,
  clearInvitationErrors,
  updateRecruiterAdminNotes,
} from "../actions/recruiters";
import {
  Alert,
  Table,
  FormControl,
  Col,
  Button,
  Row,
  Container,
  Modal,
  Form,
  Tooltip,
  OverlayTrigger,
  ButtonGroup, ToggleButton,
} from 'react-bootstrap';
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import axios from 'axios'
import {
  validateEmail,
  validateName,
  validatePresent,
  validatePostalCode,
  validateState,
} from "../utils/validators";
import countries from "../data/countries";
import states from "../data/states";
import sizesOfCompany from "../data/sizesOfCompany";
import Autocomplete from 'react-autocomplete'
import { Editor } from "@tinymce/tinymce-react";

const autoCompleteMenuStyle = {
  position: 'fixed',
  zIndex: 1000,
  border: 'solid 1px #777777',
  padding: '5px 10px',
  background: 'white',
  overflow: 'auto'
}

const popperConfig = {modifiers:{preventOverflow:{boundariesElement:'window'}}};

const openRowStyle = {
  width: "20px",
  textAlign: "center",
  marginRight: "5px",
};

const validators = {
  firstName: validateName,
  lastName: validateName,
  phoneNumber: validatePresent,
  companyName: validateName,
  sizeOfCompany: validatePresent,
  street: validatePresent,
  city: validatePresent,
  state: validateState,
  country: validatePresent,
  postalCode: validatePostalCode,
  email: validateEmail,
};

class ListOfRecruiters extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,

    recruiters: PropTypes.array.isRequired,

    findRecruiter: PropTypes.func.isRequired,
    approveRecruiter: PropTypes.func.isRequired,
    inviteRecruiter: PropTypes.func.isRequired,
    clearInvitationErrors: PropTypes.func.isRequired,
    createRecruiter: PropTypes.func.isRequired,
    totalCount: PropTypes.number,
  };
  timeout = null;
  state = {
    cities: [],
    pageNr: 0,
    perPage: 25,
    search: "",
    order: undefined,
    showInvitationModal: false,
    invitationEmail: "",
    showCreateModal: false,
    newRecruiter: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      linkedinProfileUrl: "",
      companyName: "",
      street: "",
      sizeOfCompany: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
      paypalAccountEmail: "",
      email: "",
      isReferrer: false,
    },
    validationErrors: {},
    openedRow: '',
    updateAdminNotesRecruiter: null,
  };
  options = {
    state: states,
    country: countries,
    sizeOfCompany: sizesOfCompany,
  };

  pageChange = (pageNumber) => {
    const pageNr = pageNumber - 1;
    this.setState({ pageNr: pageNr }, () => {
      this.findRecruiters();
    });
  };
  sort = (orders) => {
    this.setState({ order: orders}, () => {
      this.findRecruiters();
    });
  };

  _onChange = (e) => {
    const name = e.target.name;
    const val = e.target.value;
    let extra = {}, cb = function() {}
    if (name === "country") {
      extra = { city: '', state: '', cities: [] }
    } else if (name === "state") {
      extra = { city: '' }
      cb = this._fetchCities
    }
    this.setState((prevState) => ({
      newRecruiter: {
        ...prevState.newRecruiter,
        [name]: val,
        ...extra
      },
    }), cb);
  };
  _fetchCities = async(e) => {
    const {data} = await axios.get(
      `${process.env.PUBLIC_URL}/api/v1/data/cities`,
      {
        params: {state: this.state.newRecruiter.state}
      }
    );
    this.setState({
      cities: data.map(x => ({
        value: x.name, //DO not use id
        text: x.name
      }))
    })
  }
  findRecruiters = async () => {
    const search = this.state.search;
    if (!search) {
      await this.props.findRecruiter({
        offset: this.state.pageNr,
        limit: this.state.perPage,
        orders: this.state.order
      });
    } else {
      await this.props.findRecruiter({
        offset: this.state.pageNr,
        limit: this.state.perPage,
        search: this.state.search,
        orders: this.state.order
      });
    }
  };
  _validateField = (validator) => (e) =>
    this.setState({
      validationErrors: {
        ...this.state.validationErrors,
        [e.target.name]: validator(e.target.value, this.state.newRecruiter),
      },
    });
  componentDidMount() {
    this.findRecruiters();
  }
  showInvitationModal = () => {
    this.props.clearInvitationErrors();
    this.setState({
      showInvitationModal: true,
      invitationEmail: "",
    });
  };
  hideInvitationModal = () => {
    this.setState({
      showInvitationModal: false,
      invitationEmail: "",
    });
  };
  showCreateModal = () => {
    this.props.clearInvitationErrors();
    this.setState({
      showCreateModal: true,
      newRecruiter: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        linkedinProfileUrl: "",
        companyName: "",
        street: "",
        sizeOfCompany: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        paypalAccountEmail: "",
        email: "",
        isReferrer: this.state.newRecruiter.isReferrer
      },
      validationErrors: {},
    });
  };
  hideCreateModal = () => {
    this.setState({
      showCreateModal: false,
      newRecruiter: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        linkedinProfileUrl: "",
        companyName: "",
        street: "",
        sizeOfCompany: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        paypalAccountEmail: "",
        email: "",
        isReferrer: this.state.newRecruiter.isReferrer
      },
      validationErrors: {},
    });
  };
  changeInvEmail = (e) => {
    const value = e.target.value;
    this.setState({
      invitationEmail: value,
    });
  };
  inviteRecruiter = async () => {
    try {
      await this.props.inviteRecruiter({ email: this.state.invitationEmail });
      this.hideInvitationModal();
    } catch (e) {
      console.log(this.props.inivationErrors);
    }
  };
  approveRecruiter = async ({ recruiterId }) => {
    try {
      await this.props.approveRecruiter({ recruiterId });
    } catch (e) {
      console.error(e);
      toast.error(this.props.approveRecruiterError);
    }
  };
  search = (e) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.setState(
      {
        search: e.target.value,
        pageNr: 0,
        orders: '',
      },
      () =>
        (this.timeout = setTimeout(() => {
          this.findRecruiters();
        }, 900))
    );
  };
  _renderSectionHeader(title, optional = false) {
    return (
      <Form.Row className="mb-2">
        <Col>
          <h5>{title}{optional ? <sub> (Optional)</sub> : ''}</h5>
        </Col>
      </Form.Row>
    );
  }
  _renderRow(label, fieldName, fieldRender, required = false) {
    const isRequired = required;
    const validationError = this.state.validationErrors[fieldName];
    const isInvalid = !!validationError;
    const validator = validators[fieldName];
    const onBlur = validator ? this._validateField(validator) : undefined;
    return (
      <Col sm={6}>
        <div>
          <Form.Label>
            {label}
            {isRequired ? <span className="required-field">*</span> : null}
          </Form.Label>
        </div>
        <div>
          {fieldRender({
            name: fieldName,
            value: this.state.newRecruiter[fieldName],
            onChange: this._onChange,
            isInvalid,
            onBlur,
            validationError,
          })}
          <Form.Control.Feedback type="invalid">
            {validationError}
          </Form.Control.Feedback>
        </div>
      </Col>
    );
  }
  createRecruiter = async () => {
    // console.log(this.state.newRecruiter)
    if (this._validateForm()) {
      await this.props.createRecruiter({
        ...this.state.newRecruiter,
        companyAddress: {
          street: this.state.newRecruiter.street,
          city: this.state.newRecruiter.city,
          state: this.state.newRecruiter.state,
          country: this.state.newRecruiter.country,
          postalCode: this.state.newRecruiter.postalCode,
        },
      });
      this.hideCreateModal();
    }
  };
  _renderTextInput = ({validationError, ...props}) => <Form.Control type="text" {...props} />;

  _renderDropdown = ({validationError, ...props}) => {
    const options = {
      ...this.options,
      state: this.state.newRecruiter.country
        ? states.filter(x => x.country === this.state.newRecruiter.country)
        : states,
      city: this.state.cities,
    };
    return (
      <Form.Control as="select" {...props}>
        <option />
        {options[props.name].map(({ value, text }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </Form.Control>
    );
  };

  _renderAutocomplete = (props) => {
    //TODO adapt this to be used by more than one field
    return (
      <Autocomplete
        getItemValue={item => item.value}
        items={this.state.newRecruiter.city
          ? this.state.cities.filter(x => x.text.startsWith(this.state.newRecruiter.city))
          : this.state.cities
        }
        renderItem={(item, isHighlighted) =>
          <div key={item.value} style={{background: isHighlighted ? 'lightgray' : 'white'}}>
            {item.text}
          </div>
        }
        renderInput={inputProps =>
          <>
            <Form.Control {...inputProps}
                          name={props.name}
                          type="text" autoComplete="new-password" isInvalid={props.isInvalid}
                          onBlur={e => {
                            e.persist()
                            props.onBlur(e)
                            inputProps.onBlur(e)
                          }} />
            <Form.Control.Feedback type="invalid">
              {props.validationError}
            </Form.Control.Feedback>
          </>
        }
        renderMenu={(items, value, style) =>
          <div style={{ ...style, ...autoCompleteMenuStyle, maxHeight: `calc(100vmin - ${style.top}px)` }}
               children={items}
          />
        }
        value={this.state.newRecruiter.city}
        onChange={props.onChange}
        onSelect={value =>
          props.onChange({target: {name: props.name, value}})
        }
        wrapperStyle={{
          display: 'block'
        }}
      />
    )
  }

  _renderFormError() {
    if (!this.state.validationErrors["$form"]) return null;
    return (
      <Alert variant="danger">{this.state.validationErrors["$form"]}</Alert>
    );
  }
  _validateForm = () => {
    const validationErrors = {};
    let errors = 0;
    for (let i = 0; i < Object.keys(this.state.newRecruiter).length; i++) {
      const fieldName = Object.keys(this.state.newRecruiter)[i];
      const field = this.state.newRecruiter[fieldName];
      const validator = validators[fieldName];
      const error = validator && validator(field, this.state.newRecruiter);
      if (error) errors++;
      validationErrors[fieldName] = error;
    }
    if (errors) this.setState({ validationErrors });
    return errors === 0;
  };

  render() {
    const { approvingRecruiter, recruiters } = this.props;
    return (
      <>
        <Modal
          centered
          show={this.state.showInvitationModal}
          onHide={this.hideInvitationModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Invite recruiter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label>Recruiter email</Form.Label>
            <Form.Control
              type="text"
              onChange={this.changeInvEmail}
              value={this.state.invitationEmail}
              isInvalid={this.props.inivationErrors.length}
            />
            <Form.Control.Feedback type="invalid">
              {this.props.inivationErrors.length &&
                this.props.inivationErrors[0].message}
            </Form.Control.Feedback>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="marign-right-10"
              variant="danger"
              onClick={this.hideInvitationModal}
            >
              Close
            </Button>
            <Button variant="primary" onClick={this.inviteRecruiter}>
              Send
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          size="lg"
          centered
          show={this.state.showCreateModal}
          onHide={this.hideCreateModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create recruiter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {this._renderFormError()}
              <ButtonGroup toggle className="mb-4">
                <ToggleButton
                  size="lg"
                  type="radio"
                  variant="secondary"
                  name="radio"
                  value="recruiter"
                  checked={!this.state.isReferrer}
                  onClick={() => this.setState({ isReferrer: false })}
                >
                  Recruiter
                </ToggleButton>
                <ToggleButton
                  size="lg"
                  type="radio"
                  variant="secondary"
                  name="radio"
                  value="referralpartner"
                  checked={this.state.isReferrer}
                  onClick={() => this.setState({ isReferrer: true })}
                >
                  Referral Partner
                </ToggleButton>
              </ButtonGroup>
              <Form.Row className="mb-2">
                {this._renderRow("First Name", "firstName", this._renderTextInput, true)}
                {this._renderRow("Last Name", "lastName", this._renderTextInput, true)}
              </Form.Row>
              <Form.Row className="mb-2">
                {this._renderRow("Phone Number", "phoneNumber", this._renderTextInput, true)}
                {this._renderRow("Linkedin Profile", "linkedinProfileUrl", this._renderTextInput)}
              </Form.Row>
              <hr />
              {this._renderSectionHeader("Company Information", true)}
              <Form.Row className="mb-2">
                {this._renderRow("Company Name", "companyName", this._renderTextInput)}
                {this._renderRow("Size Of Company", "sizeOfCompany", this._renderDropdown)}
              </Form.Row>
              <Form.Row className="mb-2">
                {this._renderRow("Country", "country", this._renderDropdown)}
                {this._renderRow("Province/State", "state", this._renderDropdown)}
              </Form.Row>
              <Form.Row className="mb-2">
                {this._renderRow("City", "city", this._renderAutocomplete)}
                {this._renderRow("Company Address", "street", this._renderTextInput)}
              </Form.Row>
              <Form.Row className="mb-2">
                {this._renderRow("Paypal Account Email", "paypalAccountEmail", this._renderTextInput)}
                {this._renderRow("Postal/Zip Code", "postalCode", this._renderTextInput)}
              </Form.Row>
              <hr />
              {this._renderSectionHeader("Login Details")}
              <Form.Row className="mb-2">
                {this._renderRow("Email", "email", this._renderTextInput, true)}
                {this._renderRow("Password", "password", (props) => "Password will be generated")}
              </Form.Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="marign-right-10"
              variant="danger"
              onClick={this.hideCreateModal}
            >
              Close
            </Button>
            <Button variant="primary" onClick={this.createRecruiter}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        {this._renderAdminNotesModal()}
        <Container fluid className="view-name-header">
          <Row>
            <Col sm="5" className="padding-none">
              <h4>Recruiters</h4>
            </Col>
            <Col sm="4" className="padding-none">
              <Button
                onClick={this.showCreateModal}
                className="pull-right"
                style={{ marginRight: "10px" }}
              >
                Create recruiter
              </Button>
              <Button
                onClick={this.showInvitationModal}
                className="pull-right"
                style={{ marginRight: "10px" }}
              >
                Invite recruiter
              </Button>
            </Col>
            <Col sm="3" className="padding-none">
              <FormControl
                type="text"
                placeholder="Search"
                value={this.state.search}
                onChange={this.search}
              />
            </Col>
          </Row>
        </Container>
        <div className="table-wrapper">
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Surname</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Verified</th>
                <th>Approved</th>
                <th className="actions-transparent">Actions</th>
              </tr>
            </thead>
            <tbody>
              {recruiters.map((recruiter) => (
                <React.Fragment key={recruiter.recruiterId}>
                  <tr className="tr-main">
                    {
                      recruiter.adminNotes
                        ? (
                          <td className="hovering-td" onClick={() => this._toggleOpenRow(recruiter)}>
                            <div style={openRowStyle}>
                              {this.state.openedRow === recruiter.recruiterId ? (
                                <i className="material-icons">arrow_drop_up</i>
                              ) : (
                                <i className="material-icons">arrow_drop_down</i>
                              )}
                            </div>
                            {recruiter.firstName}
                          </td>
                        ) : (
                          <td>{recruiter.firstName}</td>
                        )
                    }
                    <td>{recruiter.lastName}</td>
                    <td>{recruiter.email}</td>
                    <td>{recruiter.phoneNumber}</td>
                    <td>{recruiter.verified ? "Yes" : "No"}</td>
                    <td>{recruiter.approved ? "Yes" : "No"}</td>
                    <td>
                      <ButtonGroup className="btn-group-actions">
                        {recruiter.verified && !recruiter.approved ? (
                          <Button
                            disabled={approvingRecruiter}
                            className="ml-2"
                            onClick={() =>
                              this.approveRecruiter({
                                recruiterId: recruiter.recruiterId,
                              })
                            }
                          >
                            Approve
                          </Button>
                        ) : null}
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={<Tooltip>Edit notes</Tooltip>}
                          popperConfig={popperConfig}
                        >
                          <Button
                            size="sm"
                            variant="primary"
                            onClick={(e) =>
                              this.setState({updateAdminNotesRecruiter: recruiter})
                            }
                            className="material-icons"
                          >
                            note
                          </Button>
                        </OverlayTrigger>
                      </ButtonGroup>
                    </td>
                  </tr>
                  {this._renderAdminNotes(recruiter)}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
        <Pagination
          activePage={this.state.pageNr + 1}
          itemsCountPerPage={this.state.perPage}
          totalItemsCount={this.props.totalCount}
          pageRangeDisplayed={5}
          onChange={this.pageChange}
        />
      </>
    );
  }

  _toggleOpenRow = ({recruiterId}) =>
    this.setState((state) => ({
      ...state,
      openedRow: state.openedRow === recruiterId ? '' : recruiterId,
    }));

  _renderAdminNotes({recruiterId, adminNotes}) {
    if (recruiterId !== this.state.openedRow) return null;
    return (
      <tr className="p-3" dangerouslySetInnerHTML={{__html: adminNotes}}>
      </tr>
    );
  }

  _hideAdminNotesModal = () => this.setState({updateAdminNotesRecruiter: null});

  _updateAdminNotes = async() => {
    try {
      const { recruiterId, adminNotes: notes } = this.state.updateAdminNotesRecruiter;
      await this.props.updateRecruiterAdminNotes({ recruiterId, notes });
    } catch (e) {
      //
    }
    this.setState({updateAdminNotesRecruiter: null});
    await this.findRecruiters();
  };

  _renderAdminNotesModal() {
    const {updateAdminNotesRecruiter} = this.state;
    return (
      <Modal
        centered
        size="lg"
        show={updateAdminNotesRecruiter !== null}
        onHide={this._hideAdminNotesModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Recruiter Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Editor
              id="recruiter-admin-notes-editor"
              init={{
                skin_url: "https://unpkg.com/tinymce@5.0.3/skins/ui/oxide",
                content_css: "https://unpkg.com/tinymce@5.0.3/skins/content/default/content.css",
                height: 300,
              }}
              initialValue={(updateAdminNotesRecruiter && updateAdminNotesRecruiter.adminNotes) || ''}
              onEditorChange={(content) => this.setState(state => ({
                ...state,
                updateAdminNotesRecruiter: {
                  ...state.updateAdminNotesRecruiter,
                  adminNotes: content,
                }
              }))}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="marign-right-10"
            variant="danger"
            onClick={this._hideAdminNotesModal}
          >
            Close
          </Button>
          <Button variant="primary" onClick={this._updateAdminNotes}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default connect(
  (state) => ({
    recruiters: state.recruiters.recruiters,
    approveRecruiterError: state.recruiters.approveRecruiterError,
    approvingRecruiter: state.recruiters.approvingRecruiter,
    inivationErrors: state.recruiters.inivationErrors,
    totalCount: state.recruiters.totalCount,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        findRecruiter: findRecruiters,
        approveRecruiter: approveRecruiter,
        inviteRecruiter: inviteRecruiter,
        clearInvitationErrors: clearInvitationErrors,
        createRecruiter: createRecruiter,
        updateRecruiterAdminNotes,
      },
      dispatch
    )
)(ListOfRecruiters);
