import {
  ACCEPT_CANDIDATE_BY_ADMIN,
  ACCEPT_CANDIDATE_BY_ADMIN_COMPLETED,
  ACCEPT_CANDIDATE_BY_ADMIN_FAILED,
  CHANGE_CANDIDATE_JOB_POSITION_BY_ADMIN,
  CHANGE_CANDIDATE_JOB_POSITION_BY_ADMIN_COMPLETED, CHANGE_CANDIDATE_JOB_POSITION_BY_ADMIN_FAILED,
  FIND_CANDIDATES_TO_ACCEPT,
  FIND_CANDIDATES_TO_ACCEPT_COMPLETED,
  FIND_CANDIDATES_TO_ACCEPT_FAILED,
  REJECT_CANDIDATE_BY_ADMIN,
  REJECT_CANDIDATE_BY_ADMIN_COMPLETED,
  REJECT_CANDIDATE_BY_ADMIN_FAILED,
} from "../actions/candidatesForAdmin";
import { PAGE_INIT } from "../actions/pages";

export const defaultState = Object.freeze({
  findingCandidates: false,
  findCandidatesError: "",
  candidates: [],
  makingDecisionAboutCandidate: false,
  totalCount: 0,
});

const candidatesForAdminReducer = (state = defaultState, action) => {
  switch (action.type) {
    case PAGE_INIT: {
      const { candidates } = state;
      return { ...defaultState, candidates };
    }
    case FIND_CANDIDATES_TO_ACCEPT: {
      return {
        ...state,
        findingCandidates: true,
        findCandidatesError: "",
      };
    }
    case FIND_CANDIDATES_TO_ACCEPT_COMPLETED: {
      return {
        ...state,
        findingCandidates: false,
        candidates: action.payload.results,
        totalCount: action.payload.total,
      };
    }
    case FIND_CANDIDATES_TO_ACCEPT_FAILED: {
      return {
        ...state,
        findingCandidates: false,
        findCandidatesError: action.payload.message,
      };
    }
    case ACCEPT_CANDIDATE_BY_ADMIN: {
      return {
        ...state,
        makingDecisionAboutCandidate: true,
        findCandidatesError: "",
      };
    }
    case ACCEPT_CANDIDATE_BY_ADMIN_COMPLETED: {
      return {
        ...state,
        makingDecisionAboutCandidate: false,
        candidates: state.candidates.filter(
          (x) => x.candidateId !== action.payload.candidateId
        ),
      };
    }
    case ACCEPT_CANDIDATE_BY_ADMIN_FAILED: {
      return {
        ...state,
        makingDecisionAboutCandidate: false,
        findCandidatesError: action.payload.message,
      };
    }
    case REJECT_CANDIDATE_BY_ADMIN: {
      return {
        ...state,
        makingDecisionAboutCandidate: true,
        findCandidatesError: "",
      };
    }
    case REJECT_CANDIDATE_BY_ADMIN_COMPLETED: {
      return {
        ...state,
        makingDecisionAboutCandidate: false,
        candidates: state.candidates.filter(
          (x) => x.candidateId !== action.payload.candidateId
        ),
      };
    }
    case REJECT_CANDIDATE_BY_ADMIN_FAILED: {
      return {
        ...state,
        makingDecisionAboutCandidate: false,
        findCandidatesError: action.payload.message,
      };
    }
    case CHANGE_CANDIDATE_JOB_POSITION_BY_ADMIN: {
      return {
        ...state,
        makingDecisionAboutCandidate: true,
        findCandidatesError: "",
      };
    }
    case CHANGE_CANDIDATE_JOB_POSITION_BY_ADMIN_COMPLETED: {
      return {
        ...state,
        makingDecisionAboutCandidate: false,
        candidates: state.candidates.map(
            (x) => {
              if(x.candidateId === action.payload.candidateId) {
                return {...x, ...action.payload}
              } else {
                return x;
              }
            }
        ),
      };
    }
    case CHANGE_CANDIDATE_JOB_POSITION_BY_ADMIN_FAILED: {
      return {
        ...state,
        makingDecisionAboutCandidate: false,
        findCandidatesError: action.payload.message,
      };
    }
    default:
      return state;
  }
};

export default candidatesForAdminReducer;
