import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  acceptCandidateByAdmin,
  findCandidatesToAccept,
  rejectCandidateByAdmin,
  changeCandidateJobPositionByAdmin
} from "../actions/candidatesForAdmin";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Form,
} from "react-bootstrap";
import countries from "../data/countries";
import states from "../data/states";
import moment from "moment";
import axios from "axios";
import auth from "../utils/auth";
import { 
  downloadResume, 
  returnTextForArrayValue,
  openResumeInTab,
} from "../utils/commons";
import jobFunctions from "../data/jobFunctions";
import industries from "../data/industries";
import jobTypes from "../data/jobTypes";
import Pagination from "react-js-pagination";
import ColumnSorter from "../utils/ColumnSorter";

import CandidateAssessmentModal from './common/CandidateAssessmentModal';

const Status = Object.freeze({
  AwaitingVerification: 0,
  Accepted: 1,
  Rejected: 2,
  PendingEmailVerification: 3,
});

function statusFromPath(path) {
  if (!path) return 0;
  return parseInt(path.split('/')[2], 10) || 0;
}

class ListOfCandidatesForAdmin extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    candidates: PropTypes.array.isRequired,
    findCandidatesToAccept: PropTypes.func.isRequired,
    acceptCandidateByAdmin: PropTypes.func.isRequired,
    rejectCandidateByAdmin: PropTypes.func.isRequired,
    changeCandidateJobPositionByAdmin: PropTypes.func.isRequired,
    totalCount: PropTypes.number,
  };

  state = {
    job: null,
    recruiter: null,
    status: statusFromPath(this.props.location.pathname),
    pageNr: 0,
    perPage: Math.ceil((document.body.clientHeight / 112) - 2),
    openedRow: null,
    rejectCandidate: null,
    rejectReason: '',
    search: '',
    candidateForJobPositionChange: null,
    availableJobsForCandidateJobChange: [],
    order: undefined,
    resumeAssessmentCandidate: null,
  };

  _searchTimeout = null;

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const pathname = this.props.location.pathname;
    if (prevProps.location.pathname !== pathname) {
      this.setState({
        status: statusFromPath(pathname),
        pageNr: 0,
        orders: this.state.order
      }, () => this.loadData());
    }
  }

  pageChange = (pageNumber) => {
    const pageNr = pageNumber - 1;
    this.setState({ pageNr: pageNr }, () => {
      this.loadData();
    });
  };

  columnSort = (pageNumber, currentOrders) => {
    const pageNr = pageNumber - 1;
    this.setState({ pageNr: pageNr, orders: currentOrders }, () => {
      this.loadData();
    });
  };

  acceptOrRejectCandidate = async (e, id, decision, reason) => {
    if (e) e.stopPropagation();
    if (decision) {
      await this.props.acceptCandidateByAdmin({ candidateId: id });
    } else {
      await this.props.rejectCandidateByAdmin({ candidateId: id, reason });
    }
  };

  switchFilter(status) {
    this.props.history.replace('/candidates/' + status);
  }

  sort = (orders) => {
    this.setState({ order: orders}, () => {
      this.loadData();
    });
  };

  loadData() {
    const { status, pageNr: offset, perPage: limit, search, order } = this.state;
    this.props.findCandidatesToAccept({ status, offset, limit, search, orders: order });
  }

  async fetchJobDetails(jobId, recruiterId) {
    const job = await axios.get(`${process.env.PUBLIC_URL}/api/v1/jobs/find`, {
      params: { jobId },
      headers: {
        Authorization: "Bearer " + auth.getInstance().getAccessToken(),
      },
    });

    const recruiter = await axios.get(
      `${process.env.PUBLIC_URL}/api/v1/recruiters/find`,
      {
        params: { recruiterId },
        headers: {
          Authorization: "Bearer " + auth.getInstance().getAccessToken(),
        },
      }
    );

    this.setState({
      job: job.data[0],
      recruiter: recruiter.data.results[0],
    });
  }

  async revertCandidateStatus(id, recruiterId, reason) {
    await axios.post(`${process.env.PUBLIC_URL}/api/v1/candidates/revertCandidateStatus`, {
      candidateId: id,
      recruiterId,
      reason
    }, {
      headers: {
        Authorization: "Bearer " + auth.getInstance().getAccessToken(),
      }
    });
    this.loadData();
  }

  async fetchAvailableJobsForCandidateJobChange() {
    const availableJobs = await axios.get(`${process.env.PUBLIC_URL}/api/v1/jobs/getAvailableJobsForCandidateJobChange`, {
      headers: {
        Authorization: "Bearer " + auth.getInstance().getAccessToken(),
      },
    });
    this.setState({
      availableJobsForCandidateJobChange: availableJobs.data,
    });
  }

  clearJobDetails() {
    this.setState({ job: null });
  }

  renderJobDetailsRow = (label, value) => {
    return (
      <p className="job-details-row" key={label}>
        <span className="span-label">{label}</span>: {value}
      </p>
    );
  };

  renderCandidateDetails = (candidate) => {
    return (
      <Row style={{ width: "100%", marginLeft: "auto", marginRight: "auto", cursor: "pointer" }}>
        <Col xs={6}>
          <h4>Notes:</h4>
          <b>Recruiter:</b>
          <div className="wrap-text">{candidate.recruiterNote}</div>
          <br />
          <b>Candidate:</b>
          <div className="wrap-text">{candidate.candidateNote}</div>
          <br />
        </Col>
        <Col xs={6}>
          <h4>Profile:</h4>
          <b>CPA Designated:</b> {candidate.highestCollegeDegree}
          <br />
          <b>Most Recent Job Title:</b> {candidate.mostRecentJobTitle}
          <br />
          <b>Current Employer:</b> {candidate.currentEmployer}
          <br />
          <b>LinkedIn Profile:</b> {candidate.linkedInProfile}
          <br />
        </Col>
      </Row>
    )
  }

  openRow = (e, id) => this.setState({openedRow: id})
  closeRow = (e) => this.setState({openedRow: null})

  openRejectCandidate = (e, candidate) => this.setState({rejectCandidate: candidate})
  closeRejectCandidate = () => this.setState({rejectCandidate: null, rejectReason: ''})
  rejectCandidate = async(e, { candidateId, jobId }, reason) => {
    e && e.stopPropagation();
    await this.props.rejectCandidateByAdmin({ candidateId, jobId, reason });
    this.closeRejectCandidate();
    this.switchFilter(Status.AwaitingVerification);
  }
  handleRejectReasonChange = e => this.setState({rejectReason: e.target.value});

  openCandidateChangeJobPosition = (e, candidate) => this.setState({candidateForJobPositionChange: candidate})
  closeCandidateChangeJobPosition = () => this.setState({candidateForJobPositionChange: null, candidateJobPositionInfo: null})
  changeCandidateJobPosition = async(e, { candidateId, jobId, jobTitle, companyName }) => {
    e && e.stopPropagation();
    await this.props.changeCandidateJobPositionByAdmin({ candidateId, jobId, jobTitle, companyName });
    this.closeCandidateChangeJobPosition();
  }
  handleJobPositionDropDownChange = e => {
    const candidateJobPositionInfo = JSON.parse(e.target.value);
    this.setState({candidateJobPositionInfo});
  }

  onSearchChange = e => {
    if (this._searchTimeout) clearTimeout(this._searchTimeout);
    this._searchTimeout = setTimeout(() => {
      this._searchTimeout = null;
      this.loadData();
    }, 900);
    this.setState({ search: e.target.value, pageNr: 0 });
  }

  openResumeAssessment = (e, candidate) => {
    // console.log(`[openResumeAssessment]: candidate:`, candidate);
    this.setState({ resumeAssessmentCandidate: candidate });
  }

  clearResumeAssessment = () => {
    this.setState({ resumeAssessmentCandidate: null });
  }

  render() {
    const jobDetails = this.state.job ? this.state.job : null;
    const recruiterDetails = this.state.recruiter || {};
    return (
      <>
        <Container fluid className="view-name-header">
          <Row>
            <Col sm="12" className="padding-none">
              <h4 style={{ display: "inline" }}>Candidates</h4>
              <div className={"float-right"}>
                <Button
                  active={this.state.status === Status.AwaitingVerification}
                  onClick={() => this.switchFilter(Status.AwaitingVerification)}
                  style={{borderRadius: "30px 0px 0px 30px"}}
                >
                  Awaiting verification
                </Button>
                <Button
                  active={this.state.status === Status.Accepted}
                  onClick={() => this.switchFilter(Status.Accepted)}
                  style={{borderRadius: "0px"}}
                >
                  Verified
                </Button>
                <Button
                  active={this.state.status === Status.Rejected}
                  onClick={() => this.switchFilter(Status.Rejected)}
                  style={{borderRadius: "0px"}}
                >
                  Rejected
                </Button>
                <Button
                  active={this.state.status === Status.PendingEmailVerification}
                  onClick={() => this.switchFilter(Status.PendingEmailVerification)}
                  style={{borderRadius: "0px 30px 30px 0px"}}
                >
                  Pending permission
                </Button>
              </div>
              <div className={"float-right marign-right-5"}>
                <Form.Control type="text"
                              placeholder="Search"
                              value={this.state.search}
                              onChange={this.onSearchChange}
                />
              </div>
            </Col>
          </Row>
        </Container>
        <div className="table-wrapper">
          <Table>
            <thead>
              <tr>
                <th>Full Name</th>
                <th>Contact</th>
                <th>Location</th>
                <th>Expected Salary</th>
                <th>Job Title</th>
                <th>
                  Submitted At
                  <ColumnSorter
                      field="submittedAt" orders={this.state.order} onChange={(orders) => this.sort(orders)}
                      className="ml-1" role="button"
                  />
                </th>
                <th>Recruiter</th>
                {this.state.status >= Status.Accepted && this.state.status <= Status.Rejected ? (
                  <th className="td-short">
                    {this.state.status === Status.Accepted ? "Accepted at" : "Rejected at"}
                  </th>
                ) : null}
                <th className="actions-transparent">Actions</th>
              </tr>
            </thead>
            <tbody>
              {!this.props.candidates || this.props.candidates.length === 0 ? (
                <tr key="noCandidatesKey">
                  <td colSpan={7} className="text-center">
                    <small style={{ marginLeft: "auto", marginRight: "auto" }}>
                      No candidates with specified filter
                    </small>
                  </td>
                </tr>
              ) : (
                this.props.candidates.map((candidate) => (
                  <React.Fragment key={candidate.candidateId}>
                    <tr className="tr-main">
                      <td onClick={
                        this.state.openedRow !== candidate.candidateId
                          ? (e) => this.openRow(e, candidate.candidateId)
                          : this.closeRow
                      }>
                        <div
                          style={{
                            width: "20px",
                            textAlign: "center",
                            marginRight: "5px",
                          }}
                        >
                          {this.state.openedRow === candidate.candidateId ? (
                            <i className="material-icons">arrow_drop_up</i>
                          ) : (
                            <i className="material-icons">arrow_drop_down</i>
                          )}
                        </div>
                        {candidate.firstName} {candidate.lastName}
                      </td>
                      <td>
                        <div>
                          <b>Email:</b> <a href={'mailto:'+candidate.email}>{candidate.email}</a><br/>
                          <b>Phone:</b> {candidate.phone}
                        </div>
                      </td>
                      <td>
                        {candidate.city}, 
                        <br/>
                        {candidate.province && states.find((x) => x.value === candidate.province).text},
                        <br/>
                        {countries.find((x) => x.value === candidate.country).text}
                        <br/>
                      </td>
                      <td>
                        {candidate.minSalary} - {candidate.maxSalary}
                      </td>
                      <td>
                        {candidate.jobTitle}
                      </td>
                      <td>
                        {moment(candidate.submittedAt).fromNow()}
                      </td>
                      <td>
                        {candidate.recruiter &&
                        <div>
                          <b>Name:</b> {candidate.recruiter.name}<br/>
                          <b>Email:</b> <a href={'mailto:'+candidate.recruiter.email}>{candidate.recruiter.email}</a><br/>
                          <b>Phone:</b> {candidate.recruiter.phone}
                        </div>
                        }
                      </td>
                      {candidate.actionDate ? (
                        <td className="td-short">
                          {moment(candidate.actionDate).fromNow()}
                        </td>
                      ) : null}
                      <td className="text-center">
                        <ButtonGroup className="btn-group-actions">
                          {(this.state.status === Status.AwaitingVerification  
                          || this.state.status === Status.PendingEmailVerification) ? (
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={<Tooltip>Accept this candidate</Tooltip>}
                            >
                              <Button
                                size="sm"
                                variant="primary"
                                onClick={(e) =>
                                  this.acceptOrRejectCandidate(
                                    e,
                                    candidate.candidateId,
                                    true
                                  )
                                }
                                className="material-icons"
                              >
                                check
                              </Button>
                            </OverlayTrigger>
                          ) : null}
                          {(this.state.status === Status.AwaitingVerification 
                          || this.state.status === Status.PendingEmailVerification) ? (
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={<Tooltip>Reject this candidate</Tooltip>}
                            >
                              <Button
                                size="sm"
                                variant="danger"
                                onClick={e => this.openRejectCandidate(e, candidate)}
                                className="material-icons"
                              >
                                cancel
                              </Button>
                            </OverlayTrigger>
                          ) : null}
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>Show job details</Tooltip>}
                          >
                            <Button
                              size="sm"
                              variant="light"
                              onClick={(e) =>
                                this.fetchJobDetails(
                                  candidate.jobId,
                                  candidate.recruiterId
                                )
                              }
                              className="material-icons"
                            >
                              search
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                              placement="auto"
                              delay={{ show: 250, hide: 400 }}
                              overlay={<Tooltip>Change job position</Tooltip>}
                              top
                          >
                            <Button
                                size="sm"
                                variant="primary"
                                onClick={(e) =>
                                  this.fetchAvailableJobsForCandidateJobChange().then(r => this.openCandidateChangeJobPosition(e, candidate))
                                }
                                className="material-icons"
                            >
                              edit
                            </Button>
                          </OverlayTrigger>
                          {candidate.resume ? (
                            <>
                              <OverlayTrigger
                                placement="auto"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip>View Resume</Tooltip>}
                                top
                              >
                                <Button
                                  size="sm"
                                  variant="primary"
                                  onClick={(e) => openResumeInTab(e, candidate)}
                                  className="material-icons"
                                >
                                  launch
                                </Button>
                              </OverlayTrigger>

                              <OverlayTrigger
                                placement="auto"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip>Download resume</Tooltip>}
                                top
                              >
                                <Button
                                  size="sm"
                                  variant="primary"
                                  onClick={(e) =>
                                    downloadResume(e, candidate)
                                  }
                                  className="material-icons"
                                >
                                  attachment
                                </Button>
                              </OverlayTrigger>
                            </>
                          ) : null}{" "}
                          <OverlayTrigger
                            placement="auto"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>Check Resume Assessment</Tooltip>}
                            top
                          >
                            <Button
                              size="sm"
                              variant="light"
                              onClick={(e) => this.openResumeAssessment(e, candidate)}
                              className="material-icons"
                            >
                              equalizer
                            </Button>
                          </OverlayTrigger>
                          {candidate.status === 1 || candidate.status === 2 ? (
                              <OverlayTrigger
                                  placement="auto"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={<Tooltip>{candidate.status === 1 ? "Revert Accept" : "Revert Reject"}</Tooltip>}
                                  top
                              >
                                <Button
                                    size="sm"
                                    variant={candidate.status === 1 ? "danger" : "primary"}
                                    onClick={(e) =>
                                        this.revertCandidateStatus(
                                            candidate.candidateId,
                                            candidate.recruiterId,
                                            candidate.status === 1 ? "Rejected by Client after submission" : "Reconsidering Candidate"
                                        )
                                    }
                                    className="material-icons"
                                >
                                  {candidate.status === 1 ? "close" : "done"}

                                </Button>
                              </OverlayTrigger>
                          ) : null}{" "}
                        </ButtonGroup>
                      </td>
                    </tr>
                    {this.state.openedRow === candidate.candidateId ? (
                      <tr>
                        <td className="candidate-td">
                          {this.renderCandidateDetails(candidate)}
                        </td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                ))
              )}
            </tbody>
          </Table>
        </div>
        <Button size="sm" variant="primary" style={{float: 'right'}} onClick={this._exportAsCsv}>Export CSV</Button>
        <Pagination
          activePage={this.state.pageNr + 1}
          itemsCountPerPage={this.state.perPage}
          totalItemsCount={this.props.totalCount}
          pageRangeDisplayed={5}
          onChange={this.pageChange}
        />
        <Modal
          centered
          show={this.state.job !== null}
          onHide={() => this.clearJobDetails()}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {`Job details - 
              ${jobDetails ? jobDetails.jobTitle : ""}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {jobDetails ? (
              <>
                <h5 className="h5-job-details">Job Details</h5>
                {this.renderJobDetailsRow(
                  "Job Function",
                  returnTextForArrayValue(jobFunctions, jobDetails.jobFunction)
                )}
                {this.renderJobDetailsRow(
                  "Industry",
                  returnTextForArrayValue(industries, jobDetails.industry)
                )}
                {this.renderJobDetailsRow(
                  "Company Name",
                  jobDetails.companyName
                )}
                {this.renderJobDetailsRow(
                  "Company Description",
                  jobDetails.companyDescription
                )}
                {this.renderJobDetailsRow(
                  "Reporting To",
                  jobDetails.reportingTo
                )}
                {this.renderJobDetailsRow(
                  "Team Size To Manage",
                  jobDetails.teamSizeToManage
                )}
                <hr></hr>
                <h5 className="h5-job-details">Job Location</h5>
                {this.renderJobDetailsRow(
                  "Country",
                  returnTextForArrayValue(countries, jobDetails.country)
                )}
                {this.renderJobDetailsRow(
                  "Province/State",
                  returnTextForArrayValue(states, jobDetails.state)
                )}
                {this.renderJobDetailsRow(
                  "City",
                  jobDetails.city
                )}
                {this.renderJobDetailsRow(
                  "Location Type",
                  jobDetails.locationType
                )}
                {this.renderJobDetailsRow(
                  "Other Location",
                  jobDetails.otherLocation
                )}
                {this.renderJobDetailsRow(
                  "Candidate Relocation",
                  jobDetails.candidateRelocation
                )}
                <hr></hr>
                <h5 className="h5-job-details">Job Description</h5>
                {this.renderJobDetailsRow(
                  "Job Description",
                  <span
                    dangerouslySetInnerHTML={{
                      __html: jobDetails.jobDescription,
                    }}
                  />
                )}
                <hr></hr>
                <h5 className="h5-job-details">
                  Candidate Skills and Experience
                </h5>
                {this.renderJobDetailsRow("Must have", jobDetails.mustHave)}
                {this.renderJobDetailsRow(
                  "Skills Considered Bonus",
                  jobDetails.skillsConsideredBonus
                )}
                <hr></hr>
                <h5 className="h5-job-details">Compensation</h5>
                {this.renderJobDetailsRow(
                  "Job Type",
                  returnTextForArrayValue(jobTypes, jobDetails.jobType)
                )}
                {this.renderJobDetailsRow("Salary From", jobDetails.salaryFrom)}
                {this.renderJobDetailsRow("Salary To", jobDetails.salaryTo)}
                {this.renderJobDetailsRow(
                  "Commission Payment",
                  jobDetails.commissionPayment
                )}
                {this.renderJobDetailsRow(
                  "Bonus Payment",
                  jobDetails.bonusPayment
                )}
                <hr></hr>
                <h5 className="h5-job-details">Mandatory Details</h5>
                {this.renderJobDetailsRow(
                  "Number Of Positions",
                  jobDetails.numberOfPositions
                )}
                {this.renderJobDetailsRow(
                  "Culture, Perks and Benefits",
                    <span
                        dangerouslySetInnerHTML={{
                          __html: jobDetails.culturePerksAndBenefits,
                        }}
                    />
                )}
                <hr></hr>
                <h5 className="h5-job-details">Invited by</h5>
                {this.renderJobDetailsRow(
                  "First name",
                  recruiterDetails.firstName
                )}
                {this.renderJobDetailsRow(
                  "Last name",
                  recruiterDetails.lastName
                )}
                {this.renderJobDetailsRow("Email", recruiterDetails.email)}
                {this.renderJobDetailsRow(
                  "Phone",
                  recruiterDetails.phoneNumber
                )}
              </>
            ) : (
              "No details"
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="marign-right-10"
              variant="danger"
              onClick={() => this.clearJobDetails()}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Reject Modal */}
        {this.state.rejectCandidate &&
        <Modal show centered onHide={this.closeRejectCandidate}>
          <Modal.Header closeButton>
            <Modal.Title>Rejecting {this.state.rejectCandidate.firstName} {this.state.rejectCandidate.lastName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Row className="mb-2">
              <Col sm={12}>
                <Form.Label>Reason</Form.Label>
              </Col>
              <Col sm={12}>
                <Form.Control
                  as="textarea"
                  type="text"
                  value={this.state.rejectReason}
                  onChange={this.handleRejectReasonChange}
                  name="reason"
                />
              </Col>
            </Form.Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="marign-right-10"
              variant="primary"
              disabled={!this.state.rejectReason}
              onClick={e => this.rejectCandidate(e, this.state.rejectCandidate, this.state.rejectReason)}
            >
              Reject
            </Button>
          </Modal.Footer>
        </Modal>
        }
        {/* Change Job Position Modal */}
        {this.state.candidateForJobPositionChange && this.state.availableJobsForCandidateJobChange &&
        <Modal
            show
            centered
            onHide={this.closeCandidateChangeJobPosition}>
          <Modal.Header closeButton>
            <Modal.Title>Change Job Position</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Row className="mb-2">
              <Col sm={12}>
                <Form.Label>Job Position</Form.Label>
              </Col>
              <Col sm={12}>
                <Form.Control as="select"
                    onChange={this.handleJobPositionDropDownChange}
                    name="jobPosition"
                >
                  {
                    this.state.availableJobsForCandidateJobChange.map((option, index) => {
                      return (<option key={index} value={JSON.stringify(option)} selected={option.jobId === this.state.candidateForJobPositionChange.jobId}>{option.jobTitle} | {option.companyName}</option>)
                    })
                  }
                </Form.Control>
              </Col>
            </Form.Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
                className="marign-right-10"
                variant="primary"
                disabled={!this.state.candidateJobPositionInfo}
                onClick={e => this.changeCandidateJobPosition(e, {candidateId: this.state.candidateForJobPositionChange.candidateId, ...this.state.candidateJobPositionInfo})}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        }
        {/* Resume Assessment Modal */}
        <CandidateAssessmentModal candidate={this.state.resumeAssessmentCandidate} onHide={() => this.clearResumeAssessment()}/>
      </>
    );
  }

  _exportAsCsv = () => window.open(
    `${process.env.PUBLIC_URL}/api/v1/export/csv/candidatesToAcceptByAdmin?where[status]=${this.state.status}&access_token=${auth.getInstance().getAccessToken()}`,
    '_blank'
  );
}

export default connect(
  (state) => ({
    candidates: state.candidatesForAdmin.candidates,
    totalCount: state.candidatesForAdmin.totalCount,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        findCandidatesToAccept: findCandidatesToAccept,
        acceptCandidateByAdmin: acceptCandidateByAdmin,
        rejectCandidateByAdmin: rejectCandidateByAdmin,
        changeCandidateJobPositionByAdmin: changeCandidateJobPositionByAdmin,
      },
      dispatch
    )
)(ListOfCandidatesForAdmin);
